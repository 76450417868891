import React, { useState } from "react";
import { Register } from "../services/Auth";
import { sendEmailVerification } from "firebase/auth";
import { postUserChat, postUserData } from "../services/Firestore";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function AdminRegister() {
  const [credentails, setCredentials] = useState({
    name: "",
    country: "",
    phone: "",
    email: "",
    password: "",
    type: "admin",
  });
  const [errors, setErrors] = useState({
    name: false,
    country: false,
    phone: false,
    email: false,
    password: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    setCredentials({
      ...credentails,
      [event.target.name]: event.target.value,
    });
  };

  const validation = () => {
    let temp = {};
    var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    temp.name = credentails.name.trim() ? false : true;
    temp.email = regex.test(credentails.email) ? false : true;
    temp.password = credentails.password.trim() ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === false);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (validation()) {
      try {
        let res = await Register(credentails.email, credentails.password);
        await sendEmailVerification(res.user.auth.currentUser);
        await postUserData(res.user.uid, {
          userID: res.user.uid,
          name: credentails.name,
          email: credentails.email,
          country: credentails.country,
          phone: credentails.phone,
          type: credentails.type,
          profileImage:
            "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fangel%20cubrebocas.png?alt=media&token=9b99af6b-6bf8-40ac-9b0f-8b2d889bd561",
        });
        postUserChat(res.user.uid, {});
        alert("Por favor revisa tu correo electronico!");
      } catch (err) {
        console.log(err);
        //   toast.error("Cannot Create your Account");
      }
    } else {
      console.log("Error");
    }
  };

  return (
    <Grid container component="main" maxWidth="xs">
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography component="h1" variant="h5">
            Registra a los Administradores
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleRegister}
            sx={{ mt: 1, width: "35%" }}
          >
            <TextField
              margin="normal"
              fullWidth
              id="name"
              name="name"
              onChange={handleChange}
              error={errors.name}
              placeholder="Nombre"
              helperText={errors.name ? "Es requerido este campo" : ""}
            />
            <TextField
              margin="normal"
              fullWidth
              id="country"
              name="country"
              onChange={handleChange}
              error={errors.country}
              placeholder="Pais"
              helperText={errors.country ? "Es requerido este campo" : ""}
            />
            <TextField
              margin="normal"
              fullWidth
              id="phone"
              name="phone"
              onChange={handleChange}
              error={errors.phone}
              placeholder="Telefono"
              helperText={errors.name ? "Es requerido este campo" : ""}
            />
            <TextField
              margin="normal"
              fullWidth
              id="email"
              name="email"
              onChange={handleChange}
              error={errors.email}
              placeholder="Correo electrónico"
              helperText={errors.email ? "Es requerido este campo" : ""}
            />
            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={errors.password}
            >
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Contraseña"
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>
                {errors.password ? "Este campo es requerido" : ""}
              </FormHelperText>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#133A7B",
                "&:hover": {
                  backgroundColor: "#ceab51",
                  borderColor: "#ceab51",
                  color: "#FFFFFF",
                },
              }}
            >
              Registrate
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
