import React, { useMemo, useRef, useState } from "react";
import { getCurrentUser } from "../services/Firestore";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { getCurrentTimeStamp } from "../helpers/useMoment";
import { getUniqueID } from "../helpers/getUniqueId";
import { postCommunitys } from "../services/Firestore";
import { useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebaseConfig";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactQuill from "react-quill";

export default function CommunityCreate() {
  let navigate = useNavigate();

  const fileInputRef = useRef();

  const [currentUser, setCurrentUser] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  const [data, setData] = useState({
    title: "",
    description: "",
    status: "Pública",
    image: null,
    timeStamp: getCurrentTimeStamp("LLL"),
    userName: currentUser.name,
    postID: getUniqueID(),
  });
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
    image: null,
  });
  const [view, setView] = useState(false);

  useMemo(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  const validation = () => {
    let temp = {};
    temp.title = data.title.trim() ? false : true;
    temp.description = data.description.trim() ? false : true;
    temp.status = data.status.trim() ? false : true;
    temp.image = data.image !== null ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === false);
  };

  const saveCommunity = async (e) => {
    e.preventDefault();
    if (validation()) {
      const postPicsRef = ref(storage, `CommunityImage/${data.image.name}`);
      const uploadTask = uploadBytesResumable(postPicsRef, data.image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(progress);
        },
        (error) => {
          console.error(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (response) => {
            const object = {
              title: data.title,
              description: data.description,
              status: data.status,
              image: response,
              timeStamp: getCurrentTimeStamp("LLL"),
              userName: currentUser.name,
              userId: currentUser.id,
              postID: getUniqueID(),
              publish: data.status === "Pública" ? false : true,
            };
            postCommunitys(object, currentUser.userID);
            setView(true);
          });
        }
      );
      await setData({});
    } else {
      console.log("Error");
    }
  };

  const handleOnChange = (evt) => {
    const value = evt.target.value;
    setData({
      ...data,
      [evt.target.name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewUrl(imageUrl);
    }
    setData({
      ...data,
      image: file,
    });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box>
      <Typography variant="h5" fontWeight={600}>
        Crear una comunidad
      </Typography>
      {view === false && (
        <Paper sx={{ mt: 4, p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RadioGroup
                  row
                  name="status"
                  value={data.status}
                  onChange={handleOnChange}
                >
                  <FormControlLabel
                    value="Privada"
                    control={<Radio />}
                    label="Privada"
                  />
                  <FormControlLabel
                    value="Pública"
                    control={<Radio />}
                    label="Pública"
                  />
                </RadioGroup>
              </FormControl>
              <Box>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <Box
                  onClick={handleButtonClick}
                  sx={{
                    textTransform: "initial",
                    height: 250,
                    width: "100%",
                    outline: "none",
                    borderRadius: 8,
                    cursor: "pointer",
                    overflow: "hidden",
                    position: "relative",
                    backgroundColor: "rgba(145, 158, 171, 0.08)",
                    border: "1px dashed rgba(145, 158, 171, 0.2)",
                    transition:
                      "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    backgroundImage: `url(${previewUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Box>
              {errors.image && (
                <FormHelperText sx={{ color: "red", ml: 2, mt: -2, mb: 2 }}>
                  {errors.image ? "Este campo es requerido" : ""}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mt: 8 }}>
                <TextField
                  label="Nombre de la comunidad"
                  id="outlined-multiline-static"
                  fullWidth
                  sx={{ mb: 3 }}
                  name="title"
                  value={data.title}
                  onChange={handleOnChange}
                  error={errors.title}
                  helperText={errors.title ? "Es requerido este campo" : ""}
                />
                {/* <TextField
                  label="Descripción"
                  id="outlined-multiline-static"
                  fullWidth
                  sx={{ mb: 3 }}
                  multiline
                  rows={4}
                  name="description"
                  value={data.description}
                  onChange={handleOnChange}
                  error={errors.description}
                  helperText={
                    errors.description ? "Es requerido este campo" : ""
                  }
                /> */}
                <ReactQuill
                  theme="snow"
                  value={data.description}
                  placeholder="Escribe algo increible..."
                  style={{ height: 200, marginBottom: 65 }}
                  onChange={(e) =>
                    setData({
                      ...data,
                      description: e,
                    })
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={saveCommunity}
                >
                  <ArrowForwardIosIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
      {view && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              background: "#D9D9D9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              p: 3,
              mt: 5,
            }}
          >
            <Typography textAlign="justify">
              Un miembro de nuestro equipo revisará detalladamente los datos
              ingresados o te enviará un mensaje con sus consejos y/o
              modificaciones, para que finalices tu comunidad de oración púbica.
              El tiempo estimado de revisión es de uno a dos días hábiles. Una
              vez aceptada, cualquier miembro de la aplicación podrá acceder a
              ella a través de “Comunidades”. ¡Podrán unirse personas de todo el
              mundo para apoyar tu causa!
            </Typography>
            <Button
              variant="contained"
              sx={{ textTransform: "initial", mt: 2 }}
              onClick={() => {
                navigate("/community");
              }}
            >
              Finalizar
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
