import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  CardMedia,
  Menu,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import {
  deleteChallengeCommunity,
  deleteCommunity,
  deleteComunityRef,
  deleteNovenaCommunity,
  deletePrayerCommunity,
  deleteProgramRef,
  getChallengeCommunity,
  getCommunity,
  getCurrentUser,
  getNovenasCommunity,
  getPrayerCommunity,
  getProgram,
  getRelationCommunity,
  getUserCommunity,
  getUsers,
  postJoinCommunity,
  postThanks,
  updateAdminCommunity,
} from "../services/Firestore";
import { useNavigate, useLoaderData } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GroupIcon from "@mui/icons-material/Group";
import Publish from "../components/Publish";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";

export async function loader({ params }) {
  const community = await getCommunity(params.communityId);
  const uid = params.communityId;
  return { community, uid };
}

export default function CommunityPreview() {
  let navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);

  const { community, uid } = useLoaderData();

  const [anchorEl, setAnchorEl] = useState(null);
  const [select, setSelect] = useState(0);
  const [option, setOption] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [join, setJoin] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [novenas, setNovenas] = useState([]);
  const [prayers, setPrayers] = useState([]);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState("");
  const [challenges, setChallenges] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [program, setProgram] = useState([]);
  const openMenu = Boolean(anchorEl);

  const handleClickOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleChange = (event) => {
    setUser(event.target.value);
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getCurrentUser(setCurrentUser);
    getUserCommunity(setUsersCount, uid);
    getNovenasCommunity(setNovenas, uid);
    getPrayerCommunity(setPrayers, uid);
    getChallengeCommunity(setChallenges, uid);
    getProgram(setProgram, uid);
    getUsers(setUsers, uid);
  }, [uid, currentUser.id]);

  useEffect(() => {
    if (currentUser.id) {
      getRelationCommunity(setJoin, currentUser.userID, uid);
    }
  }, [currentUser.userID, currentUser, uid]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateAdmin = () => {
    updateAdminCommunity(uid, user);
    alert("Se cambio la propiedad de la comunidad");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Asigna un nuevo propietario</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecciona un usuario de la comunidad para darle la propiedad de la
            comunidad
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Usuarios</InputLabel>
              <Select value={user} label="Usuarios" onChange={handleChange}>
                {users.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleUpdateAdmin}>Asignar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Estas seguro que quieres borrar la comunidad?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Una vez que borres la comunidad no podras recuperar el contenido
            dentro de ella.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Cancelar</Button>
          <Button
            onClick={() => {
              deleteCommunity(uid);
              navigate("/community");
            }}
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid xs={6}>
              <Button
                variant="text"
                startIcon={<ArrowBackIosIcon />}
                sx={{ textTransform: "initial" }}
                onClick={() => navigate("/community")}
              >
                Volver
              </Button>
            </Grid>
            <Grid xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
              {(currentUser?.type === "admin" ||
                currentUser?.userID === community.userId) && (
                <Button
                  variant="contained"
                  sx={{ textTransform: "initial", background: "red" }}
                  onClick={() => handleClickOpenConfirm()}
                >
                  Borrar
                </Button>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    backgroundColor: "rgb(255, 255, 255)",
                    color: "rgb(33, 43, 54)",
                    overflow: "hidden",
                    position: "relative",
                    zIndex: 0,
                    height: 290,
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      background: `linear-gradient(rgba(0, 75, 80, 0.8), rgba(0, 75, 80, 0.8)) center center / cover no-repeat, url(${community.image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ p: 3 }}>
                      <Typography variant="h4" sx={{ color: "#fff" }}>
                        {community.title}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 3,
                  }}
                >
                  {width >= 600 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt=""
                        src="/static/images/avatar/1.jpg"
                        sx={{ mr: 1 }}
                      />
                      <Typography>{community.userName}</Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {width >= 600 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mr: 2,
                        }}
                      >
                        <GroupIcon sx={{ mr: 1 }} />
                        <Typography>
                          Participantes {usersCount.length}
                        </Typography>
                      </Box>
                    )}
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "initial",
                        backgroundColor: join.length !== 0 ? "red" : "",
                        mr: 2,
                      }}
                      onClick={() => {
                        join.length !== 0
                          ? deleteComunityRef(join[0].id)
                          : postJoinCommunity({
                              community: uid,
                              user: currentUser.userID,
                            });
                        postThanks(
                          [community.userId],
                          join.length !== 0
                            ? "ha salido de la comunidad"
                            : "se ha unido a tu comunidad",
                          currentUser?.name
                        );
                      }}
                    >
                      {join.length !== 0 ? "Salir" : "Unirse"}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "initial",
                        mr: 2,
                      }}
                      onClick={() => {
                        if (navigator.share) {
                          navigator
                            .share({
                              title: document.title,
                              url: window.location.href,
                            })
                            .then(() =>
                              console.log("Página compartida con éxito")
                            )
                            .catch((error) =>
                              console.error("Error al compartir:", error)
                            );
                        } else {
                          console.log(
                            "La API Web Share no está soportada en este navegador."
                          );
                        }
                      }}
                    >
                      Compartir
                    </Button>
                    {currentUser?.userID === community.userId && (
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "initial",
                        }}
                        onClick={handleClickOpen}
                      >
                        Asignar propietario
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box sx={{ pl: 3 }}>
                  <Typography fontWeight="bold">Descripcion</Typography>
                  <Typography component="div">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: community.description,
                      }}
                    />
                  </Typography>
                  <Typography>{community.status}</Typography>
                </Box>
                <Box
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mr: 2 }} onClick={() => setSelect(0)}>
                    Publicaciones
                  </Typography>
                  <Typography sx={{ mr: 2 }} onClick={() => setSelect(1)}>
                    Actividades
                  </Typography>
                  <Typography onClick={() => setSelect(2)}>Programa</Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {select === 0 && (
          <Grid item xs={12}>
            <Publish currentUser={currentUser} communityId={uid} />
          </Grid>
        )}
        {select === 1 && (
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderBottomColor: "#000",
                    }}
                    onClick={() => setOption(0)}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      Oraciones
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderBottomColor: "#000",
                    }}
                    onClick={() => setOption(1)}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      Novenas
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderBottomColor: "#000",
                    }}
                    onClick={() => setOption(2)}
                  >
                    <Typography sx={{ textAlign: "center" }}>Rutas</Typography>
                  </Box>
                </Grid>
                {option === 0 && (
                  <Grid item xs={12}>
                    {prayers.map((posts) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          pb: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            navigate("/prayerDetails", {
                              state: {
                                item: posts,
                                uid: uid,
                              },
                            })
                          }
                        >
                          <Box sx={{ width: 200 }}>
                            <CardMedia
                              component="img"
                              height="200"
                              width="200"
                              image={posts.image}
                              alt="Paella dish"
                            />
                          </Box>
                          <Typography variant="h6">{posts.title}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton aria-label="delete" onClick={handleClick}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                deletePrayerCommunity(posts.uid);
                                getPrayerCommunity(setPrayers, uid);
                              }}
                            >
                              Quitar
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                )}
                {option === 1 && (
                  <Grid item xs={12}>
                    {novenas.map((posts) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          pb: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            navigate(`/novenasPreview/${posts.id}`)
                          }
                        >
                          <Box sx={{ width: 200 }}>
                            <CardMedia
                              component="img"
                              height="200"
                              width="200"
                              image={posts.image}
                              alt="Paella dish"
                            />
                          </Box>
                          <Typography variant="h6">{posts.title}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton aria-label="delete" onClick={handleClick}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                deleteNovenaCommunity(posts.uid);
                                getNovenasCommunity(setNovenas, uid);
                              }}
                            >
                              Quitar
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                )}
                {option === 2 && (
                  <Grid item xs={12}>
                    {challenges.map((posts) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          pb: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => navigate(`/challengeList/${posts.id}`)}
                        >
                          <Box sx={{ width: 200 }}>
                            <CardMedia
                              component="img"
                              height="200"
                              width="200"
                              image={posts.image}
                              alt="Paella dish"
                            />
                          </Box>
                          <Typography variant="h6">{posts.title}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton aria-label="delete" onClick={handleClick}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                deleteChallengeCommunity(posts.uid);
                                getChallengeCommunity(setChallenges, uid);
                              }}
                            >
                              Quitar
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        )}
        {select === 2 && (
          <Grid item xs={12}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {program.map((item) => (
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        deleteProgramRef(item.id);
                        alert("Se borro la actividad programada");
                        getProgram(setProgram, uid);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <CalendarMonthIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.eventName}
                    secondary={`Del ${item.dateStart} al ${item.dateEnd} a las ${item.time}`}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
