import React, { forwardRef, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Slider,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebaseConfig";
import ProgramButton from "../components/ProgramButton";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPrayer({
  open,
  handleClose,
  selectedAudio,
  prayerText,
  item,
  uid,
}) {
  const [status, setStatus] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [audio, setAudio] = useState(new Audio());

  useEffect(() => {
    if (selectedAudio !== "") {
      const uri = ref(storage, `prayers/${selectedAudio}`);
      getDownloadURL(uri).then((url) => {
        setAudio(new Audio(url));
      });
    }
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
      setDuration(audio.duration);
    };
    audio.addEventListener("timeupdate", handleTimeUpdate);
  }, [selectedAudio]);

  useEffect(() => {
    if (selectedAudio !== "") {
      downloadAudio();
    }
  }, []);

  useEffect(() => {
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
      setDuration(audio.duration);
    };
    audio.addEventListener("timeupdate", handleTimeUpdate);
  }, [audio]);

  const downloadAudio = () => {
    const uri = ref(storage, `prayers/${selectedAudio}`);
    getDownloadURL(uri).then((url) => {
      setAudio(new Audio(url));
    });
  };

  const handleSliderChange = (event, newValue) => {
    audio.currentTime = newValue;
  };

  const togglePlay = () => {
    if (status) {
      audio.pause();
    } else {
      audio.play();
    }
    setStatus(!status);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              handleClose();
              audio.pause();
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {item.title}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              handleClose();
              audio.pause();
            }}
          >
            Completar
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Box sx={{ height: "88vh", width: "100%" }}>
            <Box
              sx={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${item.image})`,
                height: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ height: "88vh", width: "100%", mt: 3 }}>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: "#133A7B" }}
            >
              {item.title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  borderRadius: 50,
                  backgroundColor: "#133A7B",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 50,
                  height: 50,
                }}
              >
                {status ? (
                  <IconButton aria-label="play/pause" onClick={togglePlay}>
                    <PauseIcon sx={{ height: 38, width: 38, color: "white" }} />
                  </IconButton>
                ) : (
                  <IconButton aria-label="play/pause" onClick={togglePlay}>
                    <PlayArrowIcon
                      sx={{ height: 38, width: 38, color: "white" }}
                    />
                  </IconButton>
                )}
              </Box>
              <Box
                sx={{
                  width: "85%",
                }}
              >
                <Slider
                  value={currentTime}
                  max={duration}
                  onChange={handleSliderChange}
                  aria-label="Tiempo de reproducción"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <ProgramButton uid={uid} />
              <Button
                variant="contained"
                sx={{
                  textTransform: "initial",
                  ml: 2,
                }}
                onClick={() => {
                  if (navigator.share) {
                    navigator
                      .share({
                        title: document.title,
                        url: window.location.href,
                      })
                      .then(() => console.log("Página compartida con éxito"))
                      .catch((error) =>
                        console.error("Error al compartir:", error)
                      );
                  } else {
                    console.log(
                      "La API Web Share no está soportada en este navegador."
                    );
                  }
                }}
              >
                COMPARTIR
              </Button>
            </Box>
            <div dangerouslySetInnerHTML={{ __html: prayerText }} />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              mt: 5,
              width: "80%",
              height: "86vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              textAlign="right"
              sx={{ color: "#133A7B" }}
            >
              “Mientras sigues la oración manten el Valor y la Confianza. Dios
              te ama y te sostiene”
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
