import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Slider,
  Toolbar,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PauseIcon from "@mui/icons-material/Pause";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebaseConfig";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogRetos({ open, handleClose, data, challenge }) {
  const [status, setStatus] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [audio, setAudio] = useState(new Audio());

  useEffect(() => {
    if (data.audioFile !== "" && data.audioFile !== undefined) {
      const uri = ref(storage, `challenges/${data.audioFile}`);
      getDownloadURL(uri).then((url) => {
        setAudio(new Audio(url));
      });
    }
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
      setDuration(audio.duration);
    };
    audio.addEventListener("timeupdate", handleTimeUpdate);
  }, [data.audioFile]);

  useEffect(() => {
    if (data.audioFile !== "" && data.audioFile !== undefined) {
      downloadAudio();
    }
  }, []);

  useEffect(() => {
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
      setDuration(audio.duration);
    };
    audio.addEventListener("timeupdate", handleTimeUpdate);
  }, [audio]);

  const downloadAudio = () => {
    const uri = ref(storage, `challenges/${data.audioFile}`);
    getDownloadURL(uri).then((url) => {
      setAudio(new Audio(url));
    });
  };

  const handleSliderChange = (event, newValue) => {
    audio.currentTime = newValue;
  };

  const togglePlay = () => {
    if (status) {
      audio.pause();
    } else {
      audio.play();
    }
    setStatus(!status);
  };

  return (
    <Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {data.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container sx={{ p: 3 }}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "initial",
                mr: 2,
              }}
              onClick={() => {
                if (navigator.share) {
                  navigator
                    .share({
                      title: document.title,
                      url: window.location.href,
                    })
                    .then(() => console.log("Página compartida con éxito"))
                    .catch((error) =>
                      console.error("Error al compartir:", error)
                    );
                } else {
                  console.log(
                    "La API Web Share no está soportada en este navegador."
                  );
                }
              }}
            >
              Compartir
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ display: "flex", mt: 2 }}>
              <CardMedia
                component="img"
                sx={{ width: 200, height: 200 }}
                image={challenge.image}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component="div" variant="h5">
                    {data.title}
                  </Typography>
                </CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: 50,
                      backgroundColor: "#133A7B",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 50,
                      height: 50,
                      mr: 2,
                    }}
                  >
                    {status ? (
                      <IconButton aria-label="play/pause" onClick={togglePlay}>
                        <PauseIcon
                          sx={{ height: 38, width: 38, color: "white" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton aria-label="play/pause" onClick={togglePlay}>
                        <PlayArrowIcon
                          sx={{ height: 38, width: 38, color: "white" }}
                        />
                      </IconButton>
                    )}
                  </Box>
                  <Slider
                    value={currentTime}
                    max={duration}
                    onChange={handleSliderChange}
                    aria-label="Tiempo de reproducción"
                    sx={{ mr: 1 }}
                  />
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ background: "#DEDADA", mt: 5 }}>
            <Box sx={{ p: 3 }}>
              <Typography
                variant="h4"
                fontWeight={600}
                sx={{ color: "#133A7B" }}
              >
                Reto
              </Typography>
              <Typography textAlign="justify">{data.description}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ background: "#DEDADA", mt: 5 }}>
            <Box sx={{ p: 3 }}>
              <Typography
                variant="h4"
                fontWeight={600}
                sx={{ color: "#133A7B" }}
              >
                Oración
              </Typography>
              <Typography textAlign="justify">{data.prayer}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
}
