import React, { useState } from "react";
import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import logo from "../assets/logo-orando.png";
import cuatro from "../assets/cuatro.png";
import cinco from "../assets/cinco.png";
import seis from "../assets/seis.png";
import manos from "../assets/manos.png";
import plancarte from "../assets/plancarte.png";
import { useNavigate } from "react-router-dom";
import AppleIcon from "@mui/icons-material/Apple";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Ora1 from "../assets/ora1C.png";
import Ora2 from "../assets/ora2.png";
import Ora3 from "../assets/ora3.png";
import Ora4 from "../assets/ora4.jpeg";
import Ora5 from "../assets/ora5.jpeg";
import Ora6 from "../assets/ora6.jpeg";
import Ora7 from "../assets/ora7.jpeg";
import Ora8 from "../assets/ora8.jpeg";
import Ora9 from "../assets/ora9.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderAnimate from "../components/Slider";
import Slider from "react-slick";

export default function Main() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
  };

  let navigate = useNavigate();

  const [isHoverOne, setIsHoverOne] = useState(false);
  const [isHoverTwo, setIsHoverTwo] = useState(false);
  const [isHoverThree, setIsHoverThree] = useState(false);
  const [isHoverFour, setIsHoverFour] = useState(false);
  const [isHoverFive, setIsHoverFive] = useState(false);
  const [isHoverSix, setIsHoverSix] = useState(false);

  return (
    <Box sx={{ background: "#133A7B" }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                p: 5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 10,
                }}
              >
                <img
                  height="100%"
                  width="40%"
                  src={logo}
                  alt="..."
                  loading="lazy"
                />
              </Box>
              <Typography
                variant="h4"
                textAlign="center"
                sx={{ fontWeight: 600, color: "#fff", mt: 5, background: "" }}
              >
                “La oración es el respiro de la fe, es su expresión más propia.
                <br />
                Como un grito silencioso que sale del corazón de quien cree
                <br /> y se confía en Dios.“
              </Typography>
              <Box
                fontStyle="italic"
                sx={{ color: "#FFF", mt: 4, fontWeight: "bold" }}
              >
                Papa. Francisco
              </Box>
              <Box
                sx={{
                  mt: 5,
                  textTransform: "initial",
                  background: "rgb(206, 175, 104)",
                  color: "#133A7B",
                  borderRadius: 30,
                  fontSize: 24,
                  fontWeight: "bold",
                  pt: 2,
                  pb: 2,
                  pl: 4,
                  pr: 4,
                }}
                onClick={() => navigate("/login")}
              >
                Inicia Sesión
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Typography sx={{ color: "#fff", fontSize: 20, mr: 1 }}>
                ¿No tienes cuenta?
              </Typography>
              <Typography
                sx={{ color: "rgb(206, 175, 104)", fontSize: 20 }}
                onClick={() => navigate("/register")}
              >
                Registrate
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 4,
              }}
            >
              <Box
                sx={{
                  background: "#000",
                  borderRadius: 15,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pt: 1,
                  pb: 1,
                  pl: 2,
                  pr: 2,
                }}
              >
                <AppleIcon sx={{ color: "#FFF", fontSize: 40, mr: 1 }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#FFF" }}>
                    App Store
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: "#000",
                  borderRadius: 15,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pt: 1,
                  pb: 1,
                  pl: 2,
                  pr: 2,
                  ml: 2,
                }}
              >
                <PlayArrowIcon sx={{ color: "#FFF", fontSize: 40, mr: 1 }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#FFF" }}>
                    Google Play
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              mt: 8,
              mb: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ color: "#fff", textAlign: "center" }}
            >
              Descarga Orando, pide oración a nuestra comunidad y accede a una
              variedad de contenidos: oraciones, novenas y reflexiones
            </Typography>
          </Grid>
          <Box sx={{ width: "100%", mt: 1 }}>
            <div className="slider-container">
              <Slider {...settings}>
                <Grid item xs={11}>
                  <Box
                    onMouseEnter={() => setIsHoverOne(true)}
                    onMouseLeave={() => setIsHoverOne(false)}
                    sx={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(${Ora3})`,
                      height: 400,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      borderRadius: 10,
                    }}
                  >
                    {isHoverOne && (
                      <Box
                        sx={{
                          background: "#B78244",
                          width: "100%",
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottomRightRadius: 35,
                          borderBottomLeftRadius: 35,
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          textAlign="center"
                          fontWeight={600}
                          color="#fff"
                          sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                        >
                          Oraciones de la mañana
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <Box
                    onMouseEnter={() => setIsHoverTwo(true)}
                    onMouseLeave={() => setIsHoverTwo(false)}
                    sx={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(${Ora1})`,
                      height: 400,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      borderRadius: 10,
                    }}
                  >
                    {isHoverTwo && (
                      <Box
                        sx={{
                          background: "#B78244",
                          width: "100%",
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottomRightRadius: 35,
                          borderBottomLeftRadius: 35,
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          textAlign="center"
                          fontWeight={600}
                          color="#fff"
                          sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                        >
                          Novena al Sagrado Corazón de Jesús
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <Box
                    onMouseEnter={() => setIsHoverThree(true)}
                    onMouseLeave={() => setIsHoverThree(false)}
                    sx={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(${Ora2})`,
                      height: 400,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      borderRadius: 10,
                    }}
                  >
                    {isHoverThree && (
                      <Box
                        sx={{
                          background: "#B78244",
                          width: "100%",
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottomRightRadius: 35,
                          borderBottomLeftRadius: 35,
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          textAlign="center"
                          fontWeight={600}
                          color="#fff"
                          sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                        >
                          Rosario Guadalupano
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <Box
                    onMouseEnter={() => setIsHoverThree(true)}
                    onMouseLeave={() => setIsHoverThree(false)}
                    sx={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(${Ora4})`,
                      height: 400,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      borderRadius: 10,
                    }}
                  >
                    {isHoverThree && (
                      <Box
                        sx={{
                          background: "#B78244",
                          width: "100%",
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottomRightRadius: 35,
                          borderBottomLeftRadius: 35,
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          textAlign="center"
                          fontWeight={600}
                          color="#fff"
                          sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                        >
                          Angelus
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <Box
                    onMouseEnter={() => setIsHoverThree(true)}
                    onMouseLeave={() => setIsHoverThree(false)}
                    sx={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(${Ora5})`,
                      height: 400,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      borderRadius: 10,
                    }}
                  >
                    {isHoverThree && (
                      <Box
                        sx={{
                          background: "#B78244",
                          width: "100%",
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottomRightRadius: 35,
                          borderBottomLeftRadius: 35,
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          textAlign="center"
                          fontWeight={600}
                          color="#fff"
                          sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                        >
                          Acuerdate{" "}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <Box
                    onMouseEnter={() => setIsHoverThree(true)}
                    onMouseLeave={() => setIsHoverThree(false)}
                    sx={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(${Ora6})`,
                      height: 400,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      borderRadius: 10,
                    }}
                  >
                    {isHoverThree && (
                      <Box
                        sx={{
                          background: "#B78244",
                          width: "100%",
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottomRightRadius: 35,
                          borderBottomLeftRadius: 35,
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          textAlign="center"
                          fontWeight={600}
                          color="#fff"
                          sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                        >
                          Comunion Espiritual
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Slider>
            </div>
          </Box>
        </Grid>
        <Box sx={{ pb: 8 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                mt: 6,
                mb: 6,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#fff", textAlign: "center" }}
              >
                Sé un ángel de oración y únete a una comunidad por una causa
                mundial
              </Typography>
            </Grid>
            <Box sx={{ width: "100%", mt: 1 }}>
              <div className="slider-container">
                <Slider {...settings}>
                  <Grid item xs={11}>
                    <Box
                      onMouseEnter={() => setIsHoverFour(true)}
                      onMouseLeave={() => setIsHoverFour(false)}
                      sx={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${cuatro})`,
                        height: 400,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        borderRadius: 10,
                      }}
                    >
                      {isHoverFour && (
                        <Box
                          sx={{
                            background: "#B78244",
                            width: "100%",
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottomRightRadius: 35,
                            borderBottomLeftRadius: 35,
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            textAlign="center"
                            fontWeight={600}
                            color="#fff"
                            sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                          >
                            Jóvenes en Cristo
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                    <Box
                      onMouseEnter={() => setIsHoverFive(true)}
                      onMouseLeave={() => setIsHoverFive(false)}
                      sx={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${cinco})`,
                        height: 400,
                        width: "100%",

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        borderRadius: 10,
                      }}
                    >
                      {isHoverFive && (
                        <Box
                          sx={{
                            background: "#B78244",
                            width: "100%",
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottomRightRadius: 35,
                            borderBottomLeftRadius: 35,
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            textAlign="center"
                            fontWeight={600}
                            color="#fff"
                            sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                          >
                            Vida Consagrada
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                    <Box
                      onMouseEnter={() => setIsHoverSix(true)}
                      onMouseLeave={() => setIsHoverSix(false)}
                      sx={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${seis})`,
                        height: 400,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        borderRadius: 10,
                      }}
                    >
                      {isHoverSix && (
                        <Box
                          sx={{
                            background: "#B78244",
                            width: "100%",
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottomRightRadius: 35,
                            borderBottomLeftRadius: 35,
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            textAlign="center"
                            fontWeight={600}
                            color="#fff"
                            sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                          >
                            Unidos por la paz
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                    <Box
                      onMouseEnter={() => setIsHoverSix(true)}
                      onMouseLeave={() => setIsHoverSix(false)}
                      sx={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${Ora7})`,
                        height: 400,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        borderRadius: 10,
                      }}
                    >
                      {isHoverSix && (
                        <Box
                          sx={{
                            background: "#B78244",
                            width: "100%",
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottomRightRadius: 35,
                            borderBottomLeftRadius: 35,
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            textAlign="center"
                            fontWeight={600}
                            color="#fff"
                            sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                          >
                            Por los sacerdotes
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                    <Box
                      onMouseEnter={() => setIsHoverSix(true)}
                      onMouseLeave={() => setIsHoverSix(false)}
                      sx={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${Ora8})`,
                        height: 400,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        borderRadius: 10,
                      }}
                    >
                      {isHoverSix && (
                        <Box
                          sx={{
                            background: "#B78244",
                            width: "100%",
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottomRightRadius: 35,
                            borderBottomLeftRadius: 35,
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            textAlign="center"
                            fontWeight={600}
                            color="#fff"
                            sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                          >
                            San Antonio de Padua
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                    <Box
                      onMouseEnter={() => setIsHoverSix(true)}
                      onMouseLeave={() => setIsHoverSix(false)}
                      sx={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${Ora9})`,
                        height: 400,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        borderRadius: 10,
                      }}
                    >
                      {isHoverSix && (
                        <Box
                          sx={{
                            background: "#B78244",
                            width: "100%",
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottomRightRadius: 35,
                            borderBottomLeftRadius: 35,
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            textAlign="center"
                            fontWeight={600}
                            color="#fff"
                            sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                          >
                            Espíritu Santo
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Slider>
              </div>
            </Box>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          background: "rgb(206, 175, 104)",
          p: 8,
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{ color: "#fff", fontWeight: "bold" }}
                >
                  Milagros
                </Typography>
                <Typography fontSize={18} fontStyle="italic" color="#fff">
                  Testimoniado por los usuarios
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  background: "#e7d7b5",
                  display: "flex",
                  flexDirection: "column",
                  p: 2,
                  mt: 2,
                  borderRadius: 8,
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: 56, height: 56 }}
                    />
                  </Box>
                  <Box sx={{ ml: 2 }}>
                    <Typography>Bernardina Garza</Typography>
                    <Typography>
                      Estando gravemente enferma de pulmonía, pedí los Santos
                      Sacramentos, fijé la vista en el retrato del Sr. Abad y le
                      dije: Padre, si Dios Nuestro Señor quiere que sane de esta
                      enfermedad que se te escriba este favor, me vino a la
                      mente lo mucho que sufrió por el culto de la Santísima
                      Virgen de Guadalupe y me alivié. Monterrey, N. L., 1916
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      background: "rgb(184, 173, 145)",
                      p: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography>
                      Por intercesión de Antonio Plancarte
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img width="50%" src={manos} alt="..." loading="lazy" />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ background: "white", pt: 6, pb: 0 }}>
        <Container>
          <Grid container spacing={2} sx={{ mb: 0 }}>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  backgroundImage: `url(${plancarte})`,
                  width: 600,
                  height: 500,
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography variant="h3" textAlign="end" fontWeight="bold">
                  Comunidad de oración, <br /> bajo la dulce mirada de <br />{" "}
                  Santa María de Guadalupe <br /> y la guía del Venerable José
                  Antonio Plancarte
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid container spacing={2} sx={{ mt: 10, pb: 10, height: 400 }}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" textAlign="center" color="white">
              Descubre el milagro de la oración. Encomiéndate a nuestra Señora
              de Guadalupe y a otros Santos.
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <SliderAnimate />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
