import React, { useMemo, useState } from "react";
import { Box, Grid, Hidden, Typography } from "@mui/material";
import Publish from "../components/Publish";
import CardBlog from "../components/CardBlog";
import {
  getLastBlogs,
  getCurrentUser,
  getMiracle,
} from "../services/Firestore";
import logo from "../assets/retos.jpeg";
import logo2 from "../assets/ORACIONESD.png";
import logo3 from "../assets/novenas.png";
import { useNavigate } from "react-router-dom";
import CardMiracle from "../components/CardMiracle";

export default function Home() {
  let navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState({});
  const [allBlogs, setAllBlogs] = useState([]);
  const [allMiracles, setAllMiracles] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredTwo, setIsHoveredTwo] = useState(false);
  const [isHoveredThree, setIsHoveredThree] = useState(false);

  useMemo(() => {
    getCurrentUser(setCurrentUser);
    getLastBlogs(setAllBlogs);
    getMiracle(setAllMiracles);
  }, []);

  return (
    <Grid container spacing={2}>
      <Hidden smDown mdDown>
        <Grid item xs={3}>
          <Typography variant="h4" fontWeight="bold" color="#133A7B">
            Milagros
          </Typography>
          <Typography fontSize={18} fontStyle="italic" color="#133A7B">
            Testimoniado por los usuarios
          </Typography>
          {allMiracles.length === 0 ? (
            <Typography>Aun no hay ningun milagro</Typography>
          ) : (
            allMiracles.map((posts) => <CardMiracle posts={posts} />)
          )}
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={12} md={6}>
        <Hidden smDown>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box
                onClick={() => navigate("/retos")}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundImage: `url(${logo})`,
                  alignItems: "flex-end",
                  borderRadius: 10,
                }}
              >
                {isHovered && (
                  <Box
                    sx={{
                      background: "#B78244",
                      width: "100%",
                      height: 60,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottomRightRadius: 35,
                      borderBottomLeftRadius: 35,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      textAlign="center"
                      fontWeight={600}
                      color="#fff"
                      sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                    >
                      Rutas de fe
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                onClick={() => navigate("/oraciones")}
                onMouseEnter={() => setIsHoveredTwo(true)}
                onMouseLeave={() => setIsHoveredTwo(false)}
                sx={{
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundImage: `url(${logo2})`,
                  alignItems: "flex-end",
                  borderRadius: 10,
                }}
              >
                {isHoveredTwo && (
                  <Box
                    sx={{
                      background: "#B78244",
                      width: "100%",
                      height: 60,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottomRightRadius: 35,
                      borderBottomLeftRadius: 35,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      textAlign="center"
                      fontWeight={600}
                      color="#fff"
                      sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                    >
                      Oraciones
                    </Typography>
                  </Box>
                )}{" "}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                onClick={() => navigate("/novenas")}
                onMouseEnter={() => setIsHoveredThree(true)}
                onMouseLeave={() => setIsHoveredThree(false)}
                sx={{
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundImage: `url(${logo3})`,
                  alignItems: "flex-end",
                  borderRadius: 10,
                }}
              >
                {isHoveredThree && (
                  <Box
                    sx={{
                      background: "#B78244",
                      width: "100%",
                      height: 60,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottomRightRadius: 35,
                      borderBottomLeftRadius: 35,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      textAlign="center"
                      fontWeight={600}
                      color="#fff"
                      sx={{ p: 0, m: 0, textTransform: "uppercase" }}
                    >
                      Novenas
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }} />
        </Hidden>
        <Publish currentUser={currentUser} />
      </Grid>
      <Hidden smDown mdDown>
        <Grid item xs={3}>
          <Typography variant="h4" fontWeight="bold" color="#133A7B">
            Blog
          </Typography>
          <Box sx={{ mt: 5.4 }} />
          {allBlogs.map((posts) => (
            <CardBlog posts={posts} currentUser={currentUser} />
          ))}
        </Grid>
      </Hidden>
    </Grid>
  );
}
