import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Paper,
  Typography,
  Grid,
  Tab,
  Tabs,
  Divider,
} from "@mui/material";
import {
  getChallengeUser,
  getCommunityUser,
  getNovenasUser,
  getPrayerUser,
  getProgram,
  getSingleUser,
} from "../services/Firestore";
import { getCurrentUser } from "../services/Firestore";
import { useLocation } from "react-router-dom";
import ProfileEdit from "./ProfileEdit";
import CardCommunity from "../components/CardCommunity";
import Publish from "../components/Publish";
import CardPray from "../components/CardPray";
import CardNovenas from "../components/CardNovenas";
import CardChallenges from "../components/CardChallenges";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Profile() {
  let location = useLocation();

  const [isEdit, setisEdit] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [currentProfile, setCurrentProfile] = useState({});
  const [value, setValue] = useState(0);
  const [community, setCommunity] = useState([]);
  const [prayers, setPrayers] = useState([]);
  const [novenas, setNovenas] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [programa, setPrograma] = useState([]);

  const onEdit = () => {
    setisEdit(!isEdit);
  };

  useMemo(() => {
    getCurrentUser(setCurrentUser);
    if (location?.state?.email) {
      getSingleUser(setCurrentProfile, location?.state?.email);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (currentUser.id) {
      getCommunityUser(setCommunity, currentUser.id);
      getPrayerUser(setPrayers, currentUser.id);
      getNovenasUser(setNovenas, currentUser.id);
      getChallengeUser(setChallenges, currentUser.id);
      getProgram(setPrograma, currentUser.id);
    }
  }, [currentUser.id]);

  console.log(programa);

  return (
    <>
      {isEdit ? (
        <ProfileEdit currentUser={currentUser} onEdit={onEdit} />
      ) : (
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h5" gutterBottom fontWeight={600}>
              Perfil
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "rgb(255, 255, 255)",
                color: "rgb(33, 43, 54)",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                backgroundImage: "none",
                overflow: "hidden",
                position: "relative",
                boxshadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                borderRadius: 5,
                zIndex: 0,
                height: 400,
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) center center / cover no-repeat, url(${currentUser?.imagePortada})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    p: 3,
                  }}
                >
                  <Box>
                    <Avatar
                      alt="S"
                      sx={{ width: 128, height: 128 }}
                      src={currentUser.image}
                    />
                  </Box>
                  <Box
                    sx={{
                      ml: 3,
                      textAlign: "unset",
                    }}
                  >
                    <Typography variant="h5" color={"#FFF"}>
                      {Object.values(currentProfile).length === 0
                        ? currentUser.name
                        : currentProfile?.name}
                    </Typography>
                    <Typography color={"#fff"} onClick={onEdit}>
                      Editar perfil
                    </Typography>
                    <Typography variant="body2" color={"#FFF"} sx={{ mt: 1 }}>
                      {Object.values(currentProfile).length === 0
                        ? currentUser.headline
                        : currentProfile?.headline}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight={600}>Resumen Espiritual</Typography>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Mis Peticiones" />
                  <Tab label="Mis Comunidades" />
                  <Tab label="Mis Oraciones" />
                  <Tab label="Mis Retos" />
                  <Tab label="Mis Novenas" />
                  <Tab label="Mi Programa" />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Grid item xs={12}>
                  <Publish currentUser={currentUser} />
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid container spacing={2}>
                  {community.map((posts) => (
                    <CardCommunity key={posts.id} posts={posts} />
                  ))}
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Grid container spacing={2}>
                  {prayers.map((posts) => (
                    <CardPray key={posts.id} posts={posts} />
                  ))}
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Grid container spacing={2}>
                  {challenges.map((posts) => (
                    <CardChallenges key={posts.id} posts={posts} />
                  ))}
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <Grid container spacing={2}>
                  {novenas.map((posts) => (
                    <CardNovenas key={posts.id} posts={posts} />
                  ))}
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={5}>
                <Grid container spacing={2}>
                  {programa.map((posts) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        pt: 2,
                      }}
                    >
                      <Typography fontWeight={600}>
                        Actividad: {posts.eventName}
                      </Typography>
                      <Typography>Dia: {posts.dateStart}</Typography>
                      <Typography>Hora: {posts.time}</Typography>
                      <Divider sx={{ pt: 2 }} />
                    </Box>
                  ))}
                </Grid>
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
