import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { editProfile } from "../services/Firestore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";

export default function ProfileEdit({ currentUser, onEdit }) {
  const [data, setData] = useState({
    image: currentUser?.image,
    imagePortada: currentUser?.imagePortada,
    name: currentUser?.name,
    email: currentUser?.email,
    phone: currentUser?.phone,
    state: currentUser?.state === undefined ? "" : currentUser?.state,
    address: currentUser?.address === undefined ? "" : currentUser?.address,
    country: currentUser?.country ? currentUser?.country : "Mexico",
    city: currentUser?.city === undefined ? "" : currentUser?.city,
    cp: currentUser?.cp === undefined ? "" : currentUser?.cp,
  });
  const [open, setOpen] = useState(false);
  const [openPro, setOpenPro] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedPro, setSelectedPro] = useState("");

  const portada = [
    {
      id: 1,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fencabezado%201.png?alt=media&token=29284754-afc3-4c2c-83e0-8062ce70d5db",
      select: false,
    },
    {
      id: 2,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fencabezado%20mesa%20y%20vela.png?alt=media&token=3237e97e-8879-40df-b958-6bcc4f3d58c5",
      select: false,
    },
    {
      id: 3,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fportada%203.png?alt=media&token=8ffdc4b1-e662-45eb-b6c7-ec7782e95348",
      select: false,
    },
    {
      id: 4,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fencabezado%204.png?alt=media&token=00f9ec00-b6a9-494b-a4d2-5cd5aa8f6a96",
      select: false,
    },
  ];
  const images = [
    {
      id: 1,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fangel%20cubrebocas.png?alt=media&token=9b99af6b-6bf8-40ac-9b0f-8b2d889bd561",
      selec: false,
    },
    {
      id: 2,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fangel%20escuchando%20música.png?alt=media&token=d3c889ce-b439-4a7e-b3b8-d2a7bd8744a4",
      selec: false,
    },
    {
      id: 3,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fangel%20estudiante.png?alt=media&token=0d7f5012-1194-44da-ac98-8c0e0a2f9fb4",
      selec: false,
    },
    {
      id: 4,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fangel%20leyendo.png?alt=media&token=9038e8f4-90f7-4010-8f68-3de1e3175edf",
      selec: false,
    },
    {
      id: 5,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fangel%20pintando.png?alt=media&token=c246d90c-4922-413d-93da-19da77e00489",
      selec: false,
    },
    {
      id: 6,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fangel%20rezando.png?alt=media&token=2c16ecae-611c-4516-9151-72eaa70daa0a",
      selec: false,
    },
    {
      id: 7,
      image:
        "https://firebasestorage.googleapis.com/v0/b/religion-ff5e6.appspot.com/o/profile%2Fangel%20tocando%20violin.png?alt=media&token=bc182491-0138-4f1a-a80e-53a7a6f24e23",
      selec: false,
    },
  ];

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const updateProfileData = async () => {
    await editProfile(currentUser?.id, data);
    await onEdit();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenPro = () => {
    setOpenPro(true);
  };

  const handleClosePro = () => {
    setOpenPro(false);
  };

  const handleImage = (name) => {
    setSelected(name);
  };

  const handleImagePro = (name) => {
    setSelectedPro(name);
  };

  const handleSelect = () => {
    setData({
      ...data,
      image: selected,
    });
    setOpen(false);
  };

  const handleSelectPro = () => {
    setData({
      ...data,
      imagePortada: selectedPro,
    });
    setOpenPro(false);
  };

  return (
    <Box>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ fontSize: 30, color: "#133A7B" }} textAlign="center">
          Selecciona tu foto de perfil
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {images.map((item) => (
              <Grid
                item
                key={item.id}
                xs={3}
                onClick={() => handleImage(item.image)}
              >
                <Box
                  padding={2}
                  sx={{
                    borderRadius: 30,
                    "&:hover": {
                      transform: "scale(1.5)",
                    },
                    transform: selected === item.image ? "scale(1.5)" : null,
                  }}
                >
                  <img
                    src={item.image}
                    alt={`Imagen ${item.id + 1}`}
                    style={{ width: 100 }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSelect}>Seleccionar</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen onClose={handleClosePro} open={openPro}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClosePro}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Portada
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogTitle sx={{ fontSize: 30, color: "#133A7B" }} textAlign="center">
          Selecciona tu foto de portada
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {portada.map((item) => (
              <Grid
                item
                key={item.id}
                xs={6}
                onClick={() => handleImagePro(item.image)}
              >
                <Box
                  padding={2}
                  sx={{
                    borderColor: selectedPro === item.image ? "blue" : null,
                    border: selectedPro === item.image ? 1 : null,
                  }}
                >
                  <img
                    src={item.image}
                    alt={`Imagen ${item.id + 1}`}
                    style={{
                      width: "100%",
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePro}>Cancelar</Button>
          <Button onClick={handleSelectPro}>Seleccionar</Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h5" gutterBottom fontWeight={600}>
        Perfil
      </Typography>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Button
            variant="text"
            startIcon={<ArrowBackIosIcon />}
            sx={{ textTransform: "initial" }}
            onClick={() => onEdit()}
          >
            Volver
          </Button>
        </Box>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Box
              onClick={handleClickOpenPro}
              sx={{
                textTransform: "initial",
                height: 250,
                width: "100%",
                outline: "none",
                borderRadius: 8,
                cursor: "pointer",
                overflow: "hidden",
                position: "relative",
                backgroundColor: "rgba(145, 158, 171, 0.08)",
                border: "1px dashed rgba(145, 158, 171, 0.2)",
                transition:
                  "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                backgroundImage: `url(${data.imagePortada})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "gray" }}>
                Selecciona una imagen
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Avatar
              variant="square"
              alt=""
              src={data.image}
              sx={{ width: 200, height: 200 }}
              onClick={handleClickOpen}
            />
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper sx={{ p: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  placeholder="Nombre Completo"
                  sx={{ mb: 3 }}
                  name="name"
                  value={data.name}
                  onChange={handleOnChange}
                />
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  placeholder="Telefono"
                  sx={{ mb: 3 }}
                  name="phone"
                  value={data.phone}
                  onChange={handleOnChange}
                />
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  placeholder="Estado"
                  sx={{ mb: 3 }}
                  name="state"
                  value={data.state}
                  onChange={handleOnChange}
                />
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  placeholder="Direccion"
                  sx={{ mb: 3 }}
                  name="address"
                  value={data.address}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  placeholder="Correo"
                  sx={{ mb: 3 }}
                  name="email"
                  value={data.email}
                  onChange={handleOnChange}
                />
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="country"
                    value={data.country}
                    onChange={handleOnChange}
                  >
                    <MenuItem value={"Mexico"}>Mexico</MenuItem>
                    <MenuItem value={"Estados Unidos"}>Estados Unidos</MenuItem>
                    <MenuItem value={"Canada"}>Canada</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  placeholder="Ciudad"
                  sx={{ mb: 3, mt: 3 }}
                  name="city"
                  value={data.city}
                  onChange={handleOnChange}
                />
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  placeholder="Codigo postal"
                  sx={{ mb: 3 }}
                  name="cp"
                  value={data.cp}
                  onChange={handleOnChange}
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    onClick={updateProfileData}
                    sx={{ textTransform: "initial" }}
                  >
                    Guardar perfil
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
