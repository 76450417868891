import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  getCurrentUser,
  getAllUsers,
  deletePost,
  getConnections,
  likePost,
  getLikesByUser,
  getComments,
  postComment,
  getReactionAbrazo,
  getReactionRezo,
  getReactionAcompaño,
  getReactionAmor,
} from "../services/Firestore";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grow,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { getCurrentTimeStamp } from "../helpers/useMoment";
import SendIcon from "@mui/icons-material/Send";
import Reactions from "./Reactions";
import rea1 from "../assets/rea1.png";
import rea2 from "../assets/rea2.png";
import rea3 from "../assets/rea3.png";
import rea4 from "../assets/rea4.png";
import manos from "../assets/like.png";
import manos2 from "../assets/like2.png";
import Manos1 from "../assets/05_manos_blanco_.gif";
import Final from "../assets/manos_final.png";
import ShareIcon from "@mui/icons-material/Share";

export default function RecipeReviewCard({ posts, getEditData }) {
  const [currentUser, setCurrentUser] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [, setIsConnected] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [likesCount, setLikesCount] = useState([]);
  const [reactionAbrazo, setReactionAbrazo] = useState(0);
  const [reactionRezo, setReactionRezo] = useState(0);
  const [reactionAcompaño, setReactionAcompaño] = useState(0);
  const [reactionAmor, setReactionAmor] = useState(0);
  const [liked, setLiked] = useState(false);
  const [reactioned, setReactioned] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openDialogProgress, setOpenDialogProgress] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [reactDialog, setReactDialog] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useMemo(() => {
    getCurrentUser(setCurrentUser);
    getAllUsers(setAllUsers);
    getLikesByUser(currentUser?.id, posts.id, setLiked, setLikesCount);
    getReactionAbrazo(
      currentUser?.id,
      posts.id,
      setReactioned,
      setReactionAbrazo
    );
    getReactionRezo(currentUser?.id, posts.id, setReactioned, setReactionRezo);
    getReactionAcompaño(
      currentUser?.id,
      posts.id,
      setReactioned,
      setReactionAcompaño
    );
    getReactionAmor(currentUser?.id, posts.id, setReactioned, setReactionAmor);
    getComments(posts.id, setComments, setCommentsCount);
  }, [currentUser?.id, posts.id]);

  useEffect(() => {
    getConnections(currentUser?.id, posts.userID, setIsConnected);
  }, [currentUser?.id, posts.userID]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const handleLike = () => {
    likePost(currentUser?.id, posts.id, liked);
    setOpenDialog(false);
    setProgress(0);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    const timer = setTimeout(() => {
      setProgress(100);
      setIsPaused(true);
    }, 15000);
    return () => {
      clearTimeout(timer);
    };
  };

  const handleOpenDialogProgress = () => {
    setOpenDialogProgress(true);
  };

  const handleCloseComments = () => {
    setOpenDialogProgress(false);
  };

  const getComment = (event) => {
    setComment(event.target.value);
  };

  const addComment = () => {
    postComment(
      posts.id,
      comment,
      getCurrentTimeStamp("LLL"),
      currentUser?.name
    );
    setComment("");
  };

  const handleCloseReactions = () => {
    setReactDialog(false);
  };

  const handleOpenReactions = () => {
    setReactDialog(true);
  };

  return (
    <Box key={posts.id}>
      <Dialog onClose={handleCloseDialog} open={openDialog}>
        <DialogTitle sx={{ color: "#133A7B" }}>
          Estas orando por la peticion de {posts.userName}
        </DialogTitle>
        <DialogContent sx={{ height: 500, width: 500 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 300,
            }}
          >
            <Box sx={{ zIndex: 2, height: 300 }}>
              {isPaused ? (
                <img src={Final} alt="" />
              ) : (
                <img src={Manos1} alt="GIF" />
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleLike}
            disabled={progress === 100 ? false : true}
          >
            Amen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleCloseComments}
        open={openDialogProgress}
        PaperProps={{
          component: "form",
        }}
      >
        <DialogTitle>Comentarios</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          <FormControl variant="standard" fullWidth>
            <Input
              id="standard-adornment-password"
              onChange={getComment}
              placeholder="Agregar comentario"
              name="comment"
              value={comment}
              fullWidth
              endAdornment={
                comment !== "" ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={addComment}
                      color="primary"
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <></>
                )
              }
            />
          </FormControl>
          <List>
            {comments.length > 0 ? (
              comments.map((comment) => {
                return (
                  <ListItem alignItems="flex-start" key={comment.postId}>
                    <ListItemAvatar>
                      <Avatar>{comment.name.slice(0, 1)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={comment.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {comment.comment}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                );
              })
            ) : (
              <></>
            )}
          </List>
        </DialogContent>
      </Dialog>
      <Dialog onClose={handleCloseReactions} open={reactDialog}>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mr: 1,
              ml: 1,
            }}
          >
            <img src={rea1} alt="" height={20} />
            <Typography sx={{ ml: 1 }}>{reactionAbrazo}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mr: 1,
              ml: 1,
            }}
          >
            <img src={rea3} alt="" height={20} />
            <Typography sx={{ ml: 1 }}>{reactionAcompaño}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mr: 1,
              ml: 1,
            }}
          >
            <img src={rea4} alt="" height={20} />
            <Typography sx={{ ml: 1 }}>{reactionAmor}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mr: 1,
              ml: 1,
            }}
          >
            <img src={rea2} alt="" height={20} />
            <Typography sx={{ ml: 1 }}>{reactionRezo}</Typography>
          </Box>
        </DialogContent>
      </Dialog>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          avatar={
            <Avatar
              src={
                currentUser?.id === posts.userID
                  ? currentUser?.image
                  : posts?.userName.slice(0, 1)
              }
              alt={posts?.userName.slice(0, 1)}
            />
          }
          action={
            currentUser?.id === posts.userID ? (
              <Box>
                <IconButton
                  aria-label="settings"
                  onClick={handleToggle}
                  ref={anchorRef}
                >
                  <MoreVertIcon />
                </IconButton>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  sx={{ zIndex: 2, position: "absoute" }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem onClick={() => getEditData(posts)}>
                              Editar
                            </MenuItem>
                            <MenuItem
                              onClick={() => deletePost(posts.id)}
                              sx={{ color: "red" }}
                            >
                              Borrar
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            ) : (
              <></>
            )
          }
          title={
            <Typography variant="subtitle1">
              {allUsers.filter((user) => user.id === posts.userID)[0]?.name}
            </Typography>
          }
          subheader={
            posts.miracle
              ? "Por intercesión de " + posts.intercession
              : "Se siente " + posts.mood.toLowerCase()
          }
        />
        <CardContent>
          <Typography variant="subtitle1" color="text.secondary">
            {posts.intention}
          </Typography>
        </CardContent>
        <CardContent
          sx={{ display: "flex", p: 0, ml: 1, alignItems: "center" }}
        >
          {/* {reactionAbrazo + reactionAcompaño + reactionAmor + reactionRezo ===
          0 ? null : ( */}
          {/* <IconButton onClick={() => handleOpenReactions()}>
            <FavoriteIcon />
          </IconButton> */}
          <Reactions
            userId={currentUser?.id}
            postId={posts.id}
            reactioned={reactioned}
            handleOpenReactions={handleOpenReactions}
            liked={liked}
          />
          <Typography fontSize={14} ml={1}>
            {reactionAbrazo + reactionAcompaño + reactionAmor + reactionRezo ===
            0
              ? ""
              : reactionAbrazo +
                  reactionAcompaño +
                  reactionAmor +
                  reactionRezo >
                0
              ? reactionAbrazo +
                reactionAcompaño +
                reactionAmor +
                reactionRezo +
                " Reacciones"
              : reactionAbrazo +
                reactionAcompaño +
                reactionAmor +
                reactionRezo +
                " Reacción"}
          </Typography>
        </CardContent>
        {posts.miracle === false && (
          <CardActions zIndex={1} position="absolute">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  aria-label="add to favorites"
                  onClick={handleOpenDialog}
                >
                  {liked ? (
                    <Box
                      sx={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${manos2})`,
                        height: 30,
                        width: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        // color amarrillo
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${manos})`,
                        height: 30,
                        width: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                      }}
                    />
                  )}
                </IconButton>
                <Typography fontSize={14}>
                  {likesCount.length === 0
                    ? ""
                    : likesCount.length > 1
                    ? likesCount.length + " Oraciones"
                    : likesCount.length + " Oración"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    aria-label="share"
                    onClick={() => {
                      if (navigator.share) {
                        navigator
                          .share({
                            title: document.title,
                            url: "https://www.orando.mx/postShare/" + posts.id,
                          })
                          .then(() =>
                            console.log("Página compartida con éxito")
                          )
                          .catch((error) =>
                            console.error("Error al compartir:", error)
                          );
                      } else {
                        console.log(
                          "La API Web Share no está soportada en este navegador."
                        );
                      }
                    }}
                  >
                    <ShareIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography fontSize={14}>
                    {commentsCount === 0
                      ? ""
                      : commentsCount > 1
                      ? commentsCount + " Comentarios"
                      : commentsCount + " Comentario"}
                  </Typography>
                  <IconButton
                    aria-label="share"
                    onClick={handleOpenDialogProgress}
                  >
                    <ModeCommentIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </CardActions>
        )}
      </Card>
    </Box>
  );
}
