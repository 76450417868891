import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Root from "./routes/Root";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import BlogCreate from "./pages/BlogCreate";
import BlogEdit, { loader as blogEditLoader } from "./pages/BlogEdit";
import BlogPreview, { loader as blogLoader } from "./pages/BlogPreview";
import Faqs from "./pages/Faqs";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import Chat from "./pages/Chat";
import Community from "./pages/Community";
import CommunityCreate from "./pages/CommunityCreate";
import CommunityEdit, {
  loader as communityEditLoader,
} from "./pages/CommunityEdit";
import CommunityPreview, {
  loader as CommunityLoader,
} from "./pages/CommunityPreview";
import Does from "./pages/Does";
import About from "./pages/About";
import Conditions from "./pages/Conditions";
import Novenas from "./pages/Novenas";
import NovenasPreview, { loader as novenaLoader } from "./pages/NovenasPreview";
import Prayers from "./pages/Prayers";
import PrayerCreate from "./pages/PrayerCreate";
import PrayersPreview, { loader as prayLoader } from "./pages/PrayersPreview";
import Challenges from "./pages/Challenges";
import NovenaCreate from "./pages/NovenaCreate";
import ChallengeCreate from "./pages/ChallengeCreate";
import { AuthContextProvider } from "./context/AuthContext";
import { ChatContextProvider } from "./context/ChatContext";
import ChallengePreview, {
  loader as ChallengeLoader,
} from "./pages/ChallengePreview";
import { createTheme, ThemeProvider } from "@mui/material";
import AddPrayer, { loader as addPrayLoader } from "./pages/AddPrayer";
import ChallengeList, {
  loader as ChallengeListLoader,
} from "./pages/ChallengeList";
import Main from "./pages/Main";
import AdminRegister from "./pages/AdminRegister";
import PrayerUpdate, { loader as prayerEditLoader } from "./pages/PrayerUpdate";
import AddPrayerUpdate, {
  loader as addPrayerEditLoader,
} from "./pages/AddPrayerUpdate";
import NovenaUpdate, { loader as novenaEditLoader } from "./pages/NovenaUpdate";
import AcceptCommunitys from "./pages/AcceptCommunitys";
import Donar from "./pages/Donar";
import Plancarte from "./pages/Plancarte";
import ChallengeUpdate, {
  loader as challengeEditLoader,
} from "./pages/ChallengeUpdate";
import Forgot from "./pages/Forgot";
import PostShare, { loader as postLoader } from "./pages/PostShare";
import PrayerDetails from "./pages/PrayerDetails";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {},
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            backgroundColor: "#133A7B", // Color de fondo para botones contenidos primarios
            color: "#fff", // Color de texto para botones contenidos primarios
            ":hover": {
              backgroundColor: "#ceab51",
              color: "#fff",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            borderColor: "#000", // Color del borde para botones de contorno primarios
            color: "#1976d2", // Color de texto para botones de contorno primarios
          },
        },
        {
          props: { variant: "text", color: "primary" },
          style: {
            color: "#133A7B", // Color de texto para botones de contorno primarios
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#133A7B",
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       color: "#FFFFFF",
    //       borderColor: "#133A7B",
    //       "& .MuiInputBase-input": {
    //         borderColor: "#133A7B",
    //       },
    //       "&:focus": {
    //         borderColor: "#133A7B",
    //       },
    //       "&:hover": {
    //         borderColor: "#ceab51",
    //         color: "#FFFFFF",
    //       },
    //     },
    //   },
    // },
  },
});

const router = createBrowserRouter([
  { path: "/main", element: <Main /> },
  { path: "/login", element: <SignIn /> },
  { path: "/register", element: <SignUp /> },
  { path: "/forgot", element: <Forgot /> },
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "postShare/:postId",
        element: <PostShare />,
        loader: postLoader,
      },
      { path: "blog", element: <Blog /> },
      { path: "blogCreate", element: <BlogCreate /> },
      {
        path: "blogPreview/:blogId",
        element: <BlogPreview />,
        loader: blogLoader,
      },
      {
        path: "blogEdit/:blogId",
        element: <BlogEdit />,
        loader: blogEditLoader,
      },
      { path: "community", element: <Community /> },
      { path: "communityCreate", element: <CommunityCreate /> },
      {
        path: "communityEdit/:communityId",
        element: <CommunityEdit />,
        loader: communityEditLoader,
      },
      {
        path: "communityPreview/:communityId",
        element: <CommunityPreview />,
        loader: CommunityLoader,
      },
      { path: "profile", element: <Profile /> },
      { path: "novenas", element: <Novenas /> },
      { path: "novenaCreate", element: <NovenaCreate /> },
      {
        path: "novenasPreview/:prayId",
        element: <NovenasPreview />,
        loader: novenaLoader,
      },
      {
        path: "novenaUpdate/:novenaId",
        element: <NovenaUpdate />,
        loader: novenaEditLoader,
      },
      { path: "oraciones", element: <Prayers /> },
      { path: "prayerCreate", element: <PrayerCreate /> },
      {
        path: "prayersPreview/:prayId",
        element: <PrayersPreview />,
        loader: prayLoader,
      },
      {
        path: "prayerDetails",
        element: <PrayerDetails />,
      },
      {
        path: "addPray/:prayId",
        element: <AddPrayer />,
        loader: addPrayLoader,
      },
      {
        path: "prayerUpdate/:prayerId",
        element: <PrayerUpdate />,
        loader: prayerEditLoader,
      },
      {
        path: "addPrayerUpdate/:prayerAddId",
        element: <AddPrayerUpdate />,
        loader: addPrayerEditLoader,
      },
      { path: "retos", element: <Challenges /> },
      { path: "challengeCreate", element: <ChallengeCreate /> },
      {
        path: "challengeList/:challengeId",
        element: <ChallengeList />,
        loader: ChallengeListLoader,
      },
      {
        path: "challengePreview/:challengeId",
        element: <ChallengePreview />,
        // loader: ChallengeLoader,
      },
      {
        path: "challengeUpdate/:challengeId",
        element: <ChallengeUpdate />,
        loader: challengeEditLoader,
      },
      { path: "chat", element: <Chat /> },
      { path: "faqs", element: <Faqs /> },
      { path: "contact", element: <Contact /> },
      { path: "does", element: <Does /> },
      { path: "about", element: <About /> },
      { path: "conditions", element: <Conditions /> },
      { path: "adminRegister", element: <AdminRegister /> },
      { path: "acceptCommunity", element: <AcceptCommunitys /> },
      { path: "donar", element: <Donar /> },
      { path: "plancarte", element: <Plancarte /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <ThemeProvider theme={theme}>
    <AuthContextProvider>
      <ChatContextProvider>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </ChatContextProvider>
    </AuthContextProvider>
  </ThemeProvider>
);
