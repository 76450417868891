import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate, useLoaderData } from "react-router-dom";
import { getBlog, getCurrentUser } from "../services/Firestore";
import Comments from "../components/Comments";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export async function loader({ params }) {
  const blog = await getBlog(params.blogId);
  const id = params.blogId;
  return { blog, id };
}

export default function BlogPreview() {
  let navigate = useNavigate();

  const { blog, id } = useLoaderData();

  const [currentUser, setCurrentUser] = useState({});

  useMemo(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  useEffect(() => {
    // updateViews(id);
  }, [id]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowBackIosIcon />}
              sx={{ textTransform: "initial" }}
              onClick={() => navigate("/blog")}
            >
              Volver
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "rgb(255, 255, 255)",
            color: "rgb(33, 43, 54)",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            overflow: "hidden",
            position: "relative",
            boxshadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            zIndex: 0,
            height: 500,
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) center center / cover no-repeat, url(${blog.image})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                p: 3,
              }}
            >
              <Typography variant="h4" sx={{ color: "white" }}>
                {blog.title}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Container maxWidth="md">
            <Typography component="div">
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </Typography>
          </Container>
          <Divider />
          <Divider />
          {blog.comments && (
            <Box mt={3}>
              <Comments currentUser={currentUser} id={id} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
