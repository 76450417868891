import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Faqs() {
  return (
    <Box>
      <Typography variant="h5" gutterBottom fontWeight={600}>
        Preguntas frecuentes
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link underline="hover" color="inherit" href="/">
            HOME
          </Link>
          <Typography>Faqs</Typography>
        </Breadcrumbs>
      </Box>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4">¿Tienes alguna pregunta?</Typography>
        <Typography variant="h4">La respuesta esta aqui</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography variant="h5">¿Cómo funciona?</Typography>
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>¿Cómo funciona?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Ve a la siguiente página</Typography>
          </AccordionDetails>
        </Accordion>
        <Typography variant="h5" sx={{ mt: 2 }}>
          Preguntas Frecuentes
        </Typography>
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Cómo unirse o salir de una comunidad de óracion?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En la página de una comunidad, puedes empezar a ser miembros
              haciendo clic en “Unirse”. A partir de ese momento, las nuevas
              publicaciones redactadas por la comunidad se visualizarán en tu
              Lugar de Oración (Menú> Mi lugar de oración). En todo momento
              puedes salir de las comunidades de las cuáles eres miembros
              haciendo clic en “Salir de la comunidad”.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>¿Cómo crear una comunidad de oración?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Es necesario estar inscrito en Hozan para crear y animar una
              comunidad de oración. Conéctate en Hozana, y luego en tu lugar de
              oración (Menú >Mi lugar de oración), haz clic en “crear una
              comunidad de oración”.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿No recibo los correos electronicos de las comunidades de las
              cuales soy miembro?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Estás inscrito(/a)s en una comunidad de oración y ¿no recibes por
              correo electrónico las publicaciones? Puede haber varias
              explicaciones: Comprueba que eres miembros de la comunidad, yendo
              a tu perfil (Menú> mis comunidades). Puede ser que te hays
              inscrito vía Facebook. La dirección de correo electrónico a la
              cual se envían los correos electrónicos Hozana sería entonces la
              misma que la de la cuenta Facebook. Puedes comprobar si recibes
              los correos electrónicos de Hozana en ese correo electrónico. O
              bien ve a parámetros Hozana (Menú> mis Parámetros) para cambiar la
              dirección de correo de conexión. Puede ser que no recibas los
              correos electrónicos de Hozana. Ve a parámetros Hozana (Menú > mis
              parámetros) para cambiar los parámetros de notificaciones por
              correo electrónico de tu cuenta. Para finalizar verificar que los
              correos electrónicos de Hozana no son considerados como spams en
              tu buzón. Si no estás en ninguno de los casos anteriores, y aún no
              recibes los correos electrónicos de Hozana en relacionados conlas
              intenciones a las que estás inscritos, escríbenos a
              contact@hozana.org explicándonos el problema.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              No quiero animar/dirigir una comunidad de oración, pero deseo
              confirmar una intención
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Hozana es sobre todo una herramienta para crear y animar
              comunidades de oración privadas o públicas. Si quieres confiar una
              intención personal pero no tienes a nadie para invitar porque la
              oración es demasiado íntima, Hozana aún no permite hacerlo por el
              momento. Como confiar una intención Te rogamos que lleves tu
              intención al sitio web de SOS oración de la comunidad del
              Emmanuel, que propone también un sistema de escucha por teléfono
              con personas, que pueden orar por tí, al 0 972 302 999.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>¿Cómo modificar mi perfil?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Conéctate a tu cuenta Haz clic en “mi perfil” (Menú> Mi perfil)
              Haz clic en modificar y realizar las modificaciones deseadas.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Cómo gestionar los email y notificacions que recibo de Nombre?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              ¿Quieres desabonarte de los correos electrónicos que Hozana te
              envía? Puedes desinscribirte de los diferentes tipos de correos
              electrónicos enviados por Hozana modificando tus parámetros de
              notificaciones por correo electrónico: Conéctate a tu cuenta Ve a
              “Mis parámetros” (Menú> “mis parámetros”) En los parámetros,
              quitar los correos electrónicos que no quieres recibir Si quieres
              solamente desabonarte de los correos electrónicos de de una cadena
              de oración en particular, consulta el artículo: salir de una
              cadena de oración
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography variant="h5" sx={{ mt: 2 }}>
          Mi cuenta
        </Typography>
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              No puedo conectarme o he perdido mi contraseña
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              ¿Estás inscrito en Hozana, pero no puedes conectarte? Desde la
              página de inicio, Haz clic en "conectarse" ( parte superior
              derecha de la página). Existen dos opciones según la manera en la
              que te hayas inscrito/a: Si te inscribiste a partir de tu cuenta
              Google + o Facebook. Desde la página de inicio, Haz clic en
              "conexión" (arriba de la página). Y luego en el ícono Google + o
              Facebook. Debes poner la dirección de correo electrónico y la
              contraseña asociados a tu cuenta Google+ o Facebook. Si estás
              inscrito mediante tu correo electrónico y una contraseña. Escribe
              tu correo electrónico y la contraseña correspondiente. Si has
              olvidado tu contraseña, haz clic en "¿olivdaste tu contraseña?" y
              sigue las instrucciones.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Quiero cambiar mi contraseña </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Conectarse Ir a la rúbrica "mi perfil" (parte superior izquierda).
              Hacer clic en "mis parámetros” (parte superior derecha, en la
              página de su perfil)
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>¿Cómo inscribirse?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En la página de inicio de Hozana, haz clic en el botón arriba a la
              derecha "inscribirse" (en el móvil "conectarse" y luego abajo "
              inscribise "). Sigue las instrucciones y completa las
              informaciones requeridas en el campo de la sección que sigue.
              Puedes también inscribirte en un clic con tu cuenta Facebook
              (Facebook) o con tu cuenta Google+ (Gmail).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Tengo que tener una cuenta de Facebook o Google para crearme una
              cuenta?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              No. Puedes también inscribirte con tu dirección de correo
              electrónico. En la página de inicio, haz clic en "inscripción" y
              pon las informaciones requeridas en el campo.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>¿Cómo encontrar amigos en Nombre?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Hemos querido que Hozana sea enteramente anónimo. Por lo tanto, no
              Puedes encontrar una persona directamente desde Hozana. Sin
              embargo, si conoces a alguien que tiene una cuenta Hozana, puedes
              pedirle que: Conectarse Ve a la página de su perfil (haz clic en
              "mi perfil" en el menú desplegable abajo en la derecha) Copia y
              pegua el URL de su perfil (con ese ejemplo, el URL del perfil es
              https://es.hozana.org/user/24) Pídele a tu amigo que te mande el
              URL de su perfil (por ejemplo, por mail) Conéctate Copia el URL de
              tu amigo en el navegador y haz clic en "seguir".
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Quiero quitar mi apellido</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En la inscripción en Hozana, solo pedimos un nombre para preservar
              el anonimato de las personas y la intimidad de las oraciones. Sin
              embargo, si habéis puesto por error tu apellido y quieres
              quitarlo, puedes hacerlo modificando tu nombre en tu perfil. Para
              cambiar tu nombre, ver el artículo: Modificar mi perfil
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>¿Cómo desinscribirme o suprimir mi cuenta?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Si quieres simplemente recibir menos mails por parte de Hozana,
              puedes modificar tus parámetros de notificación por correo
              electrónico. Para eso, consulta el artículo “gestionar sus
              notificaciones por mail". Si recibes demasiados correos
              electrónicos de una cadena de oración en particular, consulta el
              artículo "salir de una cadena de oración ". Puedes también
              desinscribirte totalmente de Hozana: Conéctate Ir a la rúbrica "
              parámetros" (en el menú) Haz clic en "suprimir mi cuenta" abajo de
              la página.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography variant="h5" sx={{ mt: 2 }}>
          Para los creadores de comunidad
        </Typography>
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>¿Que tipo de omunidad puedo crear? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Todas las comunidades de oración deben respetar el reglamento de
              Hozana (aquí abajo): Hozana tiene como fin facilitar y amplificar
              la oración personal y comunitaria. Hozana es una página organizado
              por laicos católicos con el apoyo de sacerdotes católicos y se
              dirige a todas las personas de buena voluntad que quieren orar a
              Jesús. Las comunidades creadas en la página web de Hozana serán
              aprobadas antes de publicarlas por los responsables de Hozana. En
              el espíritu de amor y de oración de ese sitio, no serán aprobadas
              las comunidades con temas agresivos u ofensivos, de naturaleza
              política partidaria o que no respetaría el anonimato de personas
              privadas. Cualquier publicidad o proposición comercial en las
              intenciones de oración está excluido, salvo que Hozana lo haya
              acordado por escrito. Los comentarios en Hozana no serán moderados
              a priori, pero deben respetar el espíritu del reglamento. Cada
              creador de comunidad es responsable del respeto del reglamento en
              los comentarios escritos por los miembros de la comunidad, y puede
              si hace falta, suprimir un comentario inconveniente. Cada miembro
              de Hozana puede también informar a los responsables de todo
              comportamiento inconveniente o irrespetuoso.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>¿Cómo animar la comunidad que he creado?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Cuando hayas creado una comunidad, numerosas personas pueden
              unirse a esta comunidad y orar con las publicaciones que hayas
              escrito. Así, te animamos a publicar regularmente publicaciones
              para animar a todos los miembros de tu comunidad a orar. Invita a
              tus amigos en las redes sociales o por mail. Escribe un mensaje
              privado a los que oran para comunicar informaciones importantes a
              cerca de tu comunidad. (ver Reglamento de Hozana) Propón a los
              miembros de tu comunidad volverse un animador (si eres el
              encargado de la comunidad) Para conseguir ayuda, ve a la página:
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Cómo mandar un mensaje privado a los miembros de mi comunidad?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Para enviar un mensaje a los que oran dentro de tu comunidad, ve a
              la página de tu comunidad, y haz clic en “Enviar un mensaje a los
              de mi comunidad”.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Cómo crear una comunidad de oración privada?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Puedes crear una comunidad de oración privada, haciendo clic en
              “privada” en la página de creación de una comunidad (ver “¿cómo
              crear una comunidad de oración?”). Vuestra comunidad será visible
              solamente por las personas con quien compartes el vínculo/link.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Cómo crear una comunidad de oración privada?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Puedes crear una comunidad de oración privada, haciendo clic en
              “privada” en la página de creación de una comunidad (ver “¿cómo
              crear una comunidad de oración?”). Vuestra comunidad será visible
              solamente por las personas con quien compartes el vínculo/link.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿No quiero que mi comunidad sea visible públicamente?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Puedes crear una comunidad privada. La intención será visible
              únicamente para las personas con quien compartes el Link o
              Vínculo. (ver abajo: “¿qué significa el estado “privado” o
              “público” de mi intención).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Qué significa el estado 'privado' o 'publico' de una intención?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Las comunidades de oraciones públicas: Las comunidades de oración
              publicas aparecen públicamente en la página web en de “descubrir
              las comunidades”, o en las sugerencias de comunidad de tu lugar de
              oración. Ellas están visibles en los motores de búsquedas y pueden
              estar compartidas en las redes sociales. Comunidad de oración
              privada: Este modo está destinado a las comunidades privadas.
              Pueden permitiros animar tus grupos, tus redes (capellanía, grupos
              de intercambio, grupos de amigos) a cerca de la oración. Son
              visibles solamente por las personas con quienes la compartirás. No
              serán visibles en las sugerencias de comunidades de tu lugar de
              oración. No serán visibles en la lista de comunidades. (“Descubrir
              las comunidades). No será referenciada en los motores de
              búsquedas. No aparece tampoco en la lista de comunidades por las
              cuáles oras. (cuando un utilizador ve tu perfil, en la página
              “Perfil”).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Cómo modificar el estatuto (privado o público) de mi comunidad?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Puedes modificar el estado de tu comunidad “privada” o “publica”
              modificando tu comunidad (Ir en la página de tu comunidad y haz
              clic en “modificar la descripción”)
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Puedo mostrar el nombre y la foto de una persona que invito a
              orar, en el titulo o contenido de las comunidades o publicaciones?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Cuando la comunidad implica una oración para alguien sobre un tema
              personal o íntimo (salud, pedidas de sanación, problemas
              familiares…) la comunidad o la publicación debe respetar el
              anonimato de la persona. (sólo el nombre está aceptado. No puedes
              publicar su apellido o su foto). Cuando el sujeto de la comunidad
              o de la publicación no es íntimo (boda, por ejemplo), puedes poner
              fotos personales y nombres si quieres.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Cómo cambiar la imagen de mi comunidad de oración?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Conéctate a tu cuenta y …. selecciona una foto que tenga al menos
              696 pixeles de largo, y esté guardada en tu computador. Luego: ir
              en la página de tu comunidad haz clic en “modificar la
              descripción” haz clic en el botón “revisar” elegid una imagen en
              tu disco duro haz clic en “guardar”
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Cómo insertar un video o imagen en la descripcion de mi
              comunidad?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Conéctate a tu cuenta y …. en la página de tu comunidad, en la
              barra a la derecha “animo mi comunidad”, haz clic en “modificar la
              descripción”. en el formulario, puedes insertar un video o una
              imagen: haz clic en el ícono de inserción de video o de imagen
              copiad el URL del video haz clic en “guardar”
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Cómo encontrar una comunidad que he guardado pero que todavia no
              he enviado para que sea validada?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Puedes encontrar todas las comunidades que has creado en tu
              Espacio animador. ( Menú > Espacio Animador). Encontrarás en este
              espacio las comunidades activas, en proceso de validación,
              inactivas, borradores.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography variant="h5" sx={{ mt: 2 }}>
          Otras Preguntas
        </Typography>
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿Cómo señalar un abuso sobre una publicación de una comunidad de
              oración? (palabras inaceptable, ofensivas, racistas)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Arriba de cada publicación, se encuentran varias opciones. Haz
              clic en “señalar un abuso” y luego explica las razones de tu
              denuncia. El moderador de Hozana será informado de tu denuncia.
              ¿Cómo señalar comentarios inaceptables? (ofensivos, racistas.) Esa
              responsabilidad incumbe al encargado de la comunidad. Sin embargo,
              puedes mandar un mensaje al encargado de la comunidad para
              señalarle un comentario indeseable. Creando una cadena de oración
              en Hozana eres responsable de la moderación de los comentarios en
              tu comunidad de oración, y si hace falta puedes, suprimir un
              comentario que tiene un contenido indeseable según el reglamento
              de Hozana
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿He sdio nombrado 'animador' de una comunidad, cual es mi rol?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              El animador de una comunidad tiene un rol pastoral importante.
              Junto con el creador y el portador de la comunidad, puede:
              Redactar, programar y administrar las publicaciones Su rol
              pastoral es importante porque está misionado por el creador de la
              comunidad para guiar los que forman parte de la comunidad
              redactando las publicaciones de la comunidad.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              ¿He sdio nombrado 'encargado' de una comunidad, cual es mi rol?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              El encargado de una comunidad tiene un rol de administrador de la
              comunidad y un rol pastoral importante. Junto con el creador de la
              comunidad, puede: modificar la comunidad (imagen, descripción y
              estatuto) escribir a los de la comunidad Redactar, programar y
              administrar las publicaciones Su rol pastoral es importante porque
              está misionado por el creador de la comunidad para guiar los que
              forman parte de la comunidad y ser el garante de la unión pastoral
              de la comunidad. Varios portadores pueden estar nombrados por el
              creador de la comunidad.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Box>
  );
}
