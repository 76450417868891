import React, { useState } from "react";
import { Box, Button, Grid, Link, TextField, Typography } from "@mui/material";
import logo from "../assets/logo-orando.png";
import { resetPassword } from "../services/Firestore";

export default function Forgot() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    email: false,
  });

  const validation = () => {
    let temp = {};
    var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    temp.email = regex.test(email) ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (validation()) {
      try {
        resetPassword(email);
        // setResetSent(true);
      } catch (error) {
        // setError(error.message);
      }
    }
  };

  return (
    <Grid container component="main" maxWidth="xs" sx={{ height: "100vh" }}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              height="auto"
              width="80%"
              src={logo}
              alt="..."
              loading="lazy"
            />
          </Box>
          <Typography component="h1" variant="h5">
            Resetear contraseña
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleResetPassword}
            sx={{ mt: 1, width: "35%" }}
          >
            <TextField
              margin="normal"
              fullWidth
              id="email"
              name="email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              error={errors.email}
              placeholder="Correo electrónico"
              helperText={errors.email ? "Es requerido este campo" : ""}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#133A7B",
                "&:hover": {
                  backgroundColor: "#ceab51",
                  borderColor: "#ceab51",
                  color: "#FFFFFF",
                },
              }}
            >
              Enviar
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    Iniciar Sesion
                  </Typography>
                  <Link href="/login" variant="body2">
                    Entra aqui
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
