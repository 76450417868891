import React, { useMemo, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { getCurrentTimeStamp } from "../helpers/useMoment";
import { getUniqueID } from "../helpers/getUniqueId";
import { getCurrentUser, getNovena, updateNovena } from "../services/Firestore";
import { useLoaderData, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebaseConfig";
import { serverTimestamp } from "firebase/firestore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export async function loader({ params }) {
  const novena = await getNovena(params.novenaId);
  const id = params.novenaId;
  return { novena, id };
}

export default function NovenaUpdate() {
  let navigate = useNavigate();

  const fileInputRef = useRef();

  const { novena } = useLoaderData();

  console.log(novena);

  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [, setPreviewUrl] = useState(null);
  const [audioFiles, setAudioFiles] = useState([]);
  const [data, setData] = useState({
    title: novena.title,
    about: novena.about,
    prayerOne: novena.prayerOne,
    prayerTwo: novena.prayerTwo,
    prayerThree: novena.prayerThree,
    prayerFour: novena.prayerFour,
    prayerFive: novena.prayerFive,
    prayerSix: novena.prayerSix,
    prayerSeven: novena.prayerSeven,
    prayerEight: novena.prayerEight,
    prayerNine: novena.prayerNine,
    audioFile: novena.audioFile,
    publish: novena.publish,
  });
  const [errors, setErrors] = useState({
    title: false,
    about: false,
    prayerOne: false,
    prayerTwo: false,
    prayerThree: false,
    prayerFour: false,
    prayerFive: false,
    prayerSix: false,
    prayerSeven: false,
    prayerEight: false,
    prayerNine: false,
    audioNameOne: false,
    audioNameTwo: false,
    audioNameThree: false,
    audioNameFour: false,
    audioNameFive: false,
    audioNameSix: false,
    audioNameSeven: false,
    audioNameEight: false,
    audioNameNine: false,
  });
  const [newData, setNewData] = useState({
    prayerOne: novena.prayerOne,
    prayerTwo: novena.prayerTwo,
    prayerThree: novena.prayerThree,
    prayerFour: novena.prayerFour,
    prayerFive: novena.prayerFive,
    prayerSix: novena.prayerSix,
    prayerSeven: novena.prayerSeven,
    prayerEight: novena.prayerEight,
    prayerNine: novena.prayerNine,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useMemo(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  const validation = () => {
    let temp = {};
    temp.title = data.title.trim() ? false : true;
    temp.about = data.about.trim() ? false : true;
    // temp.prayerOne = data.prayerOne.trim() ? false : true;
    // temp.prayerTwo = data.prayerTwo.trim() ? false : true;
    // temp.prayerThree = data.prayerThree.trim() ? false : true;
    // temp.prayerFour = data.prayerFour.trim() ? false : true;
    // temp.prayerFive = data.prayerFive.trim() ? false : true;
    // temp.prayerSix = data.prayerSix.trim() ? false : true;
    // temp.prayerSeven = data.prayerSeven.trim() ? false : true;
    // temp.prayerEight = data.prayerEight.trim() ? false : true;
    // temp.prayerNine = data.prayerNine.trim() ? false : true;
    // temp.audioNameOne = audioFiles[0] !== undefined ? false : true;
    // temp.audioNameTwo = audioFiles[1] !== undefined ? false : true;
    // temp.audioNameThree = audioFiles[2] !== undefined ? false : true;
    // temp.audioNameFour = audioFiles[3] !== undefined ? false : true;
    // temp.audioNameFive = audioFiles[4] !== undefined ? false : true;
    // temp.audioNameSix = audioFiles[5] !== undefined ? false : true;
    // temp.audioNameSeven = audioFiles[6] !== undefined ? false : true;
    // temp.audioNameEight = audioFiles[7] !== undefined ? false : true;
    // temp.audioNameNine = audioFiles[8] !== undefined ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === false);
  };

  const handleFileChangeTest = (index, e) => {
    const file = e.target.files[0];
    const newAudioFiles = [...audioFiles];
    newAudioFiles[index] = file;
    setAudioFiles(newAudioFiles);
  };

  const savePrayer = async (e) => {
    e.preventDefault();
    if (validation()) {
      handleOpen();
      const audioNames = [];
      const uploadTasks = audioFiles.map(async (audioFile) => {
        if (audioFile) {
          audioNames.push(audioFile.name);
          let music = ref(storage, `novena/${audioFile.name}`);
          const metadata = {
            contentType: audioFile.type,
          };
          const uploadTask = uploadBytesResumable(music, audioFile, metadata);
          await uploadTask;
        }
      });
      await Promise.all(uploadTasks);
      const postPicsRef = ref(storage, `novena/${"data.image.name"}`);
      const uploadTask = uploadBytesResumable(postPicsRef, "data.image");
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(progress);
        },
        (error) => {
          console.error(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((response) => {
            const object = {
              title: data.title,
              // audioNameOne: audioNames[0],
              // audioNameTwo: audioNames[1],
              // audioNameThree: audioNames[2],
              // audioNameFour: audioNames[3],
              // audioNameFive: audioNames[4],
              // audioNameSix: audioNames[5],
              // audioNameSeven: audioNames[6],
              // audioNameEight: audioNames[7],
              // audioNameNine: audioNames[8],
              audioNameOne: novena.audioNameOne,
              audioNameTwo: novena.audioNameTwo,
              audioNameThree: novena.audioNameThree,
              audioNameFour: novena.audioNameFour,
              audioNameFive: novena.audioNameFive,
              audioNameSix: novena.audioNameSix,
              audioNameSeven: novena.audioNameSeven,
              audioNameEight: novena.audioNameEight,
              audioNameNine: novena.audioNameNine,
              image: novena.image,
              prayerOne: data.prayerOne,
              prayerTwo: data.prayerTwo,
              prayerThree: data.prayerThree,
              prayerFour: data.prayerFour,
              prayerFive: data.prayerFive,
              prayerSix: data.prayerSix,
              prayerSeven: data.prayerSeven,
              prayerEight: data.prayerEight,
              prayerNine: data.prayerNine,
              publish: data.publish,
              about: data.about,
              timeStamp: getCurrentTimeStamp("LLL"),
              createdAt: serverTimestamp(),
              userName: currentUser.name,
              userId: currentUser.userID,
              postID: getUniqueID(),
            };
            updateNovena(object);
            navigate("/novenas");
          });
        }
      );
      await setData({});
      handleClose();
    }
  };

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewUrl(imageUrl);
    }
    setData({
      ...data,
      image: file,
    });
  };

  console.log(data);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                variant="text"
                startIcon={<ArrowBackIosIcon />}
                sx={{ textTransform: "initial" }}
                onClick={() => navigate("/novenas")}
              >
                Volver
              </Button>
            </Box>
          </Grid>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight={600}
            sx={{ ml: 2, mt: 2 }}
          >
            Guardar novena
          </Typography>
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Titulo de la oración"
                name="title"
                value={data.title}
                onChange={handleOnChange}
                error={errors.title}
                helperText={errors.title ? "Es requerido este campo" : ""}
              />
              <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Acerca de la novena"
                multiline
                rows={4}
                name="about"
                value={data.about}
                onChange={handleOnChange}
                error={errors.about}
                helperText={errors.about ? "Es requerido este campo" : ""}
                sx={{ mt: 3 }}
              />
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                ref={fileInputRef}
                label="Subir imagen"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "image/*",
                  },
                }}
                sx={{ mt: 3 }}
                onChange={handleFileChange}
              />
            </Paper>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight={600}
              sx={{ mt: 2 }}
            >
              Dia 1
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
              {/* <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Oración del dia uno"
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="prayerOne"
                value={data.prayerOne}
                onChange={handleOnChange}
                error={errors.prayerOne}
                helperText={errors.prayerOne ? "Es requerido este campo" : ""}
              /> */}
              <ReactQuill
                theme="snow"
                value={newData.prayerOne}
                placeholder="Escribe algo increible..."
                style={{ height: 200, marginBottom: 65 }}
                onChange={(e) =>
                  setNewData({
                    ...newData,
                    prayerOne: e,
                  })
                }
              />
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                label={"Subir audio dia uno"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "audio/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleFileChangeTest(0, e)}
                error={errors.audioNameOne}
                helperText={
                  errors.audioNameOne ? "Es requerido este campo" : ""
                }
              />
            </Paper>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight={600}
              sx={{ mt: 2 }}
            >
              Dia 2
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
              {/* <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Oración del dia dos"
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="prayerTwo"
                value={data.prayerTwo}
                onChange={handleOnChange}
                error={errors.prayerTwo}
                helperText={errors.prayerTwo ? "Es requerido este campo" : ""}
              /> */}
              <ReactQuill
                theme="snow"
                value={novena.prayerTwo}
                placeholder="Escribe algo increible..."
                style={{ height: 200, marginBottom: 65 }}
                name="prayerTwo"
                onChange={
                  (e) => console.log(e)
                  // setData({
                  // ...data,
                  //   prayerTwo: e,
                  // })
                }
              />
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                label={"Subir audio dia dos"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "audio/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleFileChangeTest(1, e)}
              />
            </Paper>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight={600}
              sx={{ mt: 2 }}
            >
              Dia 3
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
              {/* <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Oración del dia tres"
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="prayerThree"
                value={data.prayerThree}
                onChange={handleOnChange}
                error={errors.prayerThree}
                helperText={errors.prayerThree ? "Es requerido este campo" : ""}
              /> */}
              {/* <ReactQuill
                theme="snow"
                value={data.prayerThree}
                placeholder="Escribe algo increible..."
                style={{ height: 200, marginBottom: 65 }}
                onChange={(e) =>
                  setData({
                    ...data,
                    prayerThree: e,
                  })
                }
              /> */}
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                label={"Subir audio dia tres"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "audio/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleFileChangeTest(2, e)}
              />
            </Paper>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight={600}
              sx={{ mt: 2 }}
            >
              Dia 4
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
              {/* <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Oración del dia cuatro"
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="prayerFour"
                value={data.prayerFour}
                onChange={handleOnChange}
                error={errors.prayerFour}
                helperText={errors.prayerFour ? "Es requerido este campo" : ""}
              /> */}
              {/* <ReactQuill
                theme="snow"
                value={data.prayerFour}
                placeholder="Escribe algo increible..."
                style={{ height: 200, marginBottom: 65 }}
                onChange={(e) =>
                  setData({
                    ...data,
                    prayerFour: e,
                  })
                }
              /> */}
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                label={"Subir audio dia cuatro"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "audio/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleFileChangeTest(3, e)}
              />
            </Paper>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight={600}
              sx={{ mt: 2 }}
            >
              Dia 5
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
              {/* <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Oración del dia cinco"
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="prayerFive"
                value={data.prayerFive}
                onChange={handleOnChange}
                error={errors.prayerFive}
                helperText={errors.prayerFive ? "Es requerido este campo" : ""}
              /> */}
              {/* <ReactQuill
                theme="snow"
                value={data.prayerFive}
                placeholder="Escribe algo increible..."
                style={{ height: 200, marginBottom: 65 }}
                onChange={(e) =>
                  setData({
                    ...data,
                    prayerFive: e,
                  })
                }
              /> */}
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                label={"Subir audio dia cinco"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "audio/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleFileChangeTest(4, e)}
              />
            </Paper>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight={600}
              sx={{ mt: 2 }}
            >
              Dia 6
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
              {/* <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Oración del dia seis"
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="prayerSix"
                value={data.prayerSix}
                onChange={handleOnChange}
                error={errors.prayerSix}
                helperText={errors.prayerSix ? "Es requerido este campo" : ""}
              /> */}
              {/* <ReactQuill
                theme="snow"
                value={data.prayerSix}
                placeholder="Escribe algo increible..."
                style={{ height: 200, marginBottom: 65 }}
                onChange={(e) =>
                  setData({
                    ...data,
                    prayerSix: e,
                  })
                }
              /> */}
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                label={"Subir audio dia seis"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "audio/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleFileChangeTest(5, e)}
              />
            </Paper>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight={600}
              sx={{ mt: 2 }}
            >
              Dia 7
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
              {/* <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Oración del dia siete"
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="prayerSeven"
                value={data.prayerSeven}
                onChange={handleOnChange}
                error={errors.prayerSeven}
                helperText={errors.prayerSeven ? "Es requerido este campo" : ""}
              /> */}
              {/* <ReactQuill
                theme="snow"
                value={data.prayerSeven}
                placeholder="Escribe algo increible..."
                style={{ height: 200, marginBottom: 65 }}
                onChange={(e) =>
                  setData({
                    ...data,
                    prayerSeven: e,
                  })
                }
              /> */}
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                label={"Subir audio dia siete"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "audio/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleFileChangeTest(6, e)}
              />
            </Paper>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight={600}
              sx={{ mt: 2 }}
            >
              Dia 8
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
              {/* <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Oración del dia ocho"
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="prayerEight"
                value={data.prayerEight}
                onChange={handleOnChange}
                error={errors.prayerEight}
                helperText={errors.prayerEight ? "Es requerido este campo" : ""}
              /> */}
              {/* <ReactQuill
                theme="snow"
                value={data.prayerEight}
                placeholder="Escribe algo increible..."
                style={{ height: 200, marginBottom: 65 }}
                onChange={(e) =>
                  setData({
                    ...data,
                    prayerEight: e,
                  })
                }
              /> */}
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                label={"Subir audio dia ocho"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "audio/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleFileChangeTest(7, e)}
              />
            </Paper>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight={600}
              sx={{ mt: 2 }}
            >
              Dia 9
            </Typography>
            <Paper sx={{ p: 3, mt: 2 }}>
              {/* <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Oración del dia nueve"
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="prayerNine"
                value={data.prayerNine}
                onChange={handleOnChange}
                error={errors.prayerNine}
                helperText={errors.prayerNine ? "Es requerido este campo" : ""}
              /> */}
              {/* <ReactQuill
                theme="snow"
                value={data.prayerNine}
                placeholder="Escribe algo increible..."
                style={{ height: 200, marginBottom: 65 }}
                onChange={(e) =>
                  setData({
                    ...data,
                    prayerNine: e,
                  })
                }
              /> */}
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                label={"Subir audio dia nueve"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "audio/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleFileChangeTest(8, e)}
              />
            </Paper>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              m: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={savePrayer}
                sx={{ textTransform: "initial" }}
              >
                Crear Publicación
              </Button>
            </Box>
          </Box>
        </Grid>
      </Container>
    </>
  );
}
