import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import {
  getCurrentUser,
  getPlayersSearch,
  getPrayers,
} from "../services/Firestore";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CardPray from "../components/CardPray";
import { useNavigate } from "react-router-dom";

export default function Prayers() {
  let navigate = useNavigate();

  const [allprayers, setAllPrayers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useMemo(() => {
    getPrayers(setAllPrayers);
    getCurrentUser(setCurrentUser);
  }, []);

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      getPlayersSearch(setSearchResults, searchTerm);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" fontWeight={600}>
          Oraciones
        </Typography>
        {currentUser.type === "admin" && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              navigate("/prayerCreate");
            }}
            sx={{ textTransform: "initial" }}
          >
            Agregar
          </Button>
        )}
      </Box>
      <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
        <OutlinedInput
          id="outlined-adornment-amount"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <IconButton position="start">
              <SearchIcon />
            </IconButton>
          }
        />
      </FormControl>
      <Grid container spacing={2}>
        {searchTerm !== ""
          ? searchResults.map((posts) => (
              <CardPray
                key={posts.id}
                posts={posts}
                currentUser={currentUser.type}
              />
            ))
          : allprayers.map((posts) => (
              <CardPray
                key={posts.id}
                posts={posts}
                currentUser={currentUser.type}
              />
            ))}
      </Grid>
    </Box>
  );
}
