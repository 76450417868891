import React, { useMemo, useState, useRef } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { getCurrentTimeStamp } from "../helpers/useMoment";
import { getUniqueID } from "../helpers/getUniqueId";
import { getCurrentUser, postContent } from "../services/Firestore";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebaseConfig";
import Preview from "../components/Preview";
import { serverTimestamp } from "firebase/firestore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function BlogCreate() {
  let navigate = useNavigate();

  const fileInputRef = useRef();

  const [currentUser, setCurrentUser] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    title: "",
    description: "",
    content: "",
    image: null,
    comments: true,
    publish: true,
    views: 0,
  });
  const [errors, setErrors] = useState({
    title: false,
    description: false,
    content: false,
    image: false,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  useMemo(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  const validation = () => {
    let temp = {};
    temp.title = data.title.trim() ? false : true;
    temp.description = data.description.trim() ? false : true;
    temp.content = data.content.trim() ? false : true;
    temp.image = data.image !== null ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === false);
  };

  const saveBlog = async (e) => {
    e.preventDefault();
    if (validation()) {
      const postPicsRef = ref(storage, `blogImage/${data.image.name}`);
      const uploadTask = uploadBytesResumable(postPicsRef, data.image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(progress);
        },
        (error) => {
          console.error(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((response) => {
            const object = {
              title: data.title,
              description: data.description,
              content: data.content,
              image: response,
              comments: data.comments,
              publish: data.publish,
              views: 0,
              timeStamp: getCurrentTimeStamp("LLL"),
              createdAt: serverTimestamp(),
              userName: currentUser.name,
              userId: currentUser.userID,
              postID: getUniqueID(),
            };
            postContent(object);
            navigate("/blog");
          });
        }
      );
      await setData({});
    } else {
      console.log("Error");
    }
  };

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
    if (file && file.size > maxSizeInBytes) {
      setErrorMessage("La imagen excede el tamaño máximo permitido (1MB)");
      e.target.value = "";
    } else {
      setErrorMessage("");
      const imageUrl = URL.createObjectURL(file);
      setPreviewUrl(imageUrl);
    }
    setData({
      ...data,
      image: file,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowBackIosIcon />}
              sx={{ textTransform: "initial" }}
              onClick={() => navigate("/blog")}
            >
              Volver
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Typography variant="h5" gutterBottom fontWeight={600} sx={{ mt: 3 }}>
        Crear un post
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Detalles
          </Typography>
          <Typography>Titulo, descripcion corta, imagen...</Typography>
        </Grid>
        <Grid item xs={8}>
          <Paper
            elevation={1}
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgb(33, 43, 54)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              backgroundImage: "none",
              overflow: "hidden",
              position: "relative",
              boxshadow:
                "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
              borderRadius: 5,
              zIndex: 0,
              p: 3,
            }}
          >
            <TextField
              id="outlined-multiline-static"
              fullWidth
              placeholder="Titulo de publicación"
              sx={{ mb: 3 }}
              name="title"
              value={data.title}
              onChange={handleOnChange}
              error={errors.title}
              helperText={errors.title ? "Es requerido este campo" : ""}
            />
            <TextField
              id="outlined-multiline-static"
              fullWidth
              placeholder="Descripción"
              sx={{ mb: 3 }}
              multiline
              rows={4}
              name="description"
              value={data.description}
              onChange={handleOnChange}
              error={errors.description}
              helperText={errors.description ? "Es requerido este campo" : ""}
            />
            <ReactQuill
              theme="snow"
              value={data.content}
              placeholder="Escribe algo increible..."
              style={{ height: 200, marginBottom: 65 }}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  ["clean"],
                  [{ align: [] }],
                ],
              }}
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "align",
              ]}
              onChange={(e) =>
                setData({
                  ...data,
                  content: e,
                })
              }
            />
            {errors.content && (
              <FormHelperText sx={{ color: "red", ml: 2, mt: -2, mb: 2 }}>
                {errors.content ? "Este campo es requerido" : ""}
              </FormHelperText>
            )}
            <Typography variant="caption">Seleccionar imagen</Typography>
            <Box sx={{ mb: 3 }}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
              />
              <Box
                onClick={handleButtonClick}
                sx={{
                  textTransform: "initial",
                  height: 250,
                  width: "100%",
                  outline: "none",
                  borderRadius: 8,
                  cursor: "pointer",
                  overflow: "hidden",
                  position: "relative",
                  backgroundColor: "rgba(145, 158, 171, 0.08)",
                  border: "1px dashed rgba(145, 158, 171, 0.2)",
                  transition:
                    "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  backgroundImage: `url(${previewUrl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></Box>
            </Box>
            {errors.image && (
              <FormHelperText sx={{ color: "red", ml: 2, mt: -2, mb: 2 }}>
                {errors.image ? "Este campo es requerido" : ""}
              </FormHelperText>
            )}
            {errorMessage && (
              <FormHelperText sx={{ color: "red", ml: 2, mt: -2, mb: 2 }}>
                {errorMessage}
              </FormHelperText>
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={data.comments}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setData({
                      ...data,
                      comments: e.target.checked,
                    });
                  }}
                />
              }
              label="Activar comentarios"
            />
          </Paper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              m: 3,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={data.publish}
                  onChange={(e) => {
                    setData({
                      ...data,
                      publish: e.target.checked,
                    });
                  }}
                />
              }
              label="Publicar"
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClickOpen}
                sx={{ mr: 2, textTransform: "initial" }}
              >
                Preview
              </Button>
              <Button
                variant="contained"
                onClick={saveBlog}
                sx={{ textTransform: "initial" }}
              >
                Crear Publicación
              </Button>
            </Box>
          </Box>
        </Grid>
        <Preview
          handleClose={handleClose}
          open={open}
          data={data}
          previewUrl={previewUrl}
        />
      </Grid>
    </Box>
  );
}
