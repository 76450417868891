import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { serverTimestamp } from "firebase/firestore";
import { getCurrentTimeStamp } from "../helpers/useMoment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getCurrentUser, postProgram } from "../services/Firestore";
import "dayjs/locale/es";

export default function ProgramButton({ uid }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [date, setDate] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const [time, setTime] = useState(dayjs());
  const [currentUser, setCurrentUser] = useState({});
  const [dateData, setDateData] = useState({
    dateStart: "",
    dateEnd: "",
    time: "",
  });

  useEffect(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDateStartChange = (date) => {
    setDate(date);
    const formattedDate =
      new Date(date).getFullYear() +
      "-" +
      String(new Date(date).getMonth() + 1).padStart(2, "0") +
      "-" +
      new Date(date).getDate();
    setDateData({ ...dateData, dateStart: formattedDate });
  };

  const handleDateEndChange = (date) => {
    setDateEnd(date);
    const formattedDate =
      new Date(date).getFullYear() +
      "-" +
      String(new Date(date).getMonth() + 1).padStart(2, "0") +
      "-" +
      new Date(date).getDate();
    setDateData({ ...dateData, dateEnd: formattedDate });
  };

  const handleTimeChange = (time) => {
    setTime(time);
    const formattedDate =
      "T" +
      new Date(time).getHours() +
      ":" +
      String(new Date(time).getMinutes()).padStart(2, "0");
    setDateData({ ...dateData, time: formattedDate });
  };

  const handleSubmit = async () => {
    let obj = {
      eventId: uid,
      dateStart: dateData.dateStart,
      dateEnd: dateData.dateEnd,
      time: dateData.time,
      timeStamp: getCurrentTimeStamp("LLL"),
      createdAt: serverTimestamp(),
      userName: currentUser.name,
      userId: currentUser.userID,
    };
    postProgram(obj);
    // await postThanks("destinationUserIds", "message", "userName");
    handleCloseDialog();
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Programa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Programa un dia y hora en el que quieras realizar tu actividad.
          </DialogContentText>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} md={12}>
                <DatePicker
                  label="Dia que inicia"
                  value={date}
                  onChange={(newDate) => handleDateStartChange(newDate)}
                  views={["year", "month", "day"]}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <DatePicker
                  label="Dia que termina"
                  value={dateEnd}
                  onChange={(newDate) => handleDateEndChange(newDate)}
                  views={["year", "month", "day"]}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TimePicker
                  label="Hora"
                  value={time}
                  onChange={(newTime) => handleTimeChange(newTime)}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleSubmit}>Programar</Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        sx={{ background: "#133A7B" }}
        onClick={() => handleClickOpenDialog()}
      >
        Programar ahora
      </Button>
    </div>
  );
}
