import React, { forwardRef, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Slide,
  TextField,
} from "@mui/material";
import { getCurrentTimeStamp } from "../helpers/useMoment";
import { getBlogComments, postBlogComment } from "../services/Firestore";
import CardComment from "./CardComment";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Comments({ currentUser, id }) {
  const [comment, setComment] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [comments, setComments] = useState([]);

  const addComment = () => {
    postBlogComment(id, comment, getCurrentTimeStamp("LLL"), currentUser?.name);
    setOpen(false);
    setComment("");
  };

  useMemo(() => {
    getBlogComments(id, setComments);
  }, [id]);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "rgb(255, 255, 255)",
          color: "rgb(33, 43, 54)",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          backgroundImage: "none",
          overflow: "hidden",
          position: "relative",
          boxshadow:
            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
          zIndex: 0,
        }}
      >
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={4}
          fullWidth
          placeholder="Dejanos tu comentario..."
          sx={{ mb: 2 }}
          onClick={() => {
            setOpen(true);
            setIsEdit(false);
          }}
        />
      </Paper>
      <React.Fragment>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Box
              sx={{
                backgroundColor: "rgb(255, 255, 255)",
                color: "rgb(33, 43, 54)",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                backgroundImage: "none",
                overflow: " hidden",
                position: "relative",
                boxshadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                zIndex: 0,
              }}
            >
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                fullWidth
                value={comment}
                placeholder="Dejanos tu comentario..."
                sx={{ mb: 2 }}
                onChange={(e) => setComment(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancelar</Button>
            <Button
              disabled={comment.length > 0 ? false : true}
              onClick={addComment}
            >
              {isEdit ? "Actualizar" : "Publicar"}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <Box>
        {comments.map((comment) => {
          return (
            <Box key={comment.id}>
              <CardComment comment={comment} />
            </Box>
          );
        })}
      </Box>
    </>
  );
}
