import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";

export default function Donar() {
  const [age, setAge] = useState("");
  const [type, setType] = useState("");

  const handleChangeTYpe = (event) => {
    setType(event.target.value);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            DONACIÓN
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Imagina un mundo donde miles de personas se unen cada día para orar,
            compartiendo esperanza, amor y solidaridad a pesar de las fronteras.
            Eso es exactamente lo que Orando está haciendo posible.
            <br />
            Orando es mucho más que una red social en línea; es una comunidad
            vibrante de orantes, que se unen para orar por sus necesidades y
            sufrimientos, en un sólo corazón. Sin embargo, mantener este
            increíble servicio gratuito para todos requiere recursos y esfuerzo.{" "}
            <br />
            Detrás de cada oración compartida, hay un costo asociado: el pago de
            hosting, el equipo dedicado que trabaja sin descanso para
            desarrollar y diseñar materiales de oración e ingeniería digital que
            hace posible el funcionamiento en el servidor.
            <br />
            Por eso te invitamos a ser parte de este movimiento global de
            oración. Tu donación, por pequeña que sea, hará una gran diferencia.
            Ayudará a asegurar que Orando siga siendo accesible para todos, de
            manera gratuita.
            <br />
            Imagina las vidas que podemos tocar, las esperanzas que podemos
            encender y los corazones que podemos unir con tu generosidad. Cada
            contribución nos acerca un paso más a nuestro objetivo de mantener
            Orando a todo mundo.
            <br />
            Hazte parte de esta misión y sé un agente de cambio. Dona ahora y
            ayuda a mantener viva la llama de la oración en la comunidad global
            de Orando. ¡Gracias por ser parte de esta maravillosa aventura de fe
            y esperanza! Juntos, podemos hacer la diferencia.
          </Typography>
          <br />
          <Typography>
            ¡Gracias por ser parte de esta maravillosa aventura de fe y
            esperanza! Juntos, podemos hacer la diferencia.
          </Typography>
          <br />
          <Typography variant="h4" textAlign="center">
            ¡Dona y Mantén Vivo el Milagro de Orando!"
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ mt: 5 }}>
          <Typography>MI DONACIÓN</Typography>
          <Divider sx={{ borderColor: "#E1A800", mt: 2, mb: 3 }} />
          <Box sx={{ background: "#c2c2c2", p: 6, textAlign: "justify" }}>
            <Typography>
              ¡Tu contribución frecuente hace posible nuestro progreso
              constante!" Una donación periódica es el apoyo que necesitamos
              para contar con recursos seguros y mantener vivo nuestro
              compromiso.
            </Typography>
          </Box>
          <Paper sx={{ p: 3 }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                onChange={handleChange}
              >
                <MenuItem value={"Una Vez"}>Una vez</MenuItem>
                <MenuItem value={"Mensual"}>Mensual</MenuItem>
              </Select>
            </FormControl>
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={6}>
                <Box
                  sx={{ borderRadius: 5, borderWidth: 1, borderColor: "gray" }}
                >
                  <Typography textAlign="center">5 Mex $</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{ borderRadius: 5, borderWidth: 1, borderColor: "gray" }}
                >
                  <Typography textAlign="center">10 Mex $</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{ borderRadius: 5, borderWidth: 1, borderColor: "gray" }}
                >
                  <Typography textAlign="center">50 Mex $</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{ borderRadius: 5, borderWidth: 1, borderColor: "gray" }}
                >
                  <Typography textAlign="center">100 Mex $</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{ borderRadius: 5, borderWidth: 1, borderColor: "gray" }}
                >
                  <Typography textAlign="center">200 Mex $</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{ borderRadius: 5, borderWidth: 1, borderColor: "gray" }}
                >
                  <Typography textAlign="center">Otro</Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={4} sx={{ mt: 5 }}>
          <Typography>MIS DATOS</Typography>
          <Divider sx={{ borderColor: "#E1A800", mt: 2, mb: 3 }} />
          <Box sx={{ background: "#c2c2c2", p: 6 }}>
            <FormControl fullWidth sx={{ background: "white" }}>
              <InputLabel id="demo-simple-select-label">
                Metodo de pago
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Metodo de Pago"
                onChange={handleChangeTYpe}
              >
                <MenuItem value={"Una Vez"}>Tarjeta</MenuItem>
                <MenuItem value={"Mensual"}>Efectivo</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Nombre"
              fullWidth
              sx={{ mt: 3, background: "white" }}
            />
            <TextField
              label="Apellido"
              fullWidth
              sx={{ mt: 3, background: "white" }}
            />
            <TextField
              label="Correo Electrónico"
              fullWidth
              sx={{ mt: 3, background: "white" }}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ mt: 5 }}>
          <Typography>FINALIZAR</Typography>
          <Divider sx={{ borderColor: "#E1A800", mt: 2, mb: 3 }} />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              sx={{ background: "#E1A800" }}
              endIcon={<FavoriteIcon />}
            >
              DONAR AHORA
            </Button>
          </Box>
          <Box
            sx={{
              background: "#c2c2c2",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              p: 6,
              mt: 3,
            }}
          >
            <Typography sx={{ color: "#E52525" }} textAlign="center">
              ¡GRACIAS POR UNIRTE AL EQUIPO DE COLABORADORES!
            </Typography>
            <Typography sx={{ color: "#133A7B" }} textAlign="center">
              Una donación periódica es el apoyo constante que necesitamos para
              asegurar recursos y seguir construyendo juntos.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
