import React from "react";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";

export default function Does() {
  return (
    <Container>
      <Typography variant="h5" gutterBottom fontWeight={600}>
        ¿Cómo funciona?
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link underline="hover" color="inherit" href="/">
            HOME
          </Link>
          <Typography>Como funciona</Typography>
        </Breadcrumbs>
      </Box>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold" }} variant="h6">
          "Uniendo corazones en la oración, encontramos fortaleza y esperanza"
        </Typography>
        <Typography>
          Orando es una aplicación diseñada a fortalecer la vida espiritual de
          sus usuarios, conectándolos en una comunidad de oración e intercesión.
          Proporciona una plataforma digital donde los usuarios pueden solicitar
          oraciones ante sus necesidades, encontrar apoyo mutuo y disfrutar de
          material espiritual.
        </Typography>
        <br />
        <Typography sx={{ fontWeight: "bold" }} variant="h6">
          PRINCIPALES FUNCIONALIDADES:
        </Typography>
        <Typography>
          Solicitar Oraciones: Los usuarios pueden escribir sus peticiones de
          oración para que otros miembros de la comunidad oren por ellas.
        </Typography>
        <Typography>
          Ofrecer Oraciones: Además de pedir oraciones, los usuarios tienen la
          opción de responder a las peticiones de otros ofreciendo sus propias
          oraciones. Basta con hacer clic en el icono de oración para sumarse en
          apoyo.
        </Typography>
        <Typography>
          Comentar y reaccionar. Ante la solicitud de oraciones, los usuarios
          pueden expresar sus palabras de apoyo, esperanza o consuelo mediante
          comentarios y reacciones, fortaleciendo así la unión y solidaridad de
          la comunidad.
        </Typography>
        <Typography>
          Crear comunidades personalizadas: Los usuarios tienen la opción de
          conectarse con otros miembros de la comunidad, formando grupos de
          oración personalizados. Estas comunidades pueden ser públicas o
          privadas, permitiendo compartir oraciones, motivarse mutuamente y
          fortalecer la fe en conjunto.
        </Typography>
        <Typography>
          Recursos Espirituales: La aplicación ofrece oraciones, novenas y
          reflexiones, para enriquecer la vida espiritual de los usuarios.
        </Typography>
        <Typography>
          Programar el material: Para mantener una práctica espiritual
          constante, la aplicación permite programar y calendarizar el material
          espiritual. Así, los usuarios pueden recibir recordatorios para
          mantenerse conectados con su vida de oración diaria.
        </Typography>
        <Typography>
          Notificaciones: Los usuarios pueden configurar notificaciones
          personalizadas, recibiendo recordatorios para unirse a oraciones
          específicas, eventos comunitarios o reflexiones diarias, manteniendo
          así una constante conexión con la comunidad y sus prácticas
          espirituales.
        </Typography>
        <Typography>
          Disponible en Android y Apple: Para una experiencia más ágil y
          accesible, la aplicación está disponible para descargar tanto en
          dispositivos Android como en dispositivos Apple, garantizando que los
          usuarios puedan acceder a ella en cualquier momento y lugar.
        </Typography>

        <Typography sx={{ fontWeight: "bold", mt: 3 }} variant="h6">
          CÓMO CREAR UNA CUENTA EN ORANDO
        </Typography>
        <br />
        <Typography>
          Para unirte a nuestra comunidad de oración en Orando, sigue estos
          sencillos pasos para crear tu cuenta
        </Typography>
        <Typography>
          Accede: Abre tu navegador web en Orando.mx o descarga la aplicación en
          App store o Google Play.
        </Typography>
        <Typography>
          Regístrate: Busca el botón de registro o crear cuenta en la página
          principal y haz clic en él para comenzar el proceso.
        </Typography>
        <br />
        <Typography fontWeight="bold">
          Completa tus datos Personales:
        </Typography>
        <Typography>
          •Nombre: Ingresa tu nombre completo.
          <br />
          •País: registra la dirección.
          <br />
          •Teléfono: Introduce tu número de teléfono móvil, con sus ladas
          correspondientes
          <br />
          •Correo Electrónico: Proporciona una dirección de correo electrónico
          válida.
          <br />
          •Crea tu Contraseña: Elige una contraseña segura para proteger tu
          cuenta. Asegúrate de que contenga al menos 8 caracteres, incluyendo
          números, letras mayúsculas y minúsculas.
          <br />
          Verifica tu cuenta: Una vez que hayas completado todos los campos, se
          te enviará un correo electrónico de verificación. Abre tu correo y
          sigue las instrucciones para verificar tu cuenta.
          <br />
          Accede a tu cuenta: Una vez verificada tu cuenta, podrás iniciar
          sesión con tu correo electrónico y contraseña desde cualquier
          dispositivo, ya sea en la página web o en la aplicación móvil.
        </Typography>
        <Typography>
          Después del proceso serás parte de la comunidad Orando, donde podrás
          compartir tus peticiones de oración, unirte en oración por los demás y
          enriquecer tu vida espiritual con recursos y reflexiones.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }} variant="h6">
          MURO PERSONAL
        </Typography>
        <Typography>
          En Orando, entendemos la importancia de tener un espacio personalizado
          donde se puede gestionar, seguir tu progreso espiritual y conectarte
          con la comunidad. Por eso, ofrece un perfil personal completo que
          actúa como tu centro de operación espiritual.
          <br />
          Editar Perfil: Tienes la libertad de personalizar la información de tu
          cuenta. Puedes modificar el nombre con el que deseas ser identificado,
          elegir un avatar de perfil y seleccionar una imagen de portada.
          <br />
          Solicitudes de Oración: Aquí podrás ver las peticiones de oración que
          has realizado, permitiéndote llevar un registro de tus intenciones y
          peticiones a lo largo del tiempo.
          <br />
          Comunidades: Accede rápidamente a las comunidades a las que
          perteneces, facilitando tu participación en sus actividades y
          oraciones. Oraciones Programadas: Te mantiene al tanto de las
          oraciones y actividades programadas de manera personal, o por las
          comunidades a las que te has unido.
          <br />
          Novenas: Sigue el progreso de las novenas en las que estás
          participando, facilitando tu compromiso y seguimiento.
          <br />
          Milagros: Desde tu muro personal, con un solo clic en las peticiones
          que has publicado, tienes la opción de expresar tu agradecimiento o de
          compartir la buena noticia de que tu petición se ha cumplido como un
          verdadero milagro.
          <br />
          Agradecimientos: Desde tu muro personal, puedes expresar gratitud a
          quienes han orado por ti. Tus peticiones publicadas te permiten enviar
          agradecimientos automáticos a quienes han reaccionado u orado por tu
          intención.
          <br />
          Orando es más que una simple aplicación; es una comunidad de creyentes
          comprometidos con la oración y la intercesión. En un mundo lleno de
          desafíos, Orando ofrece un espacio de esperanza, solidaridad y
          crecimiento espiritual para todos aquellos que buscan fortalecer su
          vida de oración y su relación con Dios.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }} variant="h6">
          CÓMO SOLICITAR ORACIONES A LA COMUNIDAD
        </Typography>
        <Typography>
          Accede a la Sección de Peticiones: Dentro de la aplicación, busca la
          sección dedicada a las peticiones de oración.
          <br />
          Completa tu Solicitud: Verás un recuadro donde se te hará la pregunta:
          "¿Por qué quieres que pidamos?" Aquí puedes escribir detalladamente tu
          solicitud de oración. Expresar con sinceridad y claridad tus
          necesidades o preocupaciones permitirá a la comunidad orar con más
          intensidad por ti. <br />
          Elige la Intercesión de un Santo: Tendrás la opción de pedir la
          intercesión de un santo. Se te presentará una lista de santos, y
          puedes seleccionar aquel con el que sientes una conexión especial o
          que consideras más apropiado para tu petición.
          <br />
          Indica tu estado de Ánimo: Para que la comunidad pueda comprender
          mejor tu situación y orar de manera más específica por ti, puedes
          compartir tu estado de ánimo actual. Ya sea que te sientas ansioso,
          agradecido, esperanzado o necesitado de consuelo, esta información
          ayudará a los que oran por ti a conectarse más profundamente con tu
          situación.
          <br /> Una vez completados estos pasos, tu solicitud de oración se
          publicará, y los miembros de la comunidad podrán unirse a ti en
          oración, ofreciendo su apoyo, amor y fe. Recuerda que no estás solo en
          tu camino espiritual; la comunidad está aquí para acompañarte y
          sostener tus intenciones con amor y comprensión.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }} variant="h6">
          CÓMO INTERACTUAR CON LAS SOLICITUDES DE ORACIÓN DE LOS USUARIOS
        </Typography>
        <Typography>
          Reaccionar a las Peticiones de Oración: Después de que una solicitud
          de oración se publique, verás un botón de "reacciones" que incluye
          opciones como "Te abrazo", "Rezo por ti", "Te acompaño" y "Te doy
          amor". Estas reacciones permiten expresar rápidamente tu apoyo y
          solidaridad con la persona que ha solicitado la oración. Cada reacción
          se contabiliza, mostrando así el amor y la unidad de la comunidad.
          <br />
          Tiempo de Oración dinámico: Al hacer clic en las manos juntas, se
          activará una dinámica que te proporcionará un tiempo específico para
          orar por la intención presentada. Este enfoque te invita a tomarte un
          momento de reflexión y diálogo con Dios, uniéndote en espíritu con la
          persona que ha hecho la solicitud.
          <br />
          Comentar en las Peticiones: Junto al botón de reacciones, encontrarás
          el ícono de comentarios. Al hacer clic en él, podrás leer los
          comentarios que otros miembros de la comunidad han dejado en respuesta
          a la solicitud de oración. Aquí también puedes escribir tu propio
          comentario, ofreciendo palabras de aliento, compartiendo una palabra
          de Dios o simplemente dejando saber a la persona que estás orando por
          ella.
          <br />
          Recuerda que cada interacción, ya sea una reacción o un comentario, es
          una oportunidad para fortalecer los lazos comunitarios y mostrar el
          amor y la compasión que caracterizan a nuestra comunidad. Juntos,
          podemos crear un espacio de apoyo mutuo y crecimiento espiritual,
          donde cada persona se sienta vista, amada y sostenida en oración.
          <br />
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }} variant="h6">
          CÓMO INTEGRASE O CREAR UNA COMUNIDAD
        </Typography>
        <Typography>
          En Orando, fomentamos la creación de comunidades orantes centradas en
          causas específicas o intereses comunes. Unirte o crear una comunidad
          te permite conectar con personas que comparten tus mismas inquietudes
          espirituales y objetivos de oración. Para hacerlo toma en cuenta los
          siguientes puntos.
          <br />
          Unirse a una comunidad Existente: Navega por el apartado de
          comunidades para descubrir las que ya están disponibles. Lee la
          descripción de cada una para entender su propósito y misión.
          <br />
          Únete a la Comunidad: Cuando encuentres una comunidad que te interese,
          simplemente haz clic en el botón "Unirte". De esta manera, comenzarás
          a recibir notificaciones y te integrarás a las actividades y oraciones
          programadas por los administradores.
          <br />
          Crear una nueva Comunidad: Ve al apartado de comunidades en la
          aplicación o en la página web.
          <br />
          Crea tu comunidad: Busca la opción "Crear nueva Comunidad" y haz clic
          en ella.
          <br />
          Completa los detalles: Elije un nombre que refleje el propósito o la
          causa de tu comunidad.
          <br />
          Descripción: Escribe una breve descripción para que los usuarios sepan
          de qué se trata tu comunidad.
          <br />
          Programar: El material espiritual que ofrece la aplicación, lo puedes
          programar en las fechas y el tiempo que decidas. De esta manera la
          comunidad iniciará a realizar actividades comunes.
          <br />
          Tipo de Comunidad: Decide si quieres que tu comunidad sea pública o
          privada.
          <br />
          Envía tu solicitud para Comunidades Públicas: tu solicitud será
          enviada a nuestro equipo de soporte para su revisión. Una vez
          aprobada, cualquier miembro podrá unirse a tu comunidad.
          <br />
          Comunidades Privadas: Al elegir esta opción, es importante que
          compartas el enlace para permitir la integración de nuevos miembros.
          Solo el administrador y los invitados pueden visualizar esta
          comunidad.
          <br />
          Al crear o unirte a una comunidad en Orando, estás dando un paso hacia
          la creación de un espacio personalizado de apoyo mutuo, crecimiento
          espiritual y oración colectiva.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }} variant="h6">
          CÓMO USAR EL APARTADO DE NOVENAS
        </Typography>
        <Typography>
          Este apartado es un espacio dedicado a las oraciones tradicionales que
          consisten en orar durante nueve días consecutivos. Esta práctica es
          comúnmente utilizada para confiar una intención a Dios o pedir una
          gracia particular. Cada novena está dedicada a un santo específico y
          contiene oraciones diseñadas para ese propósito.
          <br />
          ¿Cómo funciona?
          <br />
          Material: La aplicación ofrece la oración de cada día de la novena
          tanto en audio como en texto, permitiéndote elegir el formato que
          prefieras.
          <br />
          Selección de Novena: Elige la novena del santo que desees orar y
          descubre oraciones específicas para cada uno de ellos.
          <br />
          Compartir: Después de seleccionar la novena, decide si es para uso
          personal o comunitario. Si eres administrador de una comunidad, puedes
          programarla para el grupo. Además, tienes la opción de compartir la
          novena con tus amigos mediante un enlace.
          <br />
          Programar: El apartado te permite planificar tu tiempo de oración.
          Puedes establecer la hora y los días en los que realizarás la oración
          diaria, adaptándola a tu rutina.
          <br />
          Notificaciones: Activa las notificaciones y recibirás recordatorios de
          la hora de la oración diaria, garantizando que no te pierdas ningún
          día de tu novena, ya sea que la hayas elegido personalmente o que el
          administrador de tu comunidad la haya programado.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }} variant="h6">
          CÓMO USAR EL APARTADO DE ORACIONES
        </Typography>
        <Typography>
          El apartado de Oraciones es un espacio para la práctica de la oración
          vocal, que permite un diálogo de corazón a corazón con Dios. El
          Rosario, es el mejor ejemplo de esta oración repetitiva, que te
          permite contemplar la vida de Jesucristo y repetir sus palabras. La
          repetición fortalece la oración ayudando a un diálogo más profundo con
          Jesús al permitir mantenerlo presente en nuestros corazones y mentes.
          <br />
          ¿Cómo funciona?
          <br />
          Material: La aplicación ofrece las oraciones en formatos de audio y
          texto, para que elijas el que mejor se adapte a tus preferencias.
          <br />
          Selección: Elige la oración que deseas practicar y lleva un registro
          en tu muro personal.
          <br />
          Compartir: Cada oración puede ser compartida con comunidades si eres
          administrador de alguna. Además, puedes compartir un enlace con amigos
          para invitarlos a unirse. Recuerda que solo los miembros tendrán
          acceso al contenido compartido.
          <br />
          Programar: Planifica tu tiempo de oración definiendo la hora y los
          días para realizarla, ajustándola a tu rutina diaria.
          <br />
          Notificaciones: Activa las notificaciones y recibirás recordatorios de
          la hora de la oración, ayudándote a mantener tus compromisos
          espirituales.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }} variant="h6">
          CÓMO USAR EL APARTADO “RUTAS DE FE”
        </Typography>
        <Typography>
          El apartado "Rutas de Fe" se enfoca en la reflexión y la
          evangelización, brindando contenidos que enriquecen y fortalecen la fe
          de los usuarios. Ofrece una variedad de temas seleccionados para guiar
          al usuario en su camino espiritual y pautas para la conversión.
          Además, proporciona materiales específicos para la preparación hacia
          festividades y eventos significativos de la Iglesia, permitiendo a los
          usuarios comprender el significado y la importancia de cada
          celebración.
          <br />
          ¿Cómo funciona?
          <br />
          Material: La aplicación ofrece las reflexiones en un formato de audio.
          Estas reflexiones se presentan en cápsulas diarias, siguiendo un orden
          consecutivo que se ajustan al tema.
          <br />
          Selección: elige el tema de reflexión que prefieras, puedes considerar
          el tiempo litúrgico en el que te encuentras. Estos materiales están
          diseñados para enriquecer y fortalecer tu fe.
          <br />
          Compartir: Los materiales pueden ser compartidos con amigos a través
          de un enlace. Si eres administrador de una comunidad también podrás
          programar el contenido para que los miembros disfruten del material.
          <br />
          Programar: Planifica tu tiempo para dedicarlo a la reflexión del
          material, permitiéndote organizarte de acuerdo a tu disponibilidad.
          <br />
          Notificaciones: Activa las notificaciones para recibir recordatorios
          que te ayudarán a mantener el ritmo de escucha y reflexión del
          material.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }} variant="h6">
          CHAT{" "}
        </Typography>
        <Typography>
          Dentro de la aplicación, contamos con una función de chat que te
          permite conectarte con otros usuarios. Para acceder a esta
          herramienta, ve al muro principal y busca en la parte superior derecha
          el icono de mensaje, junto a las notificaciones. Al seleccionarlo, se
          abrirá el chat, facilitando la comunicación con otros miembros de la
          comunidad.
          <br />
          Conéctate y Comunica. En el chat de "Orando", tienes la oportunidad de
          interactuar con otros usuarios que comparten tu fe o se han
          solidarizado con tus peticiones de oración. Este espacio te permite
          establecer lazos de amistad y construir relaciones significativas,
          enriqueciendo tu experiencia espiritual y fortaleciendo la comunidad.
          <br />
          ¡Únete a la comunidad de "Orando" y transforma tu vida espiritual! Con
          nuestra aplicación, diseñada pensando en ti, tendrás al alcance de tu
          mano herramientas poderosas para fortalecer tu relación con Dios.
          <br />
          Disfruta de un diseño intuitivo y amigable que se adapta tanto a
          dispositivos Android como a Apple. Personaliza tu experiencia,
          selecciona los recursos que más te resuenen y forma parte de una
          comunidad activa y solidaria que comparte tus mismos valores y
          aspiraciones espirituales.
        </Typography>
      </Grid>
    </Container>
  );
}
