import React, { useMemo, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { getCurrentTimeStamp } from "../helpers/useMoment";
import { getUniqueID } from "../helpers/getUniqueId";
import { getCurrentUser, getPrayer, updatePrayer } from "../services/Firestore";
import { useLoaderData, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebaseConfig";
import { serverTimestamp } from "firebase/firestore";

export async function loader({ params }) {
  const prayer = await getPrayer(params.prayerId);
  const id = params.prayerId;
  return { prayer, id };
}

export default function PrayerUpdate() {
  let navigate = useNavigate();

  const fileInputRef = useRef();

  const { prayer, id } = useLoaderData();

  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [data, setData] = useState({
    title: prayer.title,
    image: prayer.image,
  });
  const [errors, setErrors] = useState({
    title: false,
    image: false,
  });

  useMemo(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  const validation = () => {
    let temp = {};
    temp.title = data.title.trim() ? false : true;
    temp.image = data.image !== null ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === false);
  };

  const savePrayer = async (e) => {
    e.preventDefault();
    if (validation()) {
      if (data.image.name || data.audio.name) {
        handleOpen();
        const postPicsRef = ref(storage, `prayers/${data.image.name}`);
        const uploadTask = uploadBytesResumable(postPicsRef, data.image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(progress);
          },
          (error) => {
            console.error(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((response) => {
              const object = {
                title: data.title,
                image: response,
                timeStamp: getCurrentTimeStamp("LLL"),
                createdAt: serverTimestamp(),
                userName: currentUser.name,
                userId: currentUser.userID,
                postID: getUniqueID(),
              };
              updatePrayer(id, object);
              navigate("/oraciones");
            });
          }
        );
      } else {
        const object = {
          title: data.title,
          image: data.image,
          timeStamp: prayer.timeStamp,
          createdAt: prayer.createdAt,
          userName: prayer.userName,
          userId: prayer.userId,
          postID: prayer.postID,
        };
        updatePrayer(id, object);
        navigate("/oraciones");
      }
      await setData({});
      handleClose();
    } else {
      console.log("Error");
    }
  };

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setData({
      ...data,
      image: file,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <Typography variant="h5" gutterBottom fontWeight={600}>
          Guardar oración
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Detalles
            </Typography>
            <Typography>Titulo, descripcion corta, imagen...</Typography>
          </Grid>
          <Grid item xs={8}>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "rgb(255, 255, 255)",
                color: "rgb(33, 43, 54)",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                backgroundImage: "none",
                overflow: "hidden",
                position: "relative",
                boxshadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                borderRadius: 5,
                zIndex: 0,
                p: 3,
              }}
            >
              <TextField
                id="outlined-multiline-static"
                fullWidth
                label="Titulo de la oración"
                sx={{ mb: 3 }}
                name="title"
                value={data.title}
                onChange={handleOnChange}
                error={errors.title}
                helperText={errors.title ? "Es requerido este campo" : ""}
              />
              {/* <TextField
              id="outlined-multiline-static"
              fullWidth
              placeholder="Oración"
              sx={{ mb: 3 }}
              multiline
              rows={4}
              name="prayer"
              value={data.prayer}
              onChange={handleOnChange}
              error={errors.prayer}
              helperText={errors.prayer ? "Es requerido este campo" : ""}
            />
            <TextField
              fullWidth
              type="file"
              name="audioFile"
              label="Subir audio"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  accept: "audio/*",
                },
              }}
              sx={{ mb: 3 }}
              onChange={handleFileChangeAudio}
            /> */}
              <TextField
                fullWidth
                type="file"
                ref={fileInputRef}
                label="Subir imagen"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "image/*",
                  },
                }}
                onChange={handleFileChange}
              />
              {errors.image && (
                <FormHelperText sx={{ color: "red", ml: 2, mt: -2, mb: 2 }}>
                  {errors.image ? "Este campo es requerido" : ""}
                </FormHelperText>
              )}
            </Paper>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                m: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  onClick={savePrayer}
                  sx={{ textTransform: "initial" }}
                >
                  Actualizar Publicación
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
