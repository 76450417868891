import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Link,
  Badge,
  Avatar,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ChatIcon from "@mui/icons-material/Chat";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Outlet, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
// import { auth, messaging } from "../firebaseConfig";
import { auth } from "../firebaseConfig";
import { onLogout } from "../services/Auth";
import {
  deleteNotification,
  getCurrentUser,
  getThanks,
  updateBadge,
} from "../services/Firestore";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import logo from "../assets/logoBar.png";
import { getToken, onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import logo2 from "../assets/donación.png";
import plancarte from "../assets/pp.png";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Root() {
  const theme = useTheme();

  let navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorNotifications, setAnchorNotifications] = useState(null);
  const [thanks, setThanks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  useMemo(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  const handleCloseNotiMenu = () => {
    setAnchorNotifications(null);
  };

  const handleOpenNotiMenu = (event) => {
    setAnchorNotifications(event.currentTarget);
  };

  const requestPermission = async () => {
    // const token = await getToken(messaging, {
    //   vapidKey: process.env.REACT_APP_VAPID_KEY,
    // }).catch((error) => console.log(error));
    // if (token) console.log("tu token:", token);
    // if (!token) console.log("no tienes token, rey");
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user?.accessToken) {
        console.log("token");
        if (!user?.emailVerified) {
          console.log("verificado");
          navigate("/main");
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
    requestPermission();
    // onMessage(messaging, (message) => {
    //   toast(message.notification.title);
    // });
    if (currentUser.userID !== undefined) {
      getThanks(currentUser?.userID, setThanks);
    }
  }, [navigate, currentUser.userID]);

  const handleLogout = () => {
    onLogout();
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfile = () => {
    navigate("/profile", {
      state: { id: currentUser?.id },
    });
    setAnchorElUser(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return loading ? (
    <></>
  ) : (
    <Box sx={{ display: "flex" }}>
      <ToastContainer />
      <AppBar position="fixed" open={open} sx={{ background: "#133A7B" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
            }}
            onClick={() => navigate("/")}
          >
            <img
              height="auto"
              width={150}
              src={logo}
              alt="..."
              loading="lazy"
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />
          <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            <Link
              href="/donar"
              sx={{ display: "flex", flexDirection: "row" }}
              underline="none"
            >
              <Typography sx={{ color: "white", mr: 1 }}>Donar</Typography>
              <img
                height="auto"
                width={26}
                src={logo2}
                alt="..."
                style={{ marginRight: 8 }}
                loading="lazy"
              />
            </Link>
            <Link href="/chat" underline="none">
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                sx={{ color: "white" }}
              >
                <Badge badgeContent={0} color="error">
                  <ChatIcon />
                </Badge>
              </IconButton>
            </Link>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleOpenNotiMenu}
            >
              <Badge
                badgeContent={thanks.filter((item) => !item.status).length}
                color="error"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorNotifications}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorNotifications)}
              onClose={handleCloseNotiMenu}
            >
              <Box sx={{ width: 400 }}>
                <Typography fontWeight={600} sx={{ p: 2 }}>
                  Notificaciones
                </Typography>
                {thanks.map((item) => (
                  <Box
                    sx={{
                      p: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                    onClick={() => updateBadge(item.id)}
                  >
                    <Box sx={{ width: "80%" }}>
                      <Typography>
                        <b>{item.userName}</b>
                      </Typography>
                      <Typography variant="caption">{item.message}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mr: 1,
                        mt: 1,
                      }}
                    >
                      <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={() => deleteNotification(item.id)}>
                          Borrar
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Menu>
            {currentUser?.name}
            <IconButton onClick={handleOpenUserMenu} size="large">
              <Avatar alt="" src={currentUser?.image} />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleProfile}>
                <Typography textAlign="center">Perfil</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Salir</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {currentUser.type === "admin" && (
          <Box sx={{ ml: 2 }}>
            <Typography fontWeight="bold">ADMINISTRADOR</Typography>
          </Box>
        )}
        {currentUser.type === "admin" && (
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => navigate("/adminRegister")}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Registro"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => navigate("/acceptCommunity")}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText
                  primary="A. Comunidades"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        )}
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => navigate("/blog")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <EditNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Blog" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => navigate("/community")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Diversity3Icon />
              </ListItemIcon>
              <ListItemText
                primary="Comunidades"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => navigate("/novenas")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SelfImprovementIcon />
              </ListItemIcon>
              <ListItemText primary="Novenas" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => navigate("/oraciones")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AutoStoriesIcon />
              </ListItemIcon>
              <ListItemText
                primary="Oraciones"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => navigate("/retos")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ConnectWithoutContactIcon />
              </ListItemIcon>
              <ListItemText
                primary="Rutas de fe"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => navigate("/plancarte")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    backgroundImage: `url(${plancarte})`,
                    height: 25,
                    width: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    // mr: -20,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Plancarte"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ p: 1 }}>
          <Typography
            variant="caption"
            sx={{ mr: 1 }}
            onClick={() => navigate("/about")}
          >
            ¿Quienes somos?
          </Typography>
          <Typography
            variant="caption"
            sx={{ mr: 1 }}
            onClick={() => navigate("/does")}
          >
            ¿Cómo funciona?
          </Typography>
          <Typography variant="caption" onClick={() => navigate("/conditions")}>
            Terminos y condiciones
          </Typography>
        </Box>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {/* <Container> */}
        <Outlet />
        {/* </Container> */}
      </Main>
    </Box>
  );
}
