import React, { useMemo, useState } from "react";
import { Container, Grid } from "@mui/material";
import { getCommunitys } from "../services/Firestore";
import CardAccept from "../components/CardAccept";

export default function AcceptCommunitys() {
  const [allcommunitys, setAllCommunitys] = useState([]);

  useMemo(() => {
    getCommunitys(setAllCommunitys);
  }, []);

  return (
    <Container>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        {allcommunitys.map(
          (posts) =>
            posts.publish === false &&
            posts.status === "Pública" && (
              <CardAccept key={posts.id} posts={posts} />
            )
        )}
      </Grid>
    </Container>
  );
}
