import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  getChallenge,
  getCommunityUser,
  getCurrentUser,
  postJoinChallengeCommunity,
} from "../services/Firestore";
import { useLoaderData, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DialogRetos from "../components/DialogRetos";
import ReplyIcon from "@mui/icons-material/Reply";
import ProgramButton from "../components/ProgramButton";
import CloseIcon from "@mui/icons-material/Close";
import ProgramCommunity from "../components/ProgramCommunity";

export async function loader({ params }) {
  const challenge = await getChallenge(params.challengeId);
  const uid = params.challengeId;
  return { challenge, uid };
}

export default function ChallengeList() {
  let navigate = useNavigate();

  const { challenge, uid } = useLoaderData();

  const [openSnack, setOpenSnack] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [openShare, setOpenShare] = useState(false);
  const [communitys, setCommunitys] = useState([]);
  const [community, setCommunity] = useState("");

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  useEffect(() => {
    if (currentUser.id) {
      getCommunityUser(setCommunitys, currentUser?.id);
    }
  }, [currentUser]);

  const handleClickOpen = (item) => {
    setOpen(true);
    setData(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cutText = (text) => {
    if (text.length > 150) {
      return text.slice(0, 150) + "...";
    } else {
      return text;
    }
  };

  const handleClickOpenShare = () => {
    setOpenShare(true);
  };

  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const handleChange = (event) => {
    setCommunity(event.target.value);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseSnack}>
        LISTO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Container>
      <DialogRetos
        open={open}
        handleClose={handleClose}
        data={data}
        challenge={challenge}
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message="Se ha compartido la ruta de fe"
        action={action}
      />
      <Dialog onClose={handleCloseShare} open={openShare}>
        <DialogTitle>Selecciona una comunidad</DialogTitle>
        <DialogContent>
          {communitys.length !== 0 && (
            <FormControl fullWidth size="small">
              <Select
                displayEmpty
                name="community"
                value={community}
                onChange={handleChange}
                defaultValue="Seleccione comunidad"
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <em>Seleccione comunidad</em>
                </MenuItem>
                {communitys.map((item) => (
                  <MenuItem value={item.id}>{item.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShare}>Cancelar</Button>
          <Button
            onClick={() => {
              postJoinChallengeCommunity({
                challenge: uid,
                community: community,
              });
              handleCloseShare();
              handleClickSnack();
            }}
          >
            Compartir
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        {width >= 600 && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                variant="text"
                startIcon={<ArrowBackIosIcon />}
                sx={{ textTransform: "initial" }}
                onClick={() => navigate("/retos")}
              >
                Volver
              </Button>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  startIcon={<ReplyIcon />}
                  onClick={handleClickOpenShare}
                  sx={{ mr: 2 }}
                >
                  Compartir en comunidad
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "initial",
                    mr: 2,
                  }}
                  onClick={() => {
                    if (navigator.share) {
                      navigator
                        .share({
                          title: document.title,
                          url: window.location.href,
                        })
                        .then(() => console.log("Página compartida con éxito"))
                        .catch((error) =>
                          console.error("Error al compartir:", error)
                        );
                    } else {
                      console.log(
                        "La API Web Share no está soportada en este navegador."
                      );
                    }
                  }}
                >
                  COMPARTIR
                </Button>
                <ProgramButton uid={uid} />
                <ProgramCommunity uid={uid} />
              </Box>
            </Box>
          </Grid>
        )}
        <List
          sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Lista de retos
            </ListSubheader>
          }
        >
          {challenge.challenges.map((item) => (
            <ListItem
              alignItems="flex-start"
              onClick={() => handleClickOpen(item)}
            >
              <ListItemAvatar>
                <Avatar alt="" src={item.image} />
              </ListItemAvatar>
              <ListItemText
                primary={item.title}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {cutText(item.description)}
                    </Typography>
                    {/* — {item.prayer} */}
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Container>
  );
}
