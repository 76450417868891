import React, { forwardRef } from "react";
import {
  AppBar,
  Box,
  Chip,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Preview({ handleClose, open, data, previewUrl }) {
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Preview
            </Typography>
          </Toolbar>
        </AppBar>
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                backgroundImage: `url(${previewUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: 500,
                p: 10,
                mt: 5,
              }}
            >
              <Box
                sx={{
                  p: 6,
                }}
              >
                <Typography variant="h4">{data.title}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 5 }}>
              <Container maxWidth="md">
                <Typography>{data.description}</Typography>
                <Typography component="div">
                  <div dangerouslySetInnerHTML={{ __html: data.content }} />
                </Typography>
              </Container>
              <Divider />
              <Box mt={3} mb={3}>
                <Chip label={data.keywords} />
              </Box>
              <Divider />
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
