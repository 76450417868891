import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import Ora3 from "../assets/plan.png";

export default function Plancarte() {
  const handleOpenNewTab = () => {
    const url = "https://www.antonioplancarte.org";
    window.open(url, "_blank");
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Typography
            fontSize={30}
            textAlign="right"
            sx={{ color: "#133A7B", fontWeight: "bold" }}
          >
            "Debo seguir el ejemplo de Cristo, retirándome a un lugar solitario
            y meditando, <br /> cuando me sienta triste, desconsolado y falto de
            espíritu... <br />
            que sólo en la oración hallará alivio el espíritu tibio y afligido"
          </Typography>
          <br />
          <Typography textAlign="right" sx={{ color: "#133A7B" }}>
            Venerable José Antonio Plancarte y Labastida{" "}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
            sx={{
              fontWeight: "400",
              color: "#133A7B",
              textAlign: "justify",
              mt: 15,
              fontSize: 25,
            }}
          >
            El Venerable José Antonio Plancarte, nacido en Ciudad de México en
            1840, fue un sacerdote diocesano de profunda fe cuyo legado se
            centró en la educación, con especial atención a las mujeres y
            sacerdotes. Su devoción a la Santísima Virgen de Guadalupe fue un
            pilar en su vida, impulsándolo a promover su devoción y coronación.
            <br />
            <br />
            Hombre de profunda oración, que vivió con "Valor y Confianza",
            encontrando su fortaleza en la comunión con Jesús Eucaristía. Sus
            últimos días los dedicó a la oración en el Templo Expiatorio de San
            Felipe de Jesús, que él mismo fundó. En el año 2020, el Papa
            Francisco lo declaró Venerable, subrayando su vida de virtud y
            santidad. Su ejemplo y devoción nos inspiran a confiarle nuestras
            preocupaciones y dolores, creyendo que desde el cielo intercede ante
            Dios en nuestro camino de fe.
          </Typography>
          <Box onClick={handleOpenNewTab}>
            <Typography
              sx={{
                fontSize: 25,
                color: "#133A7B",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Conoce más.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${Ora3})`,
              height: 800,
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              borderRadius: 10,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            textAlign="left"
            sx={{ fontSize: 30, mt: 5, color: "#133A7B", fontWeight: "bold" }}
          >
            "Deja tus preocupaciones en manos del Venerable José Antonio
            Plancarte <br /> y confía en su poderosa intercesión."
            <Link
              href="https://www.orando.mx/novenasPreview/lbkJ4D42moCJ9a5nNgCS"
              variant="h6"
              color="inherit"
            >
              <Typography sx={{ fontSize: 30 }}>Haz tu novena.</Typography>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
