import React from "react";
import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";

export default function CardComment({ comment }) {
  return (
    <Box sx={{ mt: 2 }}>
      <CardHeader
        avatar={<Avatar>{comment.name.slice(0, 1)}</Avatar>}
        title={comment.name}
        subheader={comment.timeStamp}
      />
      <CardContent sx={{ ml: 7 }}>
        <Typography variant="body2" color="text.secondary">
          {comment.comment}
        </Typography>
      </CardContent>
      <Divider />
    </Box>
  );
}
