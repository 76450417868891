import React, { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { deleteBlog } from "../services/Firestore";

export default function CardBlog({ posts, currentUser }) {
  let navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const cutText = (text) => {
    if (text.length > 190) {
      return text.slice(0, 190) + "...";
    } else {
      return text;
    }
  };

  const cutHeader = (text) => {
    if (text.length > 28) {
      return text.slice(0, 28) + "...";
    } else {
      return text;
    }
  };

  return (
    <Card
      sx={{ mt: 2, borderRadius: 10, background: "#406C97" }}
      key={posts.id}
    >
      <CardHeader
        action={
          currentUser?.userID === posts.userId ? (
            <IconButton aria-label="settings" onClick={handleOpenUserMenu}>
              <MoreVertIcon />
            </IconButton>
          ) : (
            <Box></Box>
          )
        }
        title={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#FFFFFF" }}
            >
              {cutHeader(posts.title)}
            </Typography>
          </Box>
        }
      />
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={() => navigate(`/blogEdit/${posts.id}`)}>
          <Typography textAlign="center">Editar</Typography>
        </MenuItem>
        <MenuItem onClick={() => deleteBlog(posts.id)}>
          <Typography textAlign="center" sx={{ color: "red" }}>
            Borrar
          </Typography>
        </MenuItem>
      </Menu>
      <CardActionArea onClick={() => navigate(`/blogPreview/${posts.id}`)}>
        <CardMedia
          component="img"
          height="194"
          image={posts.image}
          alt="Paella dish"
        />
        <CardContent sx={{ minHeight: 80 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ color: "#FFFFFF" }}
          >
            {cutText(posts.description)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
