import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function CardCommunity({ posts, currentUser }) {
  let navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      {currentUser?.type === "admin" && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 1, mt: 1 }}>
          <IconButton aria-label="settings" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => navigate(`/communityEdit/${posts.id}`)}>
              Editar
            </MenuItem>
          </Menu>
        </Box>
      )}
      <Box
        onClick={() => navigate(`/communityPreview/${posts.id}`)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${posts.image})`,
          height: 400,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          borderRadius: 10,
        }}
      >
        {isHovered && (
          <Box
            sx={{
              background: "#B78244",
              width: "100%",
              height: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottomRightRadius: 35,
              borderBottomLeftRadius: 35,
            }}
          >
            <Typography
              gutterBottom
              variant="h4"
              textAlign="center"
              fontWeight={600}
              color="#fff"
              sx={{ p: 0, m: 0 }}
            >
              {posts.title}
            </Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
