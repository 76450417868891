import React from "react";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";

export default function Contact() {
  return (
    <Box>
      <Typography variant="h5" gutterBottom fontWeight={600}>
        Contactenos
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link underline="hover" color="inherit" href="/">
            HOME
          </Link>
          <Typography>Contactenos</Typography>
        </Breadcrumbs>
      </Box>
    </Box>
  );
}
