import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Hidden,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebaseConfig";
import ProgramButton from "../components/ProgramButton";
import { useLocation } from "react-router-dom";
import ProgramCommunity from "../components/ProgramCommunity";

export default function PrayerDetails() {
  const location = useLocation();

  const { item, uid } = location.state || {};

  const [status, setStatus] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [audio, setAudio] = useState(new Audio());

  useEffect(() => {
    if (item.audioName !== "") {
      const uri = ref(storage, `prayers/${item.audioName}`);
      getDownloadURL(uri).then((url) => {
        setAudio(new Audio(url));
      });
    }
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
      setDuration(audio.duration);
    };
    audio.addEventListener("timeupdate", handleTimeUpdate);
  }, [item.audioName]);

  useEffect(() => {
    if (item.audioName !== "") {
      downloadAudio();
    }
  }, []);

  useEffect(() => {
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
      setDuration(audio.duration);
    };
    audio.addEventListener("timeupdate", handleTimeUpdate);
  }, [audio]);

  const downloadAudio = () => {
    const uri = ref(storage, `prayers/${item.audioName}`);
    getDownloadURL(uri).then((url) => {
      setAudio(new Audio(url));
    });
  };

  const handleSliderChange = (event, newValue) => {
    audio.currentTime = newValue;
  };

  const togglePlay = () => {
    if (status) {
      audio.pause();
    } else {
      audio.play();
    }
    setStatus(!status);
  };

  return (
    <Grid container spacing={2}>
      <Hidden smDown mdDown>
        <Grid item xs={0} sm={6} md={5}>
          <Box sx={{ height: "88vh", width: "100%" }}>
            <Box
              sx={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${item.image})`,
                height: "100%",
              }}
            />
          </Box>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={0} md={4}>
        <Box sx={{ height: "88vh", width: "100%" }}>
          <Typography variant="h5" fontWeight="bold" sx={{ color: "#133A7B" }}>
            {item.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              mb: 2,
            }}
          >
            <Box
              sx={{
                borderRadius: 50,
                backgroundColor: "#133A7B",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 50,
                height: 50,
              }}
            >
              {status ? (
                <IconButton aria-label="play/pause" onClick={togglePlay}>
                  <PauseIcon sx={{ height: 38, width: 38, color: "white" }} />
                </IconButton>
              ) : (
                <IconButton aria-label="play/pause" onClick={togglePlay}>
                  <PlayArrowIcon
                    sx={{ height: 38, width: 38, color: "white" }}
                  />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                width: "85%",
              }}
            >
              <Slider
                value={currentTime}
                max={duration}
                onChange={handleSliderChange}
                aria-label="Tiempo de reproducción"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <ProgramButton uid={uid} />
            <ProgramCommunity uid={uid} />
            {/* <Button
              variant="contained"
              sx={{
                textTransform: "initial",
                ml: 2,
              }}
              onClick={() => {
                if (navigator.share) {
                  navigator
                    .share({
                      title: document.title,
                      url: window.location.href,
                    })
                    .then(() => console.log("Página compartida con éxito"))
                    .catch((error) =>
                      console.error("Error al compartir:", error)
                    );
                } else {
                  console.log(
                    "La API Web Share no está soportada en este navegador."
                  );
                }
              }}
            >
              COMPARTIR
            </Button> */}
          </Box>
          <div dangerouslySetInnerHTML={{ __html: item.prayer }} />
        </Box>
      </Grid>
      <Hidden smDown mdDown>
        <Grid item xs={0} sm={0} md={3}>
          <Box
            sx={{
              width: "80%",
              height: "86vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              textAlign="right"
              sx={{ color: "#133A7B" }}
            >
              “Mientras sigues la oración manten el Valor y la Confianza. Dios
              te ama y te sostiene”
            </Typography>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  );
}
