import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, IconButton, Typography } from "@mui/material";
import rea1 from "../assets/rea1.png";
import rea2 from "../assets/rea2.png";
import rea3 from "../assets/rea3.png";
import rea4 from "../assets/rea4.png";
import { reactionPost } from "../services/Firestore";
import Favorite from "@mui/icons-material/Favorite";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
  },
}));

export default function Reactions({
  userId,
  postId,
  reactioned,
  handleOpenReactions,
  liked,
}) {
  const handleReaction = (type) => {
    reactionPost(userId, postId, reactioned, type);
  };

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: 300,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.5)",
                },
              }}
              onClick={() => handleReaction("Te abrazo")}
            >
              <img src={rea1} alt="" style={{ width: 25, height: 25 }} />
              <Typography sx={{ fontSize: 8 }}>Te abrazo</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.5)",
                },
              }}
              onClick={() => handleReaction("Rezo por ti")}
            >
              <img src={rea2} alt="" style={{ width: 25, height: 25 }} />
              <Typography sx={{ fontSize: 8 }}>Rezo por ti</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.5)",
                },
              }}
              onClick={() => handleReaction("Te acompaño")}
            >
              <img src={rea3} alt="" style={{ width: 25, height: 25 }} />
              <Typography sx={{ fontSize: 8 }}>Te acompaño</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.5)",
                },
              }}
              onClick={() => handleReaction("Te doy amor")}
            >
              <img src={rea4} alt="" style={{ width: 25, height: 25 }} />
              <Typography sx={{ fontSize: 8 }}>Te doy amor</Typography>
            </Box>
          </Box>
        </React.Fragment>
      }
    >
      <IconButton aria-label="add to favorites" onClick={handleOpenReactions}>
        <Favorite />
      </IconButton>
    </HtmlTooltip>
  );
}
