import React, { useMemo, useState, forwardRef, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { getCurrentTimeStamp } from "../helpers/useMoment";
import { getUniqueID } from "../helpers/getUniqueId";
import {
  postStatus,
  getStatus,
  updatePost,
  getCommunityUser,
  getStatusCommunity,
  getSingleStatus,
  postMiracle,
  postThanks,
  getUserCommunity,
} from "../services/Firestore";
import Card from "./Card";
import { styled } from "@mui/material/styles";
import { serverTimestamp } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import CardGratitude from "./CardGratitude";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "0px solid #ced4da",
    fontSize: 14,
    padding: "0px 0px 10px 0px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
    },
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Publish({ currentUser, communityId }) {
  let location = useLocation();

  const [allStatuses, setAllStatus] = useState([]);
  const [currentPost, setCurrentPost] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [community, setCommunity] = useState([]);
  const [state, setState] = useState({
    intention: "",
    destination: communityId === undefined ? "" : "Comunidad",
    intercession: "",
    mood: "",
    miracle: false,
    community: communityId === undefined ? "" : communityId,
  });
  const [errors, setErrors] = useState({
    intention: false,
    destination: false,
    intercession: false,
    mood: false,
  });
  const [openMiracle, setOpenMiracle] = useState(false);
  const [miracleData, setMiracleData] = useState({
    intention: "",
    intercession: "",
    mood: "",
    miracle: true,
    gratitude: "",
  });
  const [usersCount, setUsersCount] = useState([]);

  const validation = () => {
    let temp = {};
    temp.intention = state.intention.trim() ? false : true;
    temp.destination = state.destination.trim() ? false : true;
    temp.intercession = state.intercession.trim() ? false : true;
    temp.mood = state.mood.trim() ? false : true;
    if (state.destination === "Comunidad") {
      temp.community = state.community.trim() ? false : true;
    }
    setErrors(temp);
    return Object.values(temp).every((x) => x === false);
  };

  const sendStatus = async () => {
    if (validation()) {
      let object = {
        intention: state.intention,
        destination: state.destination,
        intercession: state.intercession,
        mood: state.mood,
        community: state.community,
        miracle: state.miracle,
        timeStamp: getCurrentTimeStamp("LLL"),
        createdAt: serverTimestamp(),
        userEmail: currentUser?.email,
        userName: currentUser?.name,
        postID: getUniqueID(),
        userID: currentUser?.id,
      };
      await postStatus(object);
      await postThanks(
        usersCount.map((item) => item.user),
        "publico en la comunidad",
        currentUser?.name
      );
      await setOpen(false);
      setIsEdit(false);
      await setState({
        intention: "",
        destination: communityId === undefined ? "" : "Comunidad",
        intercession: "",
        mood: "",
        miracle: false,
        community: communityId === undefined ? "" : communityId,
      });
    }
  };

  const miracleStatus = async () => {
    await postMiracle(miracleData);
    await updatePost(currentPost.id, state);
    await setOpenMiracle(false);
  };

  const getEditData = (posts) => {
    setOpen(true);
    setState({
      intention: posts?.intention,
      destination: posts?.destination,
      intercession: posts?.intercession,
      mood: posts?.mood,
      community: posts?.community,
    });
    setCurrentPost(posts);
    setIsEdit(true);
  };

  const updateStatus = () => {
    updatePost(currentPost.id, state);
    setOpen(false);
  };

  useMemo(() => {
    if (communityId === undefined && location?.state?.id === undefined) {
      getStatus(setAllStatus);
    }
    if (communityId !== undefined) {
      getStatusCommunity(setAllStatus, communityId);
      getUserCommunity(setUsersCount, communityId);
    }
    if (location?.state?.id) {
      getSingleStatus(setAllStatus, location?.state?.id);
    }
  }, [communityId, location?.state?.id]);

  useEffect(() => {
    if (currentUser?.id) {
      getCommunityUser(setCommunity, currentUser?.id);
    }
  }, [currentUser?.id]);

  const handleOnChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleOpenMiracle = (posts) => {
    setOpenMiracle(true);
    setMiracleData({
      intention: posts?.intention,
      intercession: posts?.intercession,
      mood: posts?.mood,
      gratitude: "",
      miracle: true,
      timeStamp: posts?.timeStamp,
      createdAt: posts?.createdAt,
      userEmail: posts?.userEmail,
      userName: posts?.userName,
      postID: posts?.postID,
      userID: posts?.userID,
    });
    setState({
      intention: posts.intention,
      destination: posts.destination,
      intercession: posts.intercession,
      mood: posts.mood,
      community: posts.community,
      miracle: true,
      timeStamp: getCurrentTimeStamp("LLL"),
      createdAt: serverTimestamp(),
      userEmail: currentUser?.email,
      userName: currentUser?.name,
      postID: getUniqueID(),
      userID: currentUser?.id,
    });
    setCurrentPost(posts);
  };

  const handleCloseMiracle = () => {
    setOpenMiracle(false);
  };

  return (
    <>
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "rgb(255, 255, 255)",
          color: "rgb(33, 43, 54)",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          backgroundImage: "none",
          overflow: "hidden",
          position: "relative",
          boxshadow:
            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
          borderRadius: 5,
          zIndex: 0,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center ",
            justifyContent: "center",
          }}
        >
          <Avatar
            alt={currentUser?.name ? currentUser?.name.slice(0, 1) : ""}
            src={currentUser?.image}
            sx={{ mr: 2 }}
          />
          <TextField
            variant="outlined"
            fullWidth
            placeholder="¿Por qué quieres que pidamos?"
            onClick={() => {
              setOpen(true);
              setIsEdit(false);
            }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Paper>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpen(false);
          setState({
            intention: "",
            destination: "",
            intercession: "",
            mood: "",
          });
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Box
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgb(33, 43, 54)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              backgroundImage: "none",
              overflow: " hidden",
              position: "relative",
              boxshadow:
                "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
              zIndex: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar
                alt={currentUser?.name ? currentUser?.name.slice(0, 1) : ""}
                src={currentUser?.image}
                sx={{ mr: 1 }}
              />
              <Typography>
                {currentUser?.name ? currentUser?.name : ""}
              </Typography>
            </Box>
            <InputBase
              name="intention"
              fullWidth
              multiline
              rows={4}
              placeholder="¿Por qué quieres que pidamos?"
              sx={{ mt: 1 }}
              value={state.intention}
              onChange={handleOnChange}
              error={errors.intention}
              helpertext={errors.intention ? "Es requerido este campo" : ""}
            />
            {communityId === undefined ? (
              <FormControl fullWidth size="small">
                <Select
                  displayEmpty
                  name="destination"
                  value={state.destination}
                  onChange={handleOnChange}
                  input={<BootstrapInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Destinatario</em>;
                    }
                    return selected;
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={"Público"}>Público</MenuItem>
                  <MenuItem value={"Comunidad"}>Comunidad</MenuItem>
                </Select>
                {errors.destination && (
                  <FormHelperText sx={{ color: "red" }}>
                    Falta este campo
                  </FormHelperText>
                )}
              </FormControl>
            ) : null}
            {state.destination === "Comunidad" && communityId === undefined && (
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Select
                  displayEmpty
                  name="community"
                  value={state.community}
                  onChange={handleOnChange}
                  input={<BootstrapInput />}
                  defaultValue="Seleccione comunidad"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Seleccione comunidad</em>
                  </MenuItem>
                  {community.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
                {errors.destination && (
                  <FormHelperText sx={{ color: "red" }}>
                    Falta este campo
                  </FormHelperText>
                )}
              </FormControl>
            )}
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <Select
                displayEmpty
                name="intercession"
                value={state.intercession}
                onChange={handleOnChange}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Por intercesión</em>;
                  }
                  return selected;
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={"La Santísima Virgen de Guadalupe"}>
                  La Santísima Virgen de Guadalupe
                </MenuItem>
                <MenuItem value={"V. J. Antonio Plancarte"}>
                  V. J. Antonio Plancarte
                </MenuItem>
                <MenuItem value={"San Antonio de Padua"}>
                  San Antonio de Padua
                </MenuItem>
                <MenuItem value={"San José"}>San José</MenuItem>
                <MenuItem value={"San Luis Gonzaga"}>San Luis Gonzaga</MenuItem>
                <MenuItem value={"San Francisco de Asís"}>
                  San Francisco de Asís
                </MenuItem>
                <MenuItem value={"San Vicente de Paúl"}>
                  San Vicente de Paúl
                </MenuItem>
                <MenuItem value={"Santa Terecita del Niño Jésus"}>
                  Santa Terecita del Niño Jésus
                </MenuItem>
              </Select>
              {errors.intercession && (
                <FormHelperText sx={{ color: "red" }}>
                  Falta este campo
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <Select
                displayEmpty
                name="mood"
                value={state.mood}
                onChange={handleOnChange}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Estado de animo</em>;
                  }
                  return selected;
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={"Feliz"}>Feliz</MenuItem>
                <MenuItem value={"Triste"}>Triste</MenuItem>
                <MenuItem value={"Entusiasmado"}>Entusiasmado</MenuItem>
                <MenuItem value={"Confiado"}>Confiado</MenuItem>
                <MenuItem value={"Preocupado"}>Preocupado</MenuItem>
                <MenuItem value={"Ilusionado"}>Ilusionado</MenuItem>
                <MenuItem value={"Temereso"}>Temereso</MenuItem>
                <MenuItem value={"Renovado"}>Renovado</MenuItem>
                <MenuItem value={"Amado"}>Amado</MenuItem>
                <MenuItem value={"Optimista"}>Optimista</MenuItem>
              </Select>
              {errors.mood && (
                <FormHelperText sx={{ color: "red" }}>
                  Falta este campo
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            sx={{ textTransform: "initial" }}
          >
            Cancelar
          </Button>
          <Button
            disabled={state.intention.length > 0 ? false : true}
            onClick={isEdit ? updateStatus : sendStatus}
            sx={{ textTransform: "initial" }}
          >
            {isEdit ? "Actualizar" : "Publicar"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMiracle}
        onClose={() => {
          setOpenMiracle(false);
          setMiracleData({
            ...miracleData,
            gratitude: "",
          });
        }}
      >
        <DialogTitle>Milagro</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Cuéntale al mundo que tu petición fue cumplida!
          </DialogContentText>
          <TextField
            name="intention"
            fullWidth
            multiline
            rows={4}
            placeholder="Escribe un mensaje"
            sx={{ mt: 1 }}
            value={miracleData.gratitude}
            onChange={(event) => {
              setMiracleData({ ...miracleData, gratitude: event.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMiracle}>Cancelar</Button>
          <Button
            disabled={miracleData.gratitude === "" ? true : false}
            onClick={() => miracleStatus()}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        {allStatuses
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((posts) =>
            location?.state?.id === undefined ? (
              <Card posts={posts} getEditData={getEditData} />
            ) : (
              <CardGratitude
                posts={posts}
                getEditData={getEditData}
                handleOpenMiracle={handleOpenMiracle}
              />
            )
          )}
      </Box>
    </>
  );
}
