import React, { useMemo, useState } from "react";
import { Box, Button, Container, Grid, Paper, TextField } from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebaseConfig";
import { getCurrentTimeStamp } from "../helpers/useMoment";
import { serverTimestamp } from "firebase/firestore";
import {
  getAddPrayer,
  getCurrentUser,
  updateAddPrayer,
} from "../services/Firestore";
import ReactQuill from "react-quill";

export async function loader({ params }) {
  const prayer = await getAddPrayer(params.prayerAddId);
  const id = params.prayerAddId;
  return { prayer, id };
}

export default function AddPrayerUpdate() {
  let navigate = useNavigate();

  const { prayer, id } = useLoaderData();

  const [currentUser, setCurrentUser] = useState({});
  const [data, setData] = useState({
    title: prayer.title,
    prayer: prayer.prayer,
    image: prayer.image,
    audio: prayer.audio,
    audioName: prayer.audioName,
    prayerGroup: prayer.prayerGroup,
    timeStamp: prayer.timeStamp,
    createdAt: prayer.createdAt,
    userName: prayer.userName,
    userId: prayer.userId,
  });
  const [errors, setErrors] = useState({
    title: "",
    prayer: "",
    image: null,
    audio: null,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  useMemo(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
    if (file && file.size > maxSizeInBytes) {
      setErrorMessage("La imagen excede el tamaño máximo permitido (1MB)");
      e.target.value = "";
    } else {
      setErrorMessage("");
    }
    setData({
      ...data,
      image: file,
    });
  };

  const handleAudioChange = (e) => {
    const file = e.target.files[0];
    setData({
      ...data,
      audio: file,
    });
  };

  const validation = () => {
    let temp = {};
    temp.title = data.title.trim() ? false : true;
    temp.prayer = data.prayer.trim() ? false : true;
    temp.image = data.image !== null ? false : true;
    temp.audio = data.audio !== null ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      if (data.image.name || data.audio.name) {
        const postPicsRef = ref(storage, `prayers/${data.image.name}`);
        const uploadTask = uploadBytesResumable(postPicsRef, data.image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(progress);
          },
          (error) => {
            console.error(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((response) => {
              let music = ref(storage, `prayers/${data.audio.name}`);
              const metadata = {
                contentType: data.audio.type,
              };
              const uploadTaskAudio = uploadBytesResumable(
                music,
                data.audio,
                metadata
              );
              uploadTaskAudio.on(
                "state_changed",
                (snapshot) => {
                  const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                  console.log(progress);
                },
                (error) => {
                  console.error(error);
                },
                () => {
                  getDownloadURL(uploadTaskAudio.snapshot.ref).then(
                    (responseA) => {
                      const object = {
                        title: data.title,
                        prayer: data.prayer,
                        image: response,
                        audio: responseA,
                        audioName: data.audio.name,
                        prayerGroup: prayer.prayerGroup,
                        timeStamp: getCurrentTimeStamp("LLL"),
                        createdAt: serverTimestamp(),
                        userName: currentUser.name,
                        userId: currentUser.userID,
                      };
                      updateAddPrayer(id, object);
                      navigate(`/prayersPreview/${prayer.prayerGroup}`);
                    }
                  );
                }
              );
            });
          }
        );
      } else {
        console.log("perro");
        const object = {
          title: data.title,
          prayer: data.prayer,
          image: data.image,
          audio: data.audio,
          audioName: data.audioName,
          prayerGroup: data.prayerGroup,
          timeStamp: data.timeStamp,
          createdAt: data.createdAt,
          userName: data.userName,
          userId: data.userId,
        };
        updateAddPrayer(id, object);
        navigate(`/prayersPreview/${prayer.prayerGroup}`);
      }
      await setData({});
    } else {
      console.log("Error");
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="text"
            startIcon={<ArrowBackIosIcon />}
            sx={{ textTransform: "initial" }}
            onClick={() => navigate(`/prayersPreview/${prayer.prayerGroup}`)}
          >
            Volver
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <TextField
              label="Nombre de la oración"
              fullWidth
              name="title"
              value={data.title}
              onChange={handleChange}
              error={errors.title}
              helperText={errors.title ? "Es requerido este campo" : ""}
            />
            <ReactQuill
              theme="snow"
              value={data.prayer}
              placeholder="Escribe tu oracion..."
              style={{ height: 200, marginBottom: 50, marginTop: 30 }}
              onChange={(e) =>
                setData({
                  ...data,
                  prayer: e,
                })
              }
            />
            <TextField
              type="file"
              label="Subir imagen"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  accept: "image/*",
                },
              }}
              sx={{ mt: 3 }}
              onChange={(e) => handleFileChange(e)}
              error={errors.image}
              helperText={errors.image ? errorMessage : ""}
            />
            <TextField
              type="file"
              label="Subir audio"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  accept: "audio/*",
                },
              }}
              sx={{ mt: 3 }}
              onChange={(e) => handleAudioChange(e)}
              error={errors.audio}
              helperText={errors.audio ? "Es requerido este campo" : ""}
            />
          </Paper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              m: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ textTransform: "initial" }}
              >
                Actualizar Oración
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
