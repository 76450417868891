import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { Login } from "../services/Auth";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import logo from "../assets/logo-orando.png";
import { auth } from "../firebaseConfig";

export default function SignIn() {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [credentails, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user?.accessToken) {
  //       console.log("llegue");
  //       if (user.emailVerified) {
  //         navigate("/");
  //       } else {
  //         setLoading(false);
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // }, [navigate]);

  const validation = () => {
    let temp = {};
    var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    temp.email = regex.test(credentails.email) ? false : true;
    temp.password = credentails.password.trim() ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      try {
        let res = await Login(credentails.email, credentails.password);
        localStorage.setItem("userEmail", res.user.email);
        navigate("/");
        // toast.success("Signed In to Linkedin!");
      } catch (err) {
        alert(err);
        //   toast.error("Please Check your Credentials");
      }
    }
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentails,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container component="main" maxWidth="xs" sx={{ height: "100vh" }}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              height="auto"
              width="80%"
              src={logo}
              alt="..."
              loading="lazy"
            />
          </Box>
          <Typography component="h1" variant="h5">
            Inicia sesión
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1, width: "35%" }}
          >
            <TextField
              margin="normal"
              fullWidth
              id="email"
              name="email"
              onChange={handleChange}
              error={errors.email}
              placeholder="Correo electrónico"
              helperText={errors.email ? "Es requerido este campo" : ""}
            />
            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={errors.password}
            >
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Contraseña"
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>
                {errors.password ? "Este campo es requerido" : ""}
              </FormHelperText>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#133A7B",
                "&:hover": {
                  backgroundColor: "#ceab51",
                  borderColor: "#ceab51",
                  color: "#FFFFFF",
                },
              }}
            >
              Entrar
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Link href="/forgot" variant="body2">
                    Olvidaste tu contraseña?
                  </Link>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    No tienes una cuenta?
                  </Typography>
                  <Link href="/register" variant="body2">
                    Entra aqui
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
