import React, { useMemo, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CardCommunity from "../components/CardCommunity";
import { getCommunitys, getCurrentUser } from "../services/Firestore";

export default function Community() {
  let navigate = useNavigate();

  const [allcommunitys, setAllCommunitys] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useMemo(() => {
    getCommunitys(setAllCommunitys);
    getCurrentUser(setCurrentUser);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" fontWeight={600}>
          Comunidades
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ textTransform: "initial" }}
          onClick={() => {
            navigate("/communityCreate");
          }}
        >
          Crear Comunidad
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        {allcommunitys.map(
          (posts) =>
            posts.publish &&
            posts.status === "Pública" && (
              <CardCommunity
                key={posts.id}
                posts={posts}
                currentUser={currentUser}
              />
            )
        )}
      </Grid>
    </Box>
  );
}
