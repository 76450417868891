import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  IconButton,
} from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  deleteNovenaRef,
  getCommunityUser,
  getCurrentUser,
  getNovenaDetail,
  getRelationNovena,
  postJoinNovena,
  shareNovenaCommunity,
} from "../services/Firestore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import calendario from "../assets/calendario.png";
import rezando from "../assets/rezando.png";
import DialogNovena from "../components/DialogNovena";
import ProgramButton from "../components/ProgramButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ProgramCommunity from "../components/ProgramCommunity";

export async function loader({ params }) {
  const novena = await getNovenaDetail(params.prayId);
  const uid = params.prayId;
  return { novena, uid };
}

export default function NovenasPreview() {
  let navigate = useNavigate();

  const { novena, uid } = useLoaderData();

  const [width, setWidth] = useState(window.innerWidth);

  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [join, setJoin] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState("");
  const [prayerText, setPrayerText] = useState("");
  const [community, setCommunity] = useState("");
  const [communitys, setCommunitys] = useState([]);
  const [openShare, setOpenShare] = useState(false);

  useEffect(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  useEffect(() => {
    if (currentUser.id) {
      getRelationNovena(setJoin, currentUser.userID, uid);
      getCommunityUser(setCommunitys, currentUser?.id);
    }
  }, [currentUser.userID, currentUser, uid]);

  const handleClickOpen = (audio, text) => {
    setOpen(true);
    setSelectedAudio(audio);
    setPrayerText(text);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const handleChange = (event) => {
    setCommunity(event.target.value);
  };

  const handleClickOpenShare = () => {
    setOpenShare(true);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseSnack}>
        LISTO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Container>
      <Grid container spacing={2}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message="Se ha compartida la novena"
          action={action}
        />
        <DialogNovena
          open={open}
          handleClose={handleClose}
          selectedAudio={selectedAudio}
          prayerText={prayerText}
          novena={novena}
          uid={uid}
        />
        <Dialog onClose={handleCloseShare} open={openShare}>
          <DialogTitle>Selecciona una comunidad</DialogTitle>
          <DialogContent>
            {communitys.length !== 0 && (
              <FormControl fullWidth size="small">
                <Select
                  displayEmpty
                  name="community"
                  value={community}
                  onChange={handleChange}
                  defaultValue="Seleccione comunidad"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Seleccione comunidad</em>
                  </MenuItem>
                  {communitys.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseShare}>Cancelar</Button>
            <Button
              onClick={() => {
                shareNovenaCommunity({
                  novena: uid,
                  community: community,
                });
                handleClickSnack();
                handleCloseShare();
              }}
            >
              Compartir
            </Button>
          </DialogActions>
        </Dialog>
        {width >= 600 && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Button
                variant="text"
                startIcon={<ArrowBackIosIcon />}
                sx={{ textTransform: "initial" }}
                onClick={() => navigate("/novenas")}
              >
                Volver
              </Button>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: join.length !== 0 ? "red" : "",
                    mr: 2,
                  }}
                  startIcon={<BookmarkIcon />}
                  onClick={() =>
                    join.length !== 0
                      ? deleteNovenaRef(join[0].id)
                      : postJoinNovena({
                          novena: uid,
                          user: currentUser.userID,
                        })
                  }
                >
                  {join.length !== 0 ? "Quitar" : "Guardar"}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<ArrowOutwardIcon />}
                  onClick={handleClickOpenShare}
                  sx={{ mr: 2 }}
                >
                  Compartir en comunidad
                </Button>
                <Button
                  variant="contained"
                  startIcon={<ArrowOutwardIcon />}
                  onClick={() => {
                    if (navigator.share) {
                      navigator
                        .share({
                          title: document.title,
                          url: window.location.href,
                        })
                        .then(() => console.log("Página compartida con éxito"))
                        .catch((error) =>
                          console.error("Error al compartir:", error)
                        );
                    } else {
                      console.log(
                        "La API Web Share no está soportada en este navegador."
                      );
                    }
                  }}
                >
                  Enviar
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CardMedia
              component="img"
              sx={{ width: 250, height: 250, borderRadius: 30 }}
              image={novena.image}
              alt="Live from space album cover"
            />
          </Box>
          {width >= 800 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: 250,
                mt: 3,
              }}
            >
              <img src={calendario} alt="" style={{ width: 80 }} />
              <ProgramButton uid={uid} />
              <ProgramCommunity uid={uid} />
              <Typography>Comenzar ahora!</Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="h4" color="#133A7B">
            {novena.title}
          </Typography>
          <Typography sx={{ mt: 2 }}>{novena.about}</Typography>
          {width >= 600 && (
            <Box
              sx={{
                display: "flex",
                mt: 2,
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  bgcolor: "background.paper",
                }}
              >
                <ListItemButton
                  onClick={() =>
                    handleClickOpen(novena.audioNameOne, novena.prayerOne)
                  }
                >
                  <ListItemIcon>
                    <img src={rezando} alt="" style={{ width: 20 }} />
                  </ListItemIcon>
                  <ListItemText primary="Primer dia" />
                  <MoreHorizIcon />
                </ListItemButton>
                <ListItemButton
                  onClick={() =>
                    handleClickOpen(novena.audioNameTwo, novena.prayerTwo)
                  }
                >
                  <ListItemIcon>
                    <img src={rezando} alt="" style={{ width: 20 }} />
                  </ListItemIcon>
                  <ListItemText primary="Segundo dia" />
                  <MoreHorizIcon />
                </ListItemButton>
                <ListItemButton
                  onClick={() =>
                    handleClickOpen(novena.audioNameThree, novena.prayerThree)
                  }
                >
                  <ListItemIcon>
                    <img src={rezando} alt="" style={{ width: 20 }} />
                  </ListItemIcon>
                  <ListItemText primary="Tercer dia" />
                  <MoreHorizIcon />
                </ListItemButton>
                <ListItemButton
                  onClick={() =>
                    handleClickOpen(novena.audioNameFour, novena.prayerFour)
                  }
                >
                  <ListItemIcon>
                    <img src={rezando} alt="" style={{ width: 20 }} />
                  </ListItemIcon>
                  <ListItemText primary="Cuarto dia" />
                  <MoreHorizIcon />
                </ListItemButton>
                <ListItemButton
                  onClick={() =>
                    handleClickOpen(novena.audioNameFive, novena.prayerFive)
                  }
                >
                  <ListItemIcon>
                    <img src={rezando} alt="" style={{ width: 20 }} />
                  </ListItemIcon>
                  <ListItemText primary="Quinto dia" />
                  <MoreHorizIcon />
                </ListItemButton>
              </List>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  bgcolor: "background.paper",
                }}
              >
                <ListItemButton
                  onClick={() =>
                    handleClickOpen(novena.audioNameSix, novena.prayerSix)
                  }
                >
                  <ListItemIcon>
                    <img src={rezando} alt="" style={{ width: 20 }} />
                  </ListItemIcon>
                  <ListItemText primary="Sexto dia" />
                  <MoreHorizIcon />
                </ListItemButton>
                <ListItemButton
                  onClick={() =>
                    handleClickOpen(novena.audioNameSeven, novena.prayerSeven)
                  }
                >
                  <ListItemIcon>
                    <img src={rezando} alt="" style={{ width: 20 }} />
                  </ListItemIcon>
                  <ListItemText primary="Septimo dia" />
                  <MoreHorizIcon />
                </ListItemButton>
                <ListItemButton
                  onClick={() =>
                    handleClickOpen(novena.audioNameEight, novena.prayerEight)
                  }
                >
                  <ListItemIcon>
                    <img src={rezando} alt="" style={{ width: 20 }} />
                  </ListItemIcon>
                  <ListItemText primary="Octavo dia" />
                  <MoreHorizIcon />
                </ListItemButton>
                <ListItemButton
                  onClick={() =>
                    handleClickOpen(novena.audioNameNine, novena.prayerNine)
                  }
                >
                  <ListItemIcon>
                    <img src={rezando} alt="" style={{ width: 20 }} />
                  </ListItemIcon>
                  <ListItemText primary="Noveno dia" />
                  <MoreHorizIcon />
                </ListItemButton>
              </List>
            </Box>
          )}
        </Grid>
        {width <= 600 && (
          <Grid item xs={12}>
            <List
              sx={{
                width: "100%",
                maxWidth: 400,
                bgcolor: "background.paper",
              }}
            >
              <ListItemButton
                onClick={() =>
                  handleClickOpen(novena.audioNameOne, novena.prayerOne)
                }
              >
                <ListItemIcon>
                  <img src={rezando} alt="" style={{ width: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Primer dia" />
                <MoreHorizIcon />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  handleClickOpen(novena.audioNameTwo, novena.prayerTwo)
                }
              >
                <ListItemIcon>
                  <img src={rezando} alt="" style={{ width: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Segundo dia" />
                <MoreHorizIcon />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  handleClickOpen(novena.audioNameThree, novena.prayerThree)
                }
              >
                <ListItemIcon>
                  <img src={rezando} alt="" style={{ width: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Tercer dia" />
                <MoreHorizIcon />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  handleClickOpen(novena.audioNameFour, novena.prayerFour)
                }
              >
                <ListItemIcon>
                  <img src={rezando} alt="" style={{ width: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Cuarto dia" />
                <MoreHorizIcon />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  handleClickOpen(novena.audioNameFive, novena.prayerFive)
                }
              >
                <ListItemIcon>
                  <img src={rezando} alt="" style={{ width: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Quinto dia" />
                <MoreHorizIcon />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  handleClickOpen(novena.audioNameSix, novena.prayerSix)
                }
              >
                <ListItemIcon>
                  <img src={rezando} alt="" style={{ width: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Sexto dia" />
                <MoreHorizIcon />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  handleClickOpen(novena.audioNameSeven, novena.prayerSeven)
                }
              >
                <ListItemIcon>
                  <img src={rezando} alt="" style={{ width: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Septimo dia" />
                <MoreHorizIcon />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  handleClickOpen(novena.audioNameEight, novena.prayerEight)
                }
              >
                <ListItemIcon>
                  <img src={rezando} alt="" style={{ width: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Octavo dia" />
                <MoreHorizIcon />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  handleClickOpen(novena.audioNameNine, novena.prayerNine)
                }
              >
                <ListItemIcon>
                  <img src={rezando} alt="" style={{ width: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Noveno dia" />
                <MoreHorizIcon />
              </ListItemButton>
            </List>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
