import { Box } from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-spring-3d-carousel";
import Car1 from "../assets/car1.png";
import Car2 from "../assets/car2.png";
import Car3 from "../assets/car3.png";
import Car4 from "../assets/car4.png";
import Car5 from "../assets/car5.png";
import Ora2 from "../assets/ora2.png";

const getTouches = (evt) => {
  return (
    evt.touches || evt.originalEvent.touches // browser API
  );
};

export default function SliderAnimate() {
  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
  });

  const slides = [
    {
      key: "1",
      content: (
        <Box
          sx={{
            width: 270,
            height: 400,
            borderRadius: 3,
            backgroundImage: `url(${Car1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      ),
    },
    {
      key: "2",
      content: (
        <Box
          sx={{
            width: 270,
            height: 400,
            borderRadius: 3,
            backgroundImage: `url(${Car2})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            position: "absolute",
            zIndex: 2,
            mt: -10,
          }}
        />
      ),
    },
    {
      key: "3",
      content: (
        <Box
          sx={{
            width: 270,
            height: 400,
            borderRadius: 3,
            backgroundImage: `url(${Car3})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
        />
      ),
    },
    {
      key: "4",
      content: (
        <Box
          sx={{
            width: 270,
            height: 400,
            borderRadius: 3,
            backgroundImage: `url(${Car4})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
        />
      ),
    },
    {
      key: "5",
      content: (
        <Box
          sx={{
            width: 270,
            height: 400,
            borderRadius: 3,
            backgroundImage: `url(${Ora2})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
        />
      ),
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => setState({ goToSlide: index }) };
  });

  const handleTouchStart = (evt) => {
    if (!state.enableSwipe) {
      return;
    }

    const firstTouch = getTouches(evt)[0];
    setState({
      ...state,
      xDown: firstTouch.clientX,
      yDown: firstTouch.clientY,
    });
  };

  const handleTouchMove = (evt) => {
    if (!state.enableSwipe || (!state.xDown && !state.yDown)) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = state.xDown - xUp;
    let yDiff = state.yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        /* left swipe */
        setState({
          goToSlide: state.goToSlide + 1,
          xDown: null,
          yDown: null,
        });
      } else {
        /* right swipe */
        setState({
          goToSlide: state.goToSlide - 1,
          xDown: null,
          yDown: null,
        });
      }
    }
  };

  return (
    <div
      style={{ width: "80%", height: "400px", margin: "0 auto" }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <Carousel
        slides={slides}
        goToSlide={state.goToSlide}
        offsetRadius={state.offsetRadius}
        showNavigation={state.showNavigation}
        animationConfig={state.config}
      />
    </div>
  );
}
