import React, { useMemo, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { getCurrentTimeStamp } from "../helpers/useMoment";
import { getUniqueID } from "../helpers/getUniqueId";
import { addChallenge, getCurrentUser } from "../services/Firestore";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebaseConfig";
import { serverTimestamp } from "firebase/firestore";

export default function ChallengeCreate() {
  let navigate = useNavigate();

  const fileInputRef = useRef();

  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [formularios, setFormularios] = useState([]);
  const [data, setData] = useState({
    title: "",
    description: "",
    image: "",
  });
  const [errors, setErrors] = useState({
    title: false,
    description: false,
    prayer: false,
    audio: false,
  });

  const handleAgregarFormulario = () => {
    setFormularios([...formularios, {}]);
  };

  const handleChange = (index, e) => {
    const nuevosFormularios = [...formularios];
    nuevosFormularios[index][e.target.name] =
      e.target && e.target.files && e.target.files.length > 0
        ? e.target.files[0]
        : e.target.value;
    setFormularios(nuevosFormularios);
  };

  useMemo(() => {
    getCurrentUser(setCurrentUser);
  }, []);

  const validation = () => {
    let temp = {};
    // temp.title = data.title.trim() ? false : true;
    // temp.description = data.description.trim() ? false : true;
    // temp.prayer = data.prayer.trim() ? false : true;
    // temp.audioFile = data.audioFile !== null ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const saveChallenge = async (e) => {
    e.preventDefault();
    if (validation()) {
      handleOpen();
      const audioNames = [];
      // const imageUrls = [];
      const uploadTasks = formularios.map(async (audioFile) => {
        if (audioFile) {
          audioNames.push(audioFile.audioFile.name);
          let music = ref(storage, `challenges/${audioFile.audioFile.name}`);
          const metadata = {
            contentType: audioFile.audioFile.type,
          };
          const uploadTask = uploadBytesResumable(
            music,
            audioFile.audioFile,
            metadata
          );
          await uploadTask;
        }
      });
      await Promise.all(uploadTasks);
      // const uploadImages = formularios.map(async (imageFile, index) => {
      //   if (imageFile) {
      //     let img = ref(storage, `challenges/${imageFile.image.name}`);
      //     const uploadImage = uploadBytesResumable(img, imageFile.image);
      //     const url = await uploadImage.getDownloadURL(
      //       uploadImage.snapshot.ref
      //     );
      //     imageUrls.push(url);
      //     await uploadImage;
      //   }
      // });
      // await Promise.all(uploadImages);
      // Creamos una copia de array1 y lo actualizamos
      const updatedArray1 = formularios.map((item, index) => {
        // Comprobamos que el índice en array2 existe
        if (audioNames[index]) {
          return {
            audioFile: audioNames[index],
            // image:
            title: item.title,
            description: item.description,
            prayer: item.prayer,
          };
        }
        // Si no hay correspondencia, devolvemos el mismo item
        return item;
      });

      // carga de imagen principal
      const postPicsRef = ref(storage, `challenges/${data.image.name}`);
      const uploadTask = uploadBytesResumable(postPicsRef, data.image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(progress);
        },
        (error) => {
          console.error(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((response) => {
            const object = {
              title: data.title,
              description: data.description,
              image: response,
              challenges: updatedArray1,
              timeStamp: getCurrentTimeStamp("LLL"),
              createdAt: serverTimestamp(),
              userName: currentUser.name,
              userId: currentUser.userID,
              postID: getUniqueID(),
            };
            addChallenge(object);
            navigate("/retos");
            // await setData({});
            handleClose();
          });
        }
      );
    } else {
      console.log("Error");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
    if (file && file.size > maxSizeInBytes) {
      // setErrorMessage("La imagen excede el tamaño máximo permitido (1MB)");
      e.target.value = "";
    } else {
      // setErrorMessage("");
      // const imageUrl = URL.createObjectURL(file);
      // setPreviewUrl(imageUrl);
    }
    setData({
      ...data,
      image: file,
    });
  };

  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h5" gutterBottom fontWeight={600}>
        Crear un reto
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Detalles
          </Typography>
          <Typography>Titulo, descripcion corta, imagen...</Typography>
        </Grid>
        <Grid item xs={8}>
          <Paper
            elevation={1}
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgb(33, 43, 54)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              backgroundImage: "none",
              overflow: "hidden",
              position: "relative",
              boxshadow:
                "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
              borderRadius: 5,
              zIndex: 0,
              p: 3,
              mt: 3,
            }}
          >
            <TextField
              id="outlined-multiline-static"
              fullWidth
              label="Titulo del reto"
              name="title"
              value={data.title}
              onChange={handleOnChange}
              error={errors.title}
              helperText={errors.title ? "Es requerido este campo" : ""}
            />
            <TextField
              id="outlined-multiline-static"
              fullWidth
              label="Descripción del reto"
              multiline
              rows={4}
              name="description"
              value={data.description}
              onChange={handleOnChange}
              error={errors.description}
              helperText={errors.description ? "Es requerido este campo" : ""}
              sx={{ mt: 3 }}
            />
            <TextField
              fullWidth
              type="file"
              name="image"
              ref={fileInputRef}
              label="Subir imagen"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  accept: "image/*",
                },
              }}
              sx={{ mt: 3 }}
              onChange={handleFileChange}
            />
          </Paper>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          {formularios.map((formulario, index) => (
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "rgb(255, 255, 255)",
                color: "rgb(33, 43, 54)",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                backgroundImage: "none",
                overflow: "hidden",
                position: "relative",
                boxshadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                borderRadius: 5,
                zIndex: 0,
                p: 3,
                mt: 3,
              }}
              key={index}
            >
              <TextField
                id="outlined-multiline-static"
                fullWidth
                placeholder="Titulo del reto"
                sx={{ mb: 3 }}
                name="title"
                // value={data.title}
                value={formulario.title || ""}
                // onChange={handleOnChange}
                onChange={(e) => handleChange(index, e)}
                error={errors.title}
                helperText={errors.title ? "Es requerido este campo" : ""}
              />
              <TextField
                id="outlined-multiline-static"
                fullWidth
                placeholder="Descripción"
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="description"
                // value={data.description}
                value={formulario.description || ""}
                // onChange={handleOnChange}
                onChange={(e) => handleChange(index, e)}
                error={errors.description}
                helperText={errors.description ? "Es requerido este campo" : ""}
              />
              <TextField
                id="outlined-multiline-static"
                fullWidth
                placeholder="A la Acción "
                sx={{ mb: 3 }}
                multiline
                rows={4}
                name="prayer"
                value={formulario.prayer || ""}
                // value={data.prayer}
                // onChange={handleOnChange}
                onChange={(e) => handleChange(index, e)}
                error={errors.prayer}
                helperText={errors.prayer ? "Es requerido este campo" : ""}
              />
              <TextField
                fullWidth
                type="file"
                name="audioFile"
                ref={fileInputRef}
                label="Subir audio"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "audio/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleChange(index, e)}
              />
              {/* <TextField
                fullWidth
                type="file"
                name="image"
                ref={fileInputRef}
                label="Subir Imagen"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    accept: "image/*",
                  },
                }}
                sx={{ mb: 3 }}
                onChange={(e) => handleChange(index, e)}
              /> */}
            </Paper>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              m: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={() => handleAgregarFormulario()}
                sx={{ textTransform: "initial", mr: 2 }}
              >
                Agregar Tarea
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={saveChallenge}
                sx={{ textTransform: "initial" }}
              >
                Guardar Reto
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
