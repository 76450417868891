import React, { useEffect, useMemo, useState } from "react";
import {
  getCurrentUser,
  getAllUsers,
  getConnections,
} from "../services/Firestore";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
export default function CardMiracle({ posts }) {
  const [currentUser, setCurrentUser] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [, setIsConnected] = useState(false);

  useMemo(() => {
    getCurrentUser(setCurrentUser);
    getAllUsers(setAllUsers);
  }, []);

  useEffect(() => {
    getConnections(currentUser?.id, posts.userID, setIsConnected);
  }, [currentUser?.id, posts.userID]);

  return (
    <Box key={posts.id}>
      <Card sx={{ mt: 2, background: "#406C97", borderRadius: 10 }}>
        <CardHeader
          avatar={
            <Avatar
              src={
                currentUser?.id === posts.userID
                  ? currentUser?.image
                  : posts?.userName.slice(0, 1)
              }
              alt={posts?.userName.slice(0, 1)}
            />
          }
          title={
            <Typography sx={{ color: "white" }}>
              {allUsers.filter((user) => user.id === posts.userID)[0]?.name}
            </Typography>
          }
          subheader={
            <Typography sx={{ color: "white", fontStyle: "italic" }}>
              {posts.miracle && "Por intercesión de " + posts.intercession}
            </Typography>
          }
        />
        <CardContent>
          <Typography variant="body2" sx={{ color: "white" }}>
            {posts.gratitude}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
