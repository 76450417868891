import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import {
  getCurrentUser,
  getNovenas,
  getNovenasSearch,
} from "../services/Firestore";
import CardNovenas from "../components/CardNovenas";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

export default function Novenas() {
  let navigate = useNavigate();

  const [allNovenas, setAllNovenas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useMemo(() => {
    getNovenas(setAllNovenas);
    getCurrentUser(setCurrentUser);
  }, []);

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      getNovenasSearch(setSearchResults, searchTerm);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" fontWeight={600}>
          Novenas
        </Typography>
        {currentUser.type === "admin" && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              navigate("/novenaCreate");
            }}
            sx={{ textTransform: "initial" }}
          >
            Agregar
          </Button>
        )}
      </Box>
      <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
        <OutlinedInput
          id="outlined-adornment-amount"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <IconButton position="start">
              <SearchIcon />
            </IconButton>
          }
        />
      </FormControl>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {searchTerm !== ""
          ? searchResults.map((posts) => (
              <CardNovenas posts={posts} currentUser={currentUser} />
            ))
          : allNovenas.map((posts) => (
              <CardNovenas
                key={posts.id}
                posts={posts}
                currentUser={currentUser}
              />
            ))}
      </Grid>
    </Box>
  );
}
