import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {
  getBlogs,
  getBlogsSearch,
  getCurrentUser,
} from "../services/Firestore";
import { useNavigate } from "react-router-dom";
import CardBlog from "../components/CardBlog";

export default function Blog() {
  let navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState({});
  const [allBlogs, setAllBlogs] = useState([]);
  const [step, setStep] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useMemo(() => {
    getCurrentUser(setCurrentUser);
    getBlogs(setAllBlogs);
  }, []);

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      getBlogsSearch(setSearchResults, searchTerm);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" fontWeight={600}>
          Blog
        </Typography>
        {currentUser.type === "admin" && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              navigate("/blogCreate");
            }}
            sx={{ textTransform: "initial" }}
          >
            Nuevo Post
          </Button>
        )}
      </Box>
      <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
        <OutlinedInput
          id="outlined-adornment-amount"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <IconButton position="start">
              <SearchIcon />
            </IconButton>
          }
        />
      </FormControl>
      {currentUser.type === "admin" && (
        <Box>
          <Box>
            <Button
              variant="text"
              sx={{ textTransform: "initial" }}
              onClick={() => setStep(1)}
            >
              Todos
            </Button>
            <Button
              variant="text"
              sx={{ textTransform: "initial" }}
              onClick={() => setStep(2)}
            >
              Publicados
            </Button>
            <Button
              variant="text"
              sx={{ textTransform: "initial" }}
              onClick={() => setStep(3)}
            >
              Borradores
            </Button>
          </Box>
          {step === 1 && (
            <Grid container spacing={2}>
              {searchTerm !== ""
                ? searchResults.map((posts) => (
                    <Grid item xs={12} sm={6} key={posts.id}>
                      <CardBlog posts={posts} currentUser={currentUser} />
                    </Grid>
                  ))
                : allBlogs.map((posts) => (
                    <Grid item xs={12} sm={6} key={posts.id}>
                      <CardBlog posts={posts} currentUser={currentUser} />
                    </Grid>
                  ))}
            </Grid>
          )}
          {step === 2 && (
            <Grid container spacing={2}>
              {allBlogs.map(
                (posts) =>
                  posts.publish &&
                  posts.userId === currentUser.userID && (
                    <Grid item xs={12} sm={6} key={posts.id}>
                      <CardBlog posts={posts} currentUser={currentUser} />
                    </Grid>
                  )
              )}
            </Grid>
          )}
          {step === 3 && (
            <Grid container spacing={2}>
              {allBlogs.map(
                (posts) =>
                  !posts.publish && (
                    <Grid item xs={12} sm={6} key={posts.id}>
                      <CardBlog posts={posts} currentUser={currentUser} />
                    </Grid>
                  )
              )}
            </Grid>
          )}
        </Box>
      )}
      {currentUser.type !== "admin" && (
        <Grid container spacing={2}>
          {allBlogs.map(
            (posts) =>
              posts.publish && (
                <Grid item xs={12} sm={6} key={posts.id}>
                  <CardBlog posts={posts} currentUser={currentUser} />
                </Grid>
              )
          )}
        </Grid>
      )}
    </Box>
  );
}
