import React from "react";
import { Box, Breadcrumbs, Grid, Link, Typography } from "@mui/material";

export default function Conditions() {
  return (
    <Box>
      <Typography variant="h5" gutterBottom fontWeight={600}>
        POLÍTICA DE PRIVACIDAD DE LA APLICACIÓN "ORANDO"
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link underline="hover" color="inherit" href="/">
            HOME
          </Link>
          <Typography>Conditiones Generales de Uso</Typography>
        </Breadcrumbs>
      </Box>
      <Grid item xs={12}>
        <Typography>
          Bienvenido a "Orando", la aplicación desarrollada y mantenida por las
          Hijas de María Inmaculada de Guadalupe. Esta Política de Privacidad
          describe cómo recopilamos, usamos y protegemos tus datos personales
          cuando utilizas nuestra aplicación.
        </Typography>
        <Typography variant="h6">Derechos de autor</Typography>
        <Typography>
          Derechos Reservados a las Hijas de María Inmaculada de Guadalupe Todos
          los derechos relacionados con la propiedad intelectual, el nombre, la
          marca, el diseño, el contenido y cualquier otro elemento relacionado
          con la aplicación "Orando" pertenecen exclusivamente a las Hijas de
          María Inmaculada de Guadalupe. Queda estrictamente prohibido el uso,
          reproducción, distribución, transformación o cualquier otra actividad
          que vulnere estos derechos sin la autorización previa y expresa de las
          Hijas de María Inmaculada de Guadalupe.
          <br />
          Uso de la Marca y el Nombre La marca "Orando" y cualquier otro nombre,
          marca, logotipo o diseño relacionado con la aplicación son propiedad
          exclusiva de las Hijas de María Inmaculada de Guadalupe. Queda
          prohibido usar, reproducir o distribuir la marca "Orando" o cualquier
          otro elemento relacionado sin la autorización previa y expresa de las
          Hijas de María Inmaculada de Guadalupe.
          <br />
          Contenido de la Aplicación Todo el contenido, incluyendo textos,
          imágenes, audios, videos, diseños, logotipos y cualquier otro material
          presente en la aplicación "Orando", es propiedad de las Hijas de María
          Inmaculada de Guadalupe. Queda prohibida la reproducción,
          distribución, modificación, adaptación o cualquier otra forma de uso
          del contenido de la aplicación sin la autorización previa y expresa de
          las Hijas de María Inmaculada de Guadalupe.
          <br />
          Derechos de Propiedad Intelectual Todos los derechos de propiedad
          intelectual sobre la aplicación "Orando" y su contenido, incluyendo
          los derechos de autor, los derechos de marca y cualquier otro derecho
          relacionado con la propiedad intelectual, son propiedad de las Hijas
          de María Inmaculada de Guadalupe. Los usuarios no adquieren ningún
          derecho de propiedad intelectual sobre la aplicación "Orando" o su
          contenido al utilizar la aplicación.
          <br />
          Las Hijas de María Inmaculada de Guadalupe se comprometen a proteger
          la privacidad y los datos personales de los usuarios.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          1. Información que Recopilamos
        </Typography>
        <Typography>
          Información Personal: Recopilamos información que nos proporcionas
          voluntariamente, como nombre, correo electrónico y otros datos de
          contacto cuando te registras en nuestra aplicación.
          <br />
          Datos de Uso: Recopilamos información sobre cómo utilizas nuestra
          aplicación, como las funciones que utilizas y las páginas que visitas.{" "}
          <br />
          Información de Dispositivo: Recopilamos información sobre tu
          dispositivo móvil, incluyendo el modelo, sistema operativo,
          identificadores únicos y datos de red.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          2. Uso de la Información
        </Typography>
        <Typography>
          Utilizamos la información recopilada para:
          <br />
          Proporcionarte los servicios de la aplicación.
          <br />
          Mejorar y personalizar tu experiencia en nuestra aplicación.
          <br />
          Comunicarnos contigo y responder a tus consultas y comentarios.
          <br />
          Cumplir con nuestras obligaciones legales y reglamentarias.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          3. Compartir y divulgar la Información
        </Typography>
        <Typography>
          No compartimos tu información personal con terceros, excepto en los
          siguientes casos:
          <br />
          Cuando lo requiere la ley o en respuesta a procesos legales.
          <br />
          Para proteger nuestros derechos o los derechos de los usuarios.
          <br />
          Con tu consentimiento explícito.
          <br />
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          4. Seguridad de la Información
        </Typography>
        <Typography>
          Implementamos medidas de seguridad para proteger tu información contra
          accesos no autorizados, alteraciones, divulgaciones o destrucciones.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          5. Derechos ARCO
        </Typography>
        <Typography>
          Tienes derecho a acceder, rectificar, cancelar u oponerte al
          tratamiento de tus datos personales. Para ejercer estos derechos,
          puedes contactarnos a través de los datos de contacto proporcionados
          al final de esta Política de Privacidad.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          6. Cambios en la Política de Privacidad
        </Typography>
        <Typography>
          Nos reservamos el derecho de actualizar esta Política de Privacidad en
          cualquier momento. Te notificaremos sobre cualquier cambio publicando
          la nueva Política de Privacidad en esta página.
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>7. Contacto</Typography>
        <Typography>
          Cualquier uso, tratamiento o gestión de datos personales realizado por
          las Hijas de María Inmaculada de Guadalupe será conforme a las
          disposiciones de la normativa de protección de datos aplicable y a los
          términos establecidos en la política de privacidad. <br />
          Si tienes preguntas o preocupaciones sobre esta Política de Privacidad
          o sobre cómo tratamos tus datos personales, puedes contactarnos a
          través de:
          <br />
          contacto@orando.com
          <br /> Dirección postal: Priv. San Sebastián 80, San Sebastián,
          Azcapotzalco, 02460 Ciudad de México, CDMX
        </Typography>
      </Grid>
    </Box>
  );
}
