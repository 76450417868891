import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Box,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
import {
  getChats,
  getCurrentUser,
  getMessage,
  searchUser,
  selectUser,
  updateChats,
} from "../services/Firestore";
import { ChatContext } from "../context/ChatContext";

export default function Chat() {
  const { dispatch, data } = useContext(ChatContext);

  const ref = useRef();

  const [userName, setUserName] = useState("");
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");

  useMemo(() => {
    getCurrentUser(setCurrentUser);
    getMessage(data.chatId, setMessages);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [data.chatId]);

  useEffect(() => {
    if (currentUser.id) {
      getChats(currentUser.id, setChats);
    }
  }, [currentUser]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSearch = () => {
    searchUser(userName, setUser, setError);
  };

  const handleKey = (e) => {
    e.code === "Enter" && handleSearch();
  };

  const handleSelect = async () => {
    selectUser(currentUser, user, setUser, setUserName);
  };

  const handleSelectExist = (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
  };

  const handleSend = async () => {
    updateChats(data, text, currentUser.id, setText);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom fontWeight={600}>
          Chat
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Paper sx={{ height: "75vh" }}>
          <List sx={{ width: "99%", bgcolor: "background.paper" }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt={currentUser.name}
                  src="/static/images/avatar/1.jpg"
                />
              </ListItemAvatar>
              <ListItemText primary={currentUser.name} />
            </ListItem>
          </List>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl sx={{ width: "80%" }}>
              <OutlinedInput
                id="outlined-adornment-amount"
                placeholder="Buscar contactos..."
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                onKeyDown={handleKey}
                startAdornment={
                  <IconButton position="start">
                    <SearchIcon />
                  </IconButton>
                }
              />
            </FormControl>
          </Box>
          <List sx={{ width: "99%", bgcolor: "background.paper" }}>
            {error && (
              <Typography sx={{ color: "red" }}>
                Usuario no encontrado!
              </Typography>
            )}
            {user && (
              <CardHeader
                onClick={handleSelect}
                avatar={
                  <Avatar alt={user.name} src="/static/images/avatar/1.jpg" />
                }
                title={user.name}
              />
            )}
          </List>
          <List>
            {Object.entries(chats)
              ?.sort((a, b) => b[1].date - a[1].date)
              .map((chat) => (
                <ListItem
                  alignItems="flex-start"
                  key={chat[0]}
                  onClick={() => handleSelectExist(chat[1].userInfo)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={chat[1].userInfo.name}
                      src="/static/images/avatar/1.jpg"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={chat[1].userInfo.name}
                    secondary={
                      <React.Fragment>
                        {chat[1].lastMessage?.text}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Paper sx={{ height: "75vh" }}>
          <Paper elevation={1}>
            <CardHeader
              avatar={
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              }
              title={data.user?.name}
            />
          </Paper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              height: "88%",
            }}
          >
            <Box
              sx={{
                padding: 2,
                height: "calc(100% - 160px)",
                overflow: "scroll",
              }}
            >
              {messages.map((m) => (
                <Box
                  key={m.id}
                  ref={ref}
                  sx={{
                    display: "flex",
                    gap: 20,
                    flexDirection:
                      m.senderId === currentUser.id && "row-reverse",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "80%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      p: 1,
                      mb: 1,
                      alignItems: m.senderId === currentUser.id && "flex-end",
                      backgroundColor:
                        m.senderId === currentUser.id ? "#FCF9F9" : "#FFF",
                      borderRadius:
                        m.senderId === currentUser.id && "10px 0px 10px 10px",
                    }}
                  >
                    <Typography>{m.text}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  id="outlined-adornment-password"
                  placeholder="Escribe un mensaje..."
                  onChange={(e) => setText(e.target.value)}
                  value={text}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleSend}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <SendIcon sx={{ color: "#E1A800" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
