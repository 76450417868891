import React from "react";
import { Box, Breadcrumbs, Container, Link, Typography } from "@mui/material";

export default function About() {
  return (
    <Container>
      <Typography variant="h5" gutterBottom fontWeight={600}>
        ¿Quiénes somos?
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link underline="hover" color="inherit" href="/">
            HOME
          </Link>
          <Typography>Quiénes somos</Typography>
        </Breadcrumbs>
      </Box>
      <Typography>
        Orando es una aplicación diseñada para conectar a las personas en una
        comunidad de oración e intercesión. Este espacio digital funciona como
        una red social donde los usuarios pueden solicitar y ofrecer oraciones,
        encontrando solidaridad y apoyo mutuo para sus necesidades y peticiones.
        <br />
        <br />
        Además de servir como espacio de intercesión, Orando brinda recursos
        espirituales para enriquecer la vida cristiana de sus usuarios. La
        plataforma, contiene oraciones, novenas y reflexiones que nutren el
        espíritu y promueven la solidaridad entre los miembros de la comunidad.
        <br />
        <br />
        En este espacio, los usuarios pueden actuar como ángeles de oración,
        acompañando y ayudando a sus hermanos en la vida. La principal
        intercesora de este proyecto es la Santísima Virgen de Guadalupe, madre
        de los que sufren, quien ha pedido una casita sagrada para escuchar a
        sus hijos en su dolor y mostrar al Dios por quien se vive. Detrás de
        Orando se encuentra la labor inspiradora de las Hijas de María
        Inmaculada de Guadalupe, una congregación mexicana fundada por el
        Venerable José Antonio Plancarte y Labastida. Su misión es ser madres
        para los huérfanos, maestras de los que no saben y consoladoras de los
        que sufren. <br />
        <br />
        Las Hijas de María Inmaculada de Guadalupe, inspiradas por el mandato
        evangélico de "id y enseñad", se comprometen con los que más sufren, con
        la niñez y la juventud. Su labor se extiende en las áreas de: educación,
        orientación vocacional, misiones y salud en los países de: México,
        Estados Unidos, San Salvador, República Dominicana, Bolivia y Roma.
        <br />
        <br />
        Inspirados por el Venerable José Antonio Plancarte y Labastida, hombre
        visionario y creativo, nace la iniciativa de esta aplicación que también
        busca difundir su santidad, permitiendo que se le encomienden las
        necesidades más urgentes de las familias, enfermos, alumnos, religiosos
        y demás necesidades del Pueblo de Dios.
        <br />
        <br />
        El financiamiento de este proyecto es gracias al esfuerzo de la
        congregación de las Hijas de María Inmaculada de Guadalupe, pero busca
        nutrirse de las generosas contribuciones de sus usuarios, asegurando de
        esta manera que “Orando” continúe siendo un espacio digital que promueva
        la santidad y la unión a través de la oración.
      </Typography>
      <Typography variant="h6" sx={{ mt: 3 }}>
        "Orando es más que una aplicación; es un puente hacia una comunidad
        unida por la oración e intercesión. Aquí, cada solicitud y ofrecimiento
        de oración se convierte en un acto de solidaridad y apoyo mutuo."
      </Typography>
      <Typography variant="h5" sx={{ mt: 3 }}>
        EQUIPO DE ORANDO
      </Typography>
      <Typography sx={{ mt: 3 }}>
        La congregación de las Hijas de María Inmaculada de Guadalupe (HMIG) ha
        establecido un equipo en Pastoral de Comunicación. Este equipo desempeña
        un papel crucial en la creación y gestión de la presencia digital de la
        congregación. Son los responsables directos del diseño, desarrollo y
        edición de los contenidos de la aplicación "Orando".
        <br />
        <br />
        El equipo de Pastoral de Comunicación promueve la misión y valores de la
        congregación. Su labor no se limita simplemente a la creación de
        contenidos, sino que también se aseguran de que estos reflejen fielmente
        la espiritualidad y el carisma de las HMIG.
        <br />
        <br />
        Además de crear y editar los contenidos, este equipo se encarga de
        mantener actualizada la aplicación, incorporando nuevas funcionalidades
        y mejoras basadas en las necesidades de la comunidad de usuarios.
        <br />
        <br />
        Trabajan en estrecha colaboración con otros departamentos de la
        congregación para asegurar que la aplicación y otros medios digitales
        sirvan como herramientas efectivas para la evangelización, la
        solidaridad y el apoyo espiritual.
      </Typography>
      <Typography variant="h6" sx={{ mt: 3 }}>
        "La Santísima Virgen de Guadalupe, es nuestra principal intercesora, la
        madre de los que sufren. Su deseo de una "casita sagrada" nos guía,
        recordándonos el amor infinito de Dios en cada momento de dolor. "
      </Typography>
    </Container>
  );
}
