import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { acceptCommunity, deleteCommunity } from "../services/Firestore";

export default function CardAccept({ posts }) {
  return (
    <Grid item xs={4}>
      <Card>
        <CardMedia
          sx={{ height: 140 }}
          image={posts.image}
          title={posts.image}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {posts.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {posts.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            sx={{ color: "green" }}
            onClick={() => acceptCommunity(posts.id)}
          >
            Aprobar
          </Button>
          <Button
            size="small"
            sx={{ color: "red" }}
            onClick={() => deleteCommunity(posts.id)}
          >
            Rechazar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
