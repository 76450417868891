import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebaseConfig";
import {
  deleteChallengeRef,
  getChallenge,
  getCurrentUser,
  getRelationChallenges,
  postJoinChallenge,
} from "../services/Firestore";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BookmarkIcon from "@mui/icons-material/Bookmark";

// export async function loader({ params }) {
//   const challenge = await getChallenge(params.challengeId);
//   const uid = params.challengeId;
//   return { challenge, uid };
// }

export default function ChallengePreview() {
  let navigate = useNavigate();

  // const { challenge, uid } = useLoaderData();

  // const [audio, setAudio] = useState(new Audio());
  // const [status, setStatus] = useState(false);
  // const [currentTime, setCurrentTime] = useState(0);
  // const [duration, setDuration] = useState(0);
  // const [currentUser, setCurrentUser] = useState({});
  // const [join, setJoin] = useState([]);

  // const togglePlay = () => {
  //   if (status) {
  //     audio.pause();
  //   } else {
  //     audio.play();
  //   }
  //   setStatus(!status);
  // };

  // useEffect(() => {
  //   downloadAudio();
  //   getCurrentUser(setCurrentUser);
  // }, []);

  // useEffect(() => {
  //   const handleTimeUpdate = () => {
  //     setCurrentTime(audio.currentTime);
  //     setDuration(audio.duration);
  //   };
  //   audio.addEventListener("timeupdate", handleTimeUpdate);
  //   if (currentUser.id) {
  //     getRelationChallenges(setJoin, currentUser.userID, uid);
  //   }
  // }, [audio, currentUser.userID, currentUser, uid]);

  // const downloadAudio = () => {
  //   const uri = ref(storage, `challenges/${challenge.audioName}`);
  //   getDownloadURL(uri).then((url) => {
  //     setAudio(new Audio(url));
  //   });
  // };

  // const handleSliderChange = (event, newValue) => {
  //   audio.currentTime = newValue;
  // };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Button
            variant="text"
            startIcon={<ArrowBackIosIcon />}
            sx={{ textTransform: "initial" }}
            onClick={() => navigate("/retos")}
          >
            Volver
          </Button>
          <Button
            variant="contained"
            // sx={{ backgroundColor: join.length !== 0 ? "red" : "" }}
            startIcon={<BookmarkIcon />}
            // onClick={() =>
            //   join.length !== 0
            //     ? deleteChallengeRef(join[0].id)
            //     : postJoinChallenge({
            //         challenge: uid,
            //         user: currentUser.userID,
            //       })
            // }
          >
            {/* {join.length !== 0 ? "Quitar" : "Guardar"} */}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <CardMedia
              component="img"
              sx={{ width: 250, height: 250 }}
              // image={challenge.image}
              alt="Live from space album cover"
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                padding: 1,
              }}
            >
              {/* <Typography variant="h4">{challenge.title}</Typography> */}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: 2,
            }}
          >
            <Box
              sx={{
                borderRadius: 50,
                backgroundColor: "#133A7B",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 50,
                height: 50,
              }}
            >
              {/* {status ? (
                <IconButton aria-label="play/pause" onClick={togglePlay}>
                  <PauseIcon sx={{ height: 38, width: 38, color: "white" }} />
                </IconButton>
              ) : (
                <IconButton aria-label="play/pause" onClick={togglePlay}>
                  <PlayArrowIcon
                    sx={{ height: 38, width: 38, color: "white" }}
                  />
                </IconButton>
              )} */}
            </Box>
            <IconButton>
              <EventAvailableIcon
                sx={{ height: 36, width: 36, color: "#133A7B" }}
              />
            </IconButton>
            <Box
              sx={{
                width: "85%",
              }}
            >
              {/* <Slider
                value={currentTime}
                max={duration}
                onChange={handleSliderChange}
                aria-label="Tiempo de reproducción"
              /> */}
            </Box>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={6} sx={{ background: "#DEDADA", mt: 5 }}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h4" fontWeight={600} sx={{ color: "#133A7B" }}>
            Reto
          </Typography>
          {/* <Typography textAlign="justify">{challenge.description}</Typography> */}
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ background: "#DEDADA", mt: 5 }}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h4" fontWeight={600} sx={{ color: "#133A7B" }}>
            Oración
          </Typography>
          {/* <Typography textAlign="justify">{challenge.prayer}</Typography> */}
        </Box>
      </Grid>
    </Grid>
  );
}
